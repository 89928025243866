import JwtDecode from 'jwt-decode';
import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useAuth } from '../../context/authContext';
import LayoutView from './LayoutView';

const Layout = () => {
  const auth = JSON.parse(localStorage.getItem('auth'));
  const { loading, loggedInUser, logout } = useAuth();
  let logoutTimer;

  useEffect(() => {
    startTimeout();
    checkTokenExpiry();
  });

  const checkTokenExpiry = () => {
    if (auth && auth.apiKey) {
      const expiryDate = JwtDecode(auth.apiKey).exp;
      const currentTime = new Date().getTime() / 1000;

      if (currentTime > expiryDate) {
        logout();
      }
    }
  };

  const startTimeout = () => {
    logoutTimer = setTimeout(logout, 1800 * 1000);
  };

  const resetTimeout = () => {
    if (logoutTimer) clearTimeout(logoutTimer);
    startTimeout();
  };

  function onlineIndicator() {
    const isOnline = navigator.onLine;

    if (isOnline) {
      toast.success('You are back online', {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else {
      toast.error('You are offline', {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
  }

  useEffect(() => {
    window.addEventListener('online', onlineIndicator);
    window.addEventListener('offline', onlineIndicator);
  }, []);

  const events = [
    'load',
    'mousemove',
    'mousedown',
    'click',
    'scroll',
    'keypress',
  ];

  for (let i in events) {
    window.addEventListener(events[i], resetTimeout);
  }
  return (
    <LayoutView loading={loading} loggedInUser={loggedInUser} logout={logout} />
  );
};

export default Layout;
