import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useFormState } from 'react-use-form-state';
import {
  Badge,
  Button,
  Card,
  Col,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from 'reactstrap';
import { PageTitle, Unauthorized, Can } from '../../components';
import { useAuth } from '../../context/authContext';
import { handleApiErrors, removeUnderscore, titleCase } from '../../lib/utils';
import {
  activateUser,
  deactivateUser,
  fetchRoles,
  fetchUsers,
  updateUserRole,
} from '../../services';

const Users = () => {
  const { loggedInUser, setLoading } = useAuth();
  const [modal, setModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState('');
  const [formState, { select }] = useFormState();
  const [usersFetchError, setUsersFetchError] = useState(false);
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const form = {
    roleId: 'roleId',
  };

  const toggleModal = async user => {
    if (user && user.id) {
      if (roles.length === 0) {
        await getRoles();
      }

      setSelectedUser(user);
      formState.setField(form.roleId, user.Role.id);
    } else {
      formState.clear();
    }
    setModal(!modal);
  };

  const getUsers = useCallback(async () => {
    setLoading(true);
    const response = await fetchUsers();
    const apiErrors = handleApiErrors(response);
    setLoading(false);

    if (apiErrors) {
      toast.error(apiErrors);
      setUsersFetchError(apiErrors);
      return;
    }

    setUsers(response.data.data);
  }, [setLoading]);

  const getRoles = useCallback(async () => {
    setLoading(true);
    const response = await fetchRoles();
    const apiErrors = handleApiErrors(response);
    setLoading(false);

    if (apiErrors) {
      toast.error(apiErrors);
      return;
    }

    setRoles(response.data.data);
  }, [setLoading]);

  const enableUser = async userId => {
    setLoading(true);
    const response = await activateUser(userId);
    const apiErrors = handleApiErrors(response);
    setLoading(false);

    if (apiErrors) {
      toast.error(apiErrors);
      return;
    }

    getUsers();
  };

  const disableUser = async userId => {
    setLoading(true);
    const response = await deactivateUser(userId);
    const apiErrors = handleApiErrors(response);
    setLoading(false);

    if (apiErrors) {
      toast.error(apiErrors);
      return;
    }

    getUsers();
  };

  const modifyUserRole = async () => {
    const payload = {
      userId: selectedUser.id,
      roleId: formState.values.roleId,
    };

    setLoading(true);
    const response = await updateUserRole(payload);
    const apiErrors = handleApiErrors(response);
    setLoading(false);

    if (apiErrors) {
      toast.error(apiErrors);
      return;
    }

    formState.clear();
    toggleModal();
    getUsers();
  };

  useEffect(() => {
    getRoles();
    getUsers();
  }, [getUsers, getRoles]);

  return (
    <Fragment>
      <PageTitle title="Users" />

      <Can
        perform="READ_USER"
        yes={() => (
          <Row>
            <Col xl={12}>
              <Card className="o-hidden border-0 shadow-lg mb-5">
                <section className="p-3 p-md-4">
                  <Row>
                    <Col>
                      <Table
                        borderless
                        striped
                        responsive
                        hover
                        className="m-0"
                      >
                        <thead>
                          <tr>
                            <th>Full name</th>
                            <th>Role</th>
                            <th className="text-center">Active</th>
                            <Can perform="WRITE_USER" yes={() => <th />} />
                          </tr>
                        </thead>
                        <tbody>
                          {users.map((user, index) => (
                            <tr key={index}>
                              <td>{user.userName}</td>
                              <td>
                                {titleCase(removeUnderscore(user?.Role?.name))}
                              </td>
                              <td align="center">
                                {user.isActive ? (
                                  <Badge
                                    pill
                                    color="success"
                                    children="Active"
                                  />
                                ) : (
                                  <Badge
                                    pill
                                    color="danger"
                                    children="Inactive"
                                  />
                                )}
                              </td>
                              <Can
                                perform="WRITE_USER"
                                yes={() => (
                                  <td align="right">
                                    {loggedInUser.id !== user.id && (
                                      <Fragment>
                                        <Button
                                          color="primary"
                                          size="sm"
                                          children="Manage"
                                          className="mb-1 mb-md-0"
                                          onClick={() => toggleModal(user)}
                                        />{' '}
                                        {user.isActive ? (
                                          <Button
                                            color="danger"
                                            size="sm"
                                            children="Disable"
                                            onClick={() => disableUser(user.id)}
                                          />
                                        ) : (
                                          <Button
                                            color="success"
                                            size="sm"
                                            children="Enable"
                                            onClick={() => enableUser(user.id)}
                                          />
                                        )}
                                      </Fragment>
                                    )}
                                  </td>
                                )}
                              />
                            </tr>
                          ))}

                          {usersFetchError && (
                            <tr>
                              <td colSpan={5} className="text-center">
                                <Button color="primary" onClick={getUsers}>
                                  Reload
                                </Button>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </section>
              </Card>
            </Col>
          </Row>
        )}
        no={() => <Unauthorized />}
      />

      <Modal
        isOpen={modal}
        toggle={toggleModal}
        backdrop="static"
        returnFocusAfterClose={false}
      >
        <ModalHeader>
          Manage {titleCase(removeUnderscore(selectedUser.userName))}
        </ModalHeader>

        <ModalBody>
          <FormGroup>
            <Label for={form.roleId}>Select New Role</Label>
            <select
              type="select"
              className="form-control"
              id={form.roleId}
              {...select(form.roleId)}
            >
              {roles.map((role, index) => (
                <option key={index} value={role.id} id="userRole">
                  {titleCase(removeUnderscore(role.name))}
                </option>
              ))}
            </select>
          </FormGroup>
        </ModalBody>

        <ModalFooter>
          <Button color="danger" onClick={toggleModal}>
            Cancel
          </Button>

          <Button color="primary" onClick={modifyUserRole}>
            Update Permissions
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default Users;
