import axios from 'axios';
import requestClient from '../lib/requestClient';
import { errorHandler } from '../lib/utils';
import config from '../config/config';

/**
 *  @typedef {{
 *            limit: Number,
 *            skip: Number,
 *            name: String,
 *            merchantId: String
 *          }}
 */
let FetchAttributesParams;

/**
 * @param {FetchAttributesParams | String} params
 * @return {Array} Array of all Attributes
 */

export const fetchAttributes = params => {
  let query = '';
  const urlParams = [];
  let { limit, skip, name, merchantId } = params || {};

  if (typeof params === 'string') {
    merchantId = params;
  }

  if (merchantId) {
    query = `/${merchantId}`;
  } else {
    const queryParams = { limit, skip, name };

    for (let key in queryParams) {
      const value = queryParams[key];
      value && urlParams.push(`${key}=${value}`);
    }

    if (urlParams.length > 0) {
      query = `?${encodeURI(urlParams.join('&'))}`;
    }
  }

  return requestClient()
    .get(`/api/v1/attributes${query}`)
    .catch(error => {
      return { errorMessage: errorHandler(error) };
    });
};

/**
 *  @typedef {{
 *            merchantId: String
 *            attributes: Array
 *          }}
 */
let CreateMerchantAttributes;

/**
 * @param {CreateMerchantAttributes} payload
 * @return {Array} Array of all Attributes
 */
export const createMerchantAttributes = payload => {
  let { merchantId, attributes } = payload || {};

  return requestClient()
    .post(`/api/v1/attributes`, { ...merchantId, ...attributes })
    .catch(error => {
      return { errorMessage: errorHandler(error) };
    });
};

/**
 *  @typedef {{
 *            merchantId: String
 *            attributes: Array
 *          }}
 */
let UpdateMerchantAttributes;

/**
 * @param {UpdateMerchantAttributes} payload
 * @return {Array} Array of all Attributes
 */
export const updateMerchantAttributes = payload => {
  let { merchantId, attributes } = payload || {};

  return requestClient()
    .put(`/api/v1/attributes`, {
      ...merchantId,
      ...attributes,
    })
    .catch(error => {
      return { errorMessage: errorHandler(error) };
    });
};

/**
 * @param {} payload
 */
export const uploadAttributeImage = payload => {
  const getHeaders = () => {
    const auth = JSON.parse(localStorage.getItem('auth'));
    const headers = {};

    headers['Content-Type'] = 'multipart/form-data';

    if (auth != null && typeof auth !== 'undefined') {
      headers['token'] = `${auth.apiKey}`;
    }

    return headers;
  };

  const url =
    config.node_env === 'development'
      ? `${config.base_url}/api/v1/attributes/upload`
      : `${config.base_url}api/v1/attributes/upload`;

  return axios({
    url,
    method: 'POST',
    data: payload,
    headers: getHeaders(),
  }).catch(error => {
    return { errorMessage: errorHandler(error) };
  });
};
