import React from 'react';
import { useHistory } from 'react-router-dom';
import { Alert, Button, Card, Col, Container, Row } from 'reactstrap';
import './_unauthorized.scss';

const Unauthorized = () => {
  const history = useHistory();

  return (
    <Container className="unauthorized">
      <Row>
        <Col xl={12}>
          <Card className="o-hidden border-0 shadow-lg mb-5">
            <section className="p-5">
              <Alert
                color="warning"
                className="text-center mb-5"
                children="Sorry, you can not access this page."
              />
              <section className="text-center">
                <Button
                  color="primary"
                  onClick={() => history.push(`/dashboard`)}
                >
                  Back to dashboard
                </Button>
              </section>
            </section>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Unauthorized;
