import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Container } from 'reactstrap';
import { Can, PageTitle, Unauthorized } from '../../components';
import { useAuth } from '../../context/authContext';
import { handleApiErrors } from '../../lib/utils';
import { fetchReportById } from '../../services';
import TableauReport from 'react-tableau-report';

const ViewReports = () => {
  const { setLoading } = useAuth();
  const [error, setError] = useState(false);
  const [reportName, setReportName] = useState('');
  const [reportUrl, setReportUrl] = useState('');
  const { reportId } = useParams();

  const getReports = useCallback(async () => {
    setLoading(true);
    const response = await fetchReportById(reportId);
    const apiErrors = handleApiErrors(response);
    setLoading(false);

    if (apiErrors) {
      toast.error(apiErrors);
      setError(apiErrors);
      return;
    }

    setReportName(response.data.data.name);
    setReportUrl(response.data.data.reportUrl || '');
  }, [setLoading]);

  useEffect(() => {
    getReports();

    window.scrollTo(0, 0);
  }, [getReports]);

  const options = {
    height: 1000,
    width: 1300,
    hideTabs: false,
    hideToolbar: true,
  };

  return (
    <Fragment>
      <PageTitle title={reportName || 'Report'} />

      <Can
        perform="READ_REPORT"
        yes={() =>
          reportUrl && (
            <Container className="reports">
              <TableauReport
                url={reportUrl}
                options={options}
                className="col-12"
              />
            </Container>
          )
        }
        no={() => <Unauthorized />}
      />
    </Fragment>
  );
};

export default ViewReports;
