import React, { Fragment, useState } from "react";
// import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormState } from "react-use-form-state";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { Can, PageTitle, Unauthorized } from "../../components";
import { success, errors } from "../../config/constants";
import { useAuth } from "../../context/authContext";
import { handleApiErrors, isValidUrl } from "../../lib/utils";

import { createNewReport } from "../../services";

const NewReports = () => {
  // const history = useHistory();
  const { setLoading } = useAuth();
  const [formState, { text }] = useFormState();
  // const [stepNumber, setStepNumber] = useState(1);
  const [reportId, setReportId] = useState(false);
  const [reportName, setReportName] = useState(false);
  const [reportType, setReportType] = useState("");

  const form = {
    reportName: "reportName",
    reportType: "reportType",
    reportUrl: "reportUrl",
  };

  const handleReportType = (e) => {
    const { value } = e.target;
    setReportType(value);
  };

  const createReport = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { reportName, reportUrl } = formState.values;

    let allInputValid = true;

    if (!reportName.trim() || !reportType.trim() || !reportUrl.trim()) {
      toast.error(errors.ENTER_ALL_FIELD);
      allInputValid = false;
    }

    if (!isValidUrl(reportUrl)) {
      toast.error(errors.URL_NOT_VALID);
      allInputValid = false;
    }

    if (!allInputValid) return;

    setLoading(true);
    const response = await createNewReport({
      name: reportName,
      reportUrl,
      reportType,
    });
    const apiErrors = handleApiErrors(response);
    setLoading(false);

    if (apiErrors) {
      toast.error(apiErrors);
      return;
    }

    toast.success(success.REPORT_CREATED);
    // history.push(`/reports/${response.data.data.id}`);
    setReportId(response.data.data.id);
    setReportName(response.data.data.name);
  };

  return (
    <Fragment>
      <PageTitle title="New Report" />

      <Can
        perform="WRITE_REPORT"
        yes={() => (
          <Container className="reports">
            <Row>
              <Col xl={12}>
                <Card className="o-hidden border-0 shadow-lg mb-5">
                  <CardBody className="p-0">
                    <Row>
                      <Col lg={12} md={12}>
                        <Form onSubmit={createReport} className="p-4 p-md-5">
                          <Row>
                            <Col md={6}>
                              <FormGroup>
                                <Label for={form.reportName}>Report Name</Label>
                                <Input
                                  type="text"
                                  autoFocus
                                  required
                                  id={form.reportName}
                                  {...text(form.reportName)}
                                />
                              </FormGroup>
                            </Col>
                            <Col md={6}>
                              <FormGroup>
                                <Label for={form.reportUrl}>Report Url</Label>
                                <Input
                                  type="text"
                                  required
                                  id={form.reportUrl}
                                  {...text(form.reportUrl)}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6}>
                              <FormGroup tag="fieldset">
                                <Label>Select Report Type</Label>
                                <Input
                                  type="select"
                                  onChange={handleReportType}
                                  className="text-capitalize"
                                >
                                  <option value="">
                                    -- Select Report Type --
                                  </option>
                                  <option value="general" key="1">
                                    General Report
                                  </option>
                                  <option value="management" key="2">
                                    Management Report
                                  </option>
                                </Input>
                              </FormGroup>
                            </Col>

                            <Col md={6} className="mt-3">
                              <Button
                                type="submit"
                                color="primary"
                                children="Create Report"
                                className="w-100 mb-4"
                              />
                            </Col>
                          </Row>
                        </Form>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        )}
        no={() => <Unauthorized />}
      />
    </Fragment>
  );
};

export default NewReports;
