import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Header, Loader } from '../';
import {
  Dashboard,
  Merchants,
  NewMerchant,
  Page404,
  Settings,
  SingleMerchant,
  Reports,
  NewReports,
  ViewReport,
  DecideBankList,
  NewDecideBank,
} from '../../pages';
import Breadcrumbs from '../Breadcrumbs';
import { Page } from '../core';
import './_layout-view.scss';

const LayoutView = ({ loading, loggedInUser, logout }) => (
  <section id="layout">
    <section id="content-wrapper">
      {loading && <Loader />}
      <Header loggedInUser={loggedInUser} logout={logout} />
      <Breadcrumbs />
      <Switch>
        <Route
          path="/"
          exact
          render={() => (
            <Redirect
              to={{
                pathname: '/dashboard',
              }}
            />
          )}
        />

        <Route
          path="/dashboard"
          exact
          render={(props) => (
            <Page {...props} component={Dashboard} title="Dashboard" />
          )}
        />

        <Route
          path="/merchants"
          exact
          render={(props) => (
            <Page {...props} component={Merchants} title="Merchants" />
          )}
        />

        <Route
          path="/merchants/new"
          exact
          render={(props) => (
            <Page
              {...props}
              component={NewMerchant}
              title="Create a new merchant"
            />
          )}
        />

        <Route
          path="/merchants/:slug"
          render={(props) => (
            <Page
              {...props}
              component={SingleMerchant}
              title="Merchant Details"
            />
          )}
        />

        <Route
          path="/decide-banks"
          exact
          render={(props) => (
            <Page {...props} component={DecideBankList} title="Decide Banks" />
          )}
        />

        <Route
          path="/decide-banks/new"
          exact
          render={(props) => (
            <Page
              {...props}
              component={NewDecideBank}
              title="New Decide Banks"
            />
          )}
        />

        <Route
          path="/reports"
          exact
          render={(props) => (
            <Page {...props} component={Reports} title="Reports" />
          )}
        />

        <Route
          path="/reports/new"
          exact
          render={(props) => (
            <Page {...props} component={NewReports} title="Reports" />
          )}
        />

        <Route
          path="/reports/:reportId"
          exact
          render={(props) => (
            <Page {...props} component={ViewReport} title="Reports" />
          )}
        />

        <Route
          path="/settings"
          render={(props) => (
            <Page {...props} component={Settings} title="Settings" />
          )}
        />

        <Route
          path=""
          exact
          render={(props) => (
            <Page {...props} component={Page404} title="Page Not Found" />
          )}
        />
      </Switch>
    </section>
  </section>
);

export default LayoutView;
