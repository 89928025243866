import React from 'react';
import withBreadcrumbs from 'react-router-breadcrumbs-hoc';
import { NavLink } from 'react-router-dom';
import { Container } from 'reactstrap';
import { removeHyphen } from '../../lib/utils';
import './_breadcrumbs.scss';

const Breadcrumbs = ({ breadcrumbs }) => (
  <Container className="mb-4">
    <nav className="bread-crumbs shadow-sm py-2 px-3">
      {breadcrumbs.map(({ breadcrumb, match }, index) => (
        <div className="bread-crumb" key={match.url}>
          {index === 0 ? (
            <NavLink exact to="/dashboard">
              Home
            </NavLink>
          ) : index < breadcrumbs.length - 1 ? (
            <NavLink exact to={match.url || ''}>
              {removeHyphen(breadcrumb.props.children)}
            </NavLink>
          ) : (
            <span>{removeHyphen(breadcrumb.props.children)}</span>
          )}
        </div>
      ))}
    </nav>
  </Container>
);

export default withBreadcrumbs()(Breadcrumbs);
