import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations as TracingIntegrations } from '@sentry/tracing';
import config from './config/config';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './_index.scss';

Sentry.init({
  dsn: config.sentry_dsn,
  environment: config.node_env,
  integrations: [new TracingIntegrations.BrowserTracing()],
  tracesSampleRate: 0.2,
});

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
