import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Alert,
  Button,
  Card,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  Row,
  Table,
} from 'reactstrap';
import { Can, PageTitle, Pagination, Unauthorized } from '../../components';
import { useAuth } from '../../context/authContext';
import { errors } from '../../config/constants';
import {
  extractNumber,
  handleApiErrors,
  havePermission,
} from '../../lib/utils';
import { fetchReports, dropReport } from '../../services';

const Reports = () => {
  const location = useLocation();
  const history = useHistory();
  const { setLoading, loggedInUser } = useAuth();
  const {
    role: { permissions },
  } = loggedInUser;
  const initialPageNumber = location.search
    ? extractNumber(location.search)
    : 1;
  const [pageNumber, setPageNumber] = useState(initialPageNumber);
  const [error, setError] = useState(false);
  const [reports, setReports] = useState([]);
  const [findParam, setFindParam] = useState('');

  const getReports = useCallback(async () => {
    setLoading(true);
    const response = await fetchReports({ skip: pageNumber * 20 - 20 });

    const apiErrors = handleApiErrors(response);
    setLoading(false);

    if (apiErrors) {
      toast.error(apiErrors);
      setError(apiErrors);
      return;
    }

    setReports(response.data.data);
  }, [pageNumber, setLoading]);

  useEffect(() => {
    getReports();

    window.scrollTo(0, 0);
  }, [getReports, pageNumber]);

  const handlePagination = async e => {
    const { value } = e.target;
    setPageNumber(parseInt(value));

    if (value > 1) {
      history.push(`reports?page=${value}`);
    } else {
      history.push(`reports`);
    }
  };

  const findReport = async e => {
    e.preventDefault();
    e.stopPropagation();

    if (!findParam) {
      toast.error(errors.ENTER_REPORT_NAME);
      return;
    }

    setLoading(true);
    const response = await fetchReports({ name: findParam });
    const apiErrors = handleApiErrors(response);
    setLoading(false);

    if (apiErrors) {
      toast.error(apiErrors);
      setError(apiErrors);
      return;
    }

    setReports(response.data.data);
  };

  const deleteReport = async (e, reportId) => {
    e.preventDefault();
    e.stopPropagation();

    if (!reportId) {
      toast.error(errors.REPORT_ID_MISSING);
      return;
    }

    setLoading(true);
    const response = await dropReport({ reportId });
    const apiErrors = handleApiErrors(response);
    setLoading(false);

    if (apiErrors) {
      toast.error(apiErrors);
      setError(apiErrors);
      return;
    }

    window.location.reload(true);
  };

  let showReport = false;

  return (
    <Fragment>
      <PageTitle
        title="Reports"
        right={
          <Can
            perform="WRITE_REPORT"
            yes={() => (
              <Link to="/reports/new" className="btn btn-primary">
                Add New
              </Link>
            )}
            no={() => ''}
          />
        }
        subtitle={`Page ${pageNumber}`}
      />

      <Can
        perform="READ_REPORT"
        yes={() => (
          <Container className="reports">
            <Row>
              <Col lg={7} md={6} />
              <Col lg={5} md={6} className="mb-3">
                <Form onSubmit={findReport}>
                  <InputGroup>
                    <Input onChange={e => setFindParam(e.target.value)} />
                    <InputGroupAddon addonType="append">
                      <Button color="primary" className="px-4">
                        Search
                      </Button>
                    </InputGroupAddon>
                  </InputGroup>
                </Form>
              </Col>
              <Col xl={12}>
                <Card className="o-hidden border-0 shadow-lg mb-5">
                  <section className="p-3 p-md-4">
                    <Table borderless striped responsive hover className="m-0">
                      <thead>
                        <tr className="d-flex align-items-center">
                          <th className="col-2">Name</th>
                          <th className="col-2">Report Type</th>
                          <th className="col-6 text-center">URL</th>
                          <th className="col-2" />
                        </tr>
                      </thead>

                      <tbody className="w-100">
                        {reports.map((report, index) => {
                          showReport = false;
                          if (
                            report.reportType.toLowerCase() === 'general' &&
                            havePermission('VIEW_GENERAL_REPORT', permissions)
                          )
                            showReport = true;
                          if (
                            report.reportType.toLowerCase() === 'management' &&
                            havePermission(
                              'VIEW_MANAGEMENT_REPORT',
                              permissions,
                            )
                          )
                            showReport = true;
                          return (
                            showReport && (
                              <tr
                                key={index}
                                className="d-flex align-items-center"
                              >
                                <td className="col-2">{report.name}</td>
                                <td className="col-2">{report.reportType}</td>
                                <td className="col-6">{report.reportUrl}</td>
                                <td align="right" className="col-2">
                                  <Link
                                    className="btn btn-primary btn-sm"
                                    to={`/reports/${report.id}`}
                                  >
                                    View report
                                  </Link>

                                  <Link
                                    className="btn btn-danger btn-sm"
                                    to={`#`}
                                    onClick={e => deleteReport(e, report.id)}
                                  >
                                    Delete report
                                  </Link>
                                </td>
                              </tr>
                            )
                          );
                        })}
                      </tbody>
                    </Table>

                    {findParam && reports.length === 0 && (
                      <Alert color="warning" className="text-center">
                        Cannot find Report with name{' '}
                        <span className="font-weight-bold">{findParam}</span>
                        <Button color="link">View all</Button>
                      </Alert>
                    )}

                    {error && (
                      <div colSpan={3} className="text-center">
                        <Button color="primary" onClick={getReports}>
                          Reload
                        </Button>
                      </div>
                    )}
                  </section>
                </Card>
              </Col>

              <Col xs={12} className="mb-5">
                <Pagination
                  currentPage={pageNumber}
                  lastPage={20}
                  clickEvent={handlePagination}
                />
              </Col>
            </Row>
          </Container>
        )}
        no={() => <Unauthorized />}
      />
    </Fragment>
  );
};

export default Reports;
