import React, { Fragment, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import NumberFormat from 'react-number-format';
import {
  Card,
  Button,
  FormGroup,
  Label,
  Input,
  Form,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import {
  handleApiErrors,
  removeCommas,
  numberWithCommas,
} from '../../../lib/utils';
import { success, errors } from '../../../config/constants';
import { fundWallet, updateChargeFee } from '../../../services';
import { useAuth } from '../../../context/authContext';

const form = {
  amount: 'amount', // amounts are in kobo
  reference: 'reference', // comment for the transaction
  walletType: 'walletType', //comment for the walletType
};

const SingleMerchantDecideWallet = ({
  merchantId,
  merchantSlug,
  merchantName,
  transactions,
  merchantWallet,
  prePaidBalance,
  postPaidBalance,
  merchantFee,
  merchantFetchError,
  getMerchant,
}) => {
  const { setLoading } = useAuth();
  const [feeState, setFeeState] = useState(null);
  const [refState, setRefState] = useState('');
  const [walletType, setWalletType] = useState('');
  const [modal, setModal] = useState(false);
  const [amountState, setAmountState] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [merchantWallet]);

  const updateWalletFeeAmount = e => {
    const { value } = e.target;
    setFeeState(value);
  };

  const updateWalletType = e => {
    const { value } = e.target;
    setWalletType(value);
  };

  const updateReference = e => {
    const { value } = e.target;
    setRefState(value);
  };

  const updateWalletFund = e => {
    const { value } = e.target;
    setAmountState(value);
  };

  const submitForm = async e => {
    e.preventDefault();
    e.stopPropagation();

    const payload = {
      slug: merchantSlug,
      amount: parseInt(removeCommas(feeState)) * 100,
    };

    if (!feeState) {
      toast.error(errors.ENTER_ALL_FIELD);
      return;
    }

    // if (feeState < 100) {
    //   toast.error(errors.DECIDE_FEE_NOT_VALID);
    //   return;
    // }

    setLoading(true);
    const response = await updateChargeFee(payload);

    const apiErrors = handleApiErrors(response);
    setFeeState('');
    setLoading(false);

    if (apiErrors) {
      toast.error(apiErrors);
      return;
    }

    toast.success(success.DECIDE_FEE_UPDATE);
    getMerchant();
  };

  const toggleModal = () => {
    setModal(true);
  };

  const HideModal = () => {
    setRefState('');
    setAmountState('');
    setModal(false);
  };

  const fundDecideWalletBalance = async e => {
    e.preventDefault();
    e.stopPropagation();

    const payload = {
      slug: merchantSlug,
      amount: parseInt(removeCommas(amountState)) * 100,
      reference: refState,
      source: 'manual',
      wallet_type: walletType,
    };

    if (!amountState) {
      toast.error(errors.ENTER_ALL_FIELD);
      return;
    }

    if (!refState) {
      toast.error(errors.ENTER_ALL_FIELD);
      return;
    }

    if (!walletType) {
      toast.error(errors.ENTER_ALL_FIELD);
      return;
    }

    if (amountState < 100) {
      toast.error(errors.DECIDE_AMOUNT_NOT_VALID);
      return;
    }

    setLoading(true);
    const response = await fundWallet(payload);

    const apiErrors = handleApiErrors(response);

    setRefState('');
    setAmountState('');
    setWalletType('');

    HideModal();
    setLoading(false);

    if (apiErrors) {
      toast.error(apiErrors);
      return;
    }

    toast.success(success.MERCHANT_WALLET_UPDATED);
    getMerchant();
  };

  return (
    <Fragment>
      <h4 className="mb-3">Decide Wallet</h4>

      <Card className="o-hidden border-0 p-4 shadow-lg mb-5">
        {/* <Col md={8}> */}
        <div className="p-4 p-md-1">
          <h5>Wallet Balance Details</h5>
          <div>
            <p>Transactions Count: {transactions}</p>
            <p>{`Pre Paid Wallet Balance: N ${numberWithCommas(
              prePaidBalance,
            )}`}</p>
            <p>{`Post Paid Wallet Balance: N ${numberWithCommas(
              postPaidBalance,
            )}`}</p>
            <p>{`Transaction Fee: N ${numberWithCommas(merchantFee)}`}</p>
          </div>
        </div>
        <Form className="p-4 p-md-1">
          <Row>
            <Col md={6}>
              <FormGroup>
                <div className="mt-4">
                  <h5>Fund Merchant Wallet</h5>
                </div>
                <div>
                  <Label htmlFor={form.amount} className="text-capitalize">
                    Amount
                  </Label>

                  <NumberFormat
                    type="text"
                    customInput={Input}
                    thousandSeparator={true}
                    value={amountState}
                    onChange={updateWalletFund}
                  />
                </div>
                <div className="mt-2">
                  <Label for={form.reference} className="text-capitalize">
                    {form.reference}
                  </Label>
                  <Input
                    type="text"
                    minLength={2}
                    value={refState}
                    onChange={updateReference}
                  />
                </div>
                <div className="mt-2">
                  <Label for={form.walletType} className="text-capitalize">
                    Wallet Type
                  </Label>
                  <Input type="select" onChange={updateWalletType}>
                    <option value="">-- Select Wallet Type --</option>
                    <option value="pre_paid" key="1">
                      Pre Paid Wallet
                    </option>
                    <option value="post_paid" key="2">
                      Post Paid Wallet
                    </option>
                  </Input>
                </div>
              </FormGroup>
            </Col>
          </Row>

          <Button
            color="primary"
            className="ml-auto"
            size="sm"
            onClick={() => {
              toggleModal();
            }}
          >
            Fund Wallet
          </Button>

          <Modal isOpen={modal} toggle={toggleModal}>
            <ModalHeader toggle={HideModal}>Fund Merchant Wallet </ModalHeader>
            <ModalBody>
              Do you want to fund <b>{merchantName}'s</b> wallet balance?
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                type="submit"
                onClick={fundDecideWalletBalance}
              >
                Accept
              </Button>{' '}
              <Button color="secondary" onClick={HideModal}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </Form>
        {/* </Col> */}

        {/* <Col md={8}> */}
        <Form className="p-4 p-md-1" onSubmit={submitForm}>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label htmlFor={form.amount} className="text-capitalize">
                  <div className="mt-5">
                    <h5>Merchant Fee</h5>
                  </div>
                </Label>

                <NumberFormat
                  type="text"
                  customInput={Input}
                  thousandSeparator={true}
                  value={feeState}
                  onChange={updateWalletFeeAmount}
                />
              </FormGroup>
            </Col>
          </Row>

          <Button color="primary" type="submit" className="ml-auto" size="sm">
            Update Charge Fee
          </Button>
        </Form>
        {/* </Col> */}

        {merchantFetchError && (
          <div className="text-center col-12">
            <Button color="primary" onClick={getMerchant}>
              Reload
            </Button>
          </div>
        )}
      </Card>
    </Fragment>
  );
};

SingleMerchantDecideWallet.defaultProps = {
  merchantId: '',
  merchantSlug: '',
  merchantName: '',
  transactions: '',
  merchantWallet: '',
  prePaidBalance: '',
  postPaidBalance: '',
  merchantFetchError: false,
};

export default SingleMerchantDecideWallet;
