import React, { Fragment, useEffect, useState } from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import { useAuth } from '../../../context/authContext';
import { handleApiErrors } from '../../../lib/utils';
import { success } from '../../../config/constants';
import { updateBank } from '../../../services';
import { toast } from 'react-toastify';
import { useFormState } from 'react-use-form-state';

const EditBank = ({ selectedBank, toggle, isOpen, getBanks }) => {
  const { setLoading } = useAuth();
  const [formState, { text }] = useFormState();
  const [currencies, setCurrencies] = useState(['']);

  const form = {
    bankName: 'bankName',
    bankCode: 'bankCode',
  };

  const currency = selectedBank?.currencies?.map((a) => a?.code);

  useEffect(() => {
    formState.setField('bankName', selectedBank?.name);
    formState.setField('bankCode', selectedBank?.code);

    if (isOpen && currency) currency.push('');

    setCurrencies(currency);
  }, [isOpen, selectedBank]);

  const editDecideBank = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);

    const { bankName, bankCode } = formState.values;

    const response = await updateBank(selectedBank?.id, {
      name: bankName || selectedBank?.name,
      code: bankCode || selectedBank?.code,
      currencies: currencies?.filter(String) || currency,
    });

    const apiErrors = handleApiErrors(response);
    setLoading(false);

    if (apiErrors) {
      toast.error(apiErrors);
      return;
    }

    toast.success(success.DECIDE_BANK_UPDATED);
    if (response?.data?.status === 'success') {
      toggle();
      getBanks();
    }
  };

  const addNewStep = () => {
    setCurrencies([...currencies, '']);
  };

  const removeStep = (idx) => {
    const values = [...currencies];

    values.splice(idx, 1);
    setCurrencies(values);
  };

  const handleCycleChange = (e, idx) => {
    const values = [...currencies];
    values[idx] = e.target.value.toUpperCase();
    setCurrencies(values);
  };

  return (
    <Fragment>
      <Modal isOpen={isOpen} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle}>Update Decide Bank</ModalHeader>
        <ModalBody>
          <Row>
            <Col lg={12} md={12}>
              <Form onSubmit={editDecideBank} className="p-4 p-md-5">
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label for={form.bankName}>Bank Name</Label>
                      <Input
                        type="text"
                        autoFocus
                        id={form.bankName}
                        {...text(form.bankName)}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label for={form.bankCode}>Bank Code</Label>
                      <Input
                        type="text"
                        id={form.bankCode}
                        {...text(form.bankCode)}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row className="ml-2">
                  <Col md={9}>
                    <FormGroup>
                      <Label>Currency Code</Label>

                      {currencies?.map((val, idx) => (
                        <Row
                          className="row d-flex align-items-center mb-4"
                          key={idx}
                        >
                          <Col md={6} className="d-flex align-items-center">
                            <span className="mr-3">{idx + 1}</span>

                            <Input
                              type="text"
                              name="currency"
                              value={val}
                              autoFocus={idx !== 0}
                              onChange={(e) => handleCycleChange(e, idx)}
                              placeholder="Enter Currency Code"
                            />
                          </Col>

                          <Col md={3} className="ml-4 ">
                            {idx + 1 === currencies.length ? (
                              <Button
                                color="secondary"
                                onClick={addNewStep}
                                style={{ width: '120px' }}
                                disabled={val?.length === 0}
                              >
                                Add
                              </Button>
                            ) : (
                              <Button
                                color="danger"
                                onClick={() => removeStep(idx)}
                                style={{ width: '120px' }}
                                tabIndex="-1"
                              >
                                Delete
                              </Button>
                            )}
                          </Col>
                        </Row>
                      ))}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} className="mt-3">
                    <Button
                      disabled={
                        currencies?.length === 1 ||
                        (currencies && currencies[0] === '') ||
                        !formState.values.bankCode ||
                        !formState.values.bankName
                      }
                      type="submit"
                      color="primary"
                      children="Update Decide Bank"
                      className="w-100 mb-4"
                    />
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default EditBank;
