import requestClient from '../lib/requestClient';
import { errorHandler } from '../lib/utils';

/**
 *  @typedef {{
 *            limit: number,
 *            skip: number,
 *            slug: string
 *          }}
 */
let FetchMerchantParams;

/**
 * @param {FetchMerchantParams | String} params
 * @return {Array} Array of or selected Merchant(s)
 */

export const fetchMerchants = params => {
  let query = '';
  const urlParams = [];
  let { limit = 20, slug, skip } = params || {};

  if (typeof params === 'string') {
    slug = params;
  }

  if (slug) {
    query = `/${slug}`;
  } else {
    const queryParams = { limit, skip };

    for (let key in queryParams) {
      const value = queryParams[key];
      value && urlParams.push(`${key}=${value}`);
    }

    if (urlParams.length > 0) {
      query = `?${encodeURI(urlParams.join('&'))}`;
    }
  }

  return requestClient()
    .get(`/api/v1/merchants${query}`)
    .catch(error => {
      return { errorMessage: errorHandler(error) };
    });
};

/**
 * @typedef {{
 *            firstName: string
 *            lastName: string
 *            email: string
 *            phone: string
 *            address: string
 *            accountName: string
 *            slug: string
 *          }}
 */
let CreateMerchantPayload;

/** @param {CreateMerchantPayload} payload */

export const createNewMerchant = payload => {
  const {
    firstName,
    lastName,
    email,
    phone,
    address,
    accountName,
    slug,
  } = payload;

  return requestClient()
    .post(`/api/v1/merchants`, {
      user: {
        firstName,
        lastName,
        email,
        phone,
        address,
      },
      accountName,
      slug,
    })
    .catch(error => {
      return { errorMessage: errorHandler(error) };
    });
};

/**
 * @typedef {{
 *            clientAppURL: string
 *            primaryColor: string
 *            secondaryColor: string
 *          }}
 */
let UpdateMerchantPayload;

/**
 *  @param {String} merchantId
 *  @param {UpdateMerchantPayload} payload
 */

export const updateMerchant = (merchantId, payload) => {
  const { clientAppURL, primaryColor, secondaryColor } = payload;

  return requestClient()
    .put(`/api/v1/merchants/${merchantId}`, {
      clientAppURL,
      primaryColor,
      secondaryColor,
    })
    .catch(error => {
      return { errorMessage: errorHandler(error) };
    });
};

export const createMerchantUser = payload => {
  const { firstName, lastName, clientId, email, phone, roles } = payload;

  return requestClient()
    .post(`/api/v1/merchants/user`, {
      firstName,
      lastName,
      clientId,
      email,
      phone,
      roles,
    })
    .catch(error => {
      return { errorMessage: errorHandler(error) };
    });
};

export const deleteMerchantUser = payload => {
  const { clientId, userId } = payload;

  return requestClient()
    .delete(`/api/v1/merchants/user`, {
      data: {
        clientId,
        userId,
      },
    })
    .catch(error => {
      return { errorMessage: errorHandler(error) };
    });
};

export const createMerchantBankAccount = payload => {
  const { bankId, clientId, accountNumber, accountName } = payload;

  return requestClient()
    .post(`/api/v1/merchants/bank_account`, {
      bankId,
      clientId,
      accountNumber,
      accountName,
    })
    .catch(error => {
      return { errorMessage: errorHandler(error) };
    });
};

export const topUpMerchantWallet = payload => {
  const { clientId, clientName } = payload;

  return requestClient()
    .post(`/api/v1/merchants/top_up`, {
      clientId,
      clientName,
    })
    .catch(error => {
      return { errorMessage: errorHandler(error) };
    });
};

export const updateMerchantWalletThreshold = payload => {
  const { clientId, amountThreshold } = payload;

  return requestClient()
    .post(`/api/v1/merchants/wallet`, {
      clientId,
      amountThreshold,
    })
    .catch(error => {
      return { errorMessage: errorHandler(error) };
    });
};

export const deactivateCustomer = payload => {
  const { clientId, accountId } = payload;

  return requestClient()
    .delete(`/api/v1/merchants/customers`, {
      data: {
        clientId,
        accountId,
      },
    })
    .catch(error => {
      return { errorMessage: errorHandler(error) };
    });
};

export const getCustomers = params => {
  let { limit = 20, skip, merchantId, email } = params || {};

  return requestClient()
    .post(`/api/v1/merchants/customers`, {
      limit,
      skip,
      merchantId,
      email,
    })
    .catch(error => {
      return { errorMessage: errorHandler(error) };
    });
};
