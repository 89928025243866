import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { authTypes } from '../../config/constants';
import { useAuth } from '../../context/authContext';
import { handleApiErrors } from '../../lib/utils';
import { fetchUsers } from '../../services';
import LoginView from './views/LoginView';
import './_login.scss';

const Login = ({ location }) => {
  const { authenticated, setSession, loading, setLoading } = useAuth();
  const { referer } = location.state || '/';

  useEffect(() => {
    document.title = 'Login';
  }, []);

  const initiateLogin = payload => {
    const { tokenId, profileObj, error } = payload;
    setLoading(true);

    if (error) {
      toast.error(
        typeof error === 'string'
          ? error
          : 'Unable to login. Please, try again later.',
      );

      setLoading(false);
      return;
    }
    setLoading(false);
    const googleTokens = { tokenId };
    setSession(googleTokens, authTypes.TEMPORARY);
    authenticateUser({ tokenId, profileObj });
  };

  const authenticateUser = async payload => {
    const { tokenId, profileObj } = payload;

    setLoading(true);
    const response = await fetchUsers({ thisUser: true });
    const apiErrors = handleApiErrors(response);
    setLoading(false);

    if (apiErrors) {
      toast.error(apiErrors);
      return;
    }

    const { isActive, Role: role, userName, id } = response.data.data;

    const sessionData = {
      tokenId,
      isActive,
      role,
      id,
      userName,
      imageUrl: profileObj.imageUrl,
    };

    setSession(sessionData);
  };

  const loginFailure = () => {
    toast.error('Unable to login. Please, try again later.');
  };

  return (
    <LoginView
      authenticated={authenticated}
      loading={loading}
      initiateLogin={initiateLogin}
      loginFailure={loginFailure}
      referer={referer}
    />
  );
};

export default Login;
