import requestClient from '../lib/requestClient';
import { errorHandler } from '../lib/utils';

export const getWalletBalance = async (slug, merchantId) => {
  return await requestClient()
    .get(`/api/v1/client/${slug}/${merchantId}`)
    .catch(error => {
      return { errorMessage: errorHandler(error) };
    });
};

export const fundWallet = async payload => {
  const { slug, amount, reference, source, wallet_type } = payload;

  return await requestClient()
    .post(`/api/v1/client/fundwallet`, {
      slug,
      amount,
      reference,
      source,
      wallet_type,
    })
    .catch(error => {
      return { errorMessage: errorHandler(error) };
    });
};

export const updateChargeFee = async payload => {
  const { slug, amount } = payload;

  return await requestClient()
    .put(`/api/v1/client/updatefee`, {
      slug,
      amount,
    })
    .catch(error => {
      return { errorMessage: errorHandler(error) };
    });
};

export const getBanks = async () => {
  return await requestClient()
    .get(`/api/v1/client/getbanks`)
    .catch(error => {
      return { errorMessage: errorHandler(error) };
    });
};

export const deleteBank = async bankId => {
  return await requestClient()
    .delete(`/api/v1/client/deletebank/${bankId}`, { data: { bankId } })
    .catch(error => {
      return { errorMessage: errorHandler(error) };
    });
};

export const addBank = async payload => {
  const { name, code, currencies } = payload;
  return await requestClient()
    .post(`/api/v1/client/addbank`, {
      name,
      code,
      currencies,
    })
    .catch(error => {
      return { errorMessage: errorHandler(error) };
    });
};

export const updateBank = async (bankId, payload) => {
  const { name, code, currencies, enabled } = payload;
  return await requestClient()
    .put(`/api/v1/client/updatebank/${bankId}`, {
      name,
      code,
      currencies,
      enabled,
      data: { bankId },
    })
    .catch(error => {
      return { errorMessage: errorHandler(error) };
    });
};

export const getDecideMonoConfig = async clientId => {
  return await requestClient()
    .get(`/api/v1/admin/client/${clientId}/mono-config`)
    .catch(error => {
      return { errorMessage: errorHandler(error) };
    });
};

export const addDecideMonoConfig = async payload => {
  const { client_key, client_secret, clientId, webhook_secret } = payload;
  return await requestClient()
    .post(`/api/v1/admin/client/${clientId}/mono-config`, {
      client_key,
      client_secret,
      webhook_secret,
    })
    .catch(error => {
      return { errorMessage: errorHandler(error) };
    });
};

export const updateDecideMonoConfig = async payload => {
  const { client_key, client_secret, clientId, webhook_secret } = payload;
  return await requestClient()
    .put(`/api/v1/admin/client/${clientId}/mono-config`, {
      client_key,
      client_secret,
      webhook_secret,
    })
    .catch(error => {
      return { errorMessage: errorHandler(error) };
    });
};

export const getClientInfo = async slug => {
  return await requestClient()
    .get(`/api/v1/admin/client/${slug}`)
    .catch(error => {
      return { errorMessage: errorHandler(error) };
    });
};
