import React, { Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useFormState } from 'react-use-form-state';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import { Can, PageTitle, Unauthorized } from '../../components';
import { success } from '../../config/constants';
import { useAuth } from '../../context/authContext';
import { handleApiErrors } from '../../lib/utils';
import { addBank } from '../../services';

const NewDecideBank = () => {
  const history = useHistory();
  const { setLoading } = useAuth();
  const [formState, { text }] = useFormState();
  const [currencies, setCurrencies] = useState(['']);

  const form = {
    bankName: 'bankName',
    bankCode: 'bankCode',
  };

  const createDecideBank = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { bankName, bankCode } = formState.values;

    setLoading(true);
    const response = await addBank({
      name: bankName,
      code: bankCode,
      currencies: currencies.filter(String),
    });

    const apiErrors = handleApiErrors(response);
    setLoading(false);

    if (apiErrors) {
      toast.error(apiErrors);
      return;
    }

    toast.success(success.DECIDE_BANK_CREATED);
    history.push(`/decide-banks`);
  };

  const addNewStep = () => {
    setCurrencies([...currencies, '']);
  };

  const removeStep = (idx) => {
    const values = [...currencies];

    values.splice(idx, 1);
    setCurrencies(values);
  };

  const handleCycleChange = (e, idx) => {
    const values = [...currencies];
    values[idx] = e.target.value.toUpperCase();
    setCurrencies(values);
  };

  return (
    <Fragment>
      <PageTitle title="New Decide Bank" />

      <Can
        perform="WRITE_MERCHANT"
        yes={() => (
          <Container className="merchants">
            <Row>
              <Col xl={12}>
                <Card className="o-hidden border-0 shadow-lg mb-5">
                  <CardBody className="p-0">
                    <Row>
                      <Col lg={12} md={12}>
                        <Form
                          onSubmit={createDecideBank}
                          className="p-4 p-md-5"
                        >
                          <Row>
                            <Col md={6}>
                              <FormGroup>
                                <Label for={form.bankName}>Bank Name</Label>
                                <Input
                                  type="text"
                                  autoFocus
                                  required
                                  id={form.bankName}
                                  {...text(form.bankName)}
                                />
                              </FormGroup>
                            </Col>
                            <Col md={6}>
                              <FormGroup>
                                <Label for={form.bankCode}>Bank Code</Label>
                                <Input
                                  type="text"
                                  required
                                  id={form.bankCode}
                                  {...text(form.bankCode)}
                                />
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row className="ml-2">
                            <Col md={9}>
                              <FormGroup>
                                <Label for={form.currencies}>
                                  Currency Code
                                </Label>

                                {currencies.map((val, idx) => (
                                  <Row
                                    className="row d-flex align-items-center mb-4"
                                    key={idx}
                                  >
                                    <Col
                                      md={6}
                                      className="d-flex align-items-center"
                                    >
                                      <span className="mr-3">{idx + 1}</span>

                                      <Input
                                        type="text"
                                        name="currency"
                                        value={val}
                                        autoFocus={idx !== 0}
                                        onChange={(e) =>
                                          handleCycleChange(e, idx)
                                        }
                                        placeholder="Enter Currency Code"
                                      />
                                    </Col>

                                    <Col md={3} className="ml-4 ">
                                      {idx + 1 === currencies.length ? (
                                        <Button
                                          color="secondary"
                                          onClick={addNewStep}
                                          style={{ width: '120px' }}
                                          disabled={val?.length === 0}
                                        >
                                          Add
                                        </Button>
                                      ) : (
                                        <Button
                                          color="danger"
                                          onClick={() => removeStep(idx)}
                                          style={{ width: '120px' }}
                                          tabIndex="-1"
                                        >
                                          Delete
                                        </Button>
                                      )}
                                    </Col>
                                  </Row>
                                ))}
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6} className="mt-3">
                              <Button
                                disabled={
                                  currencies?.length === 1 ||
                                  currencies[0] === '' ||
                                  !formState.values.bankCode ||
                                  !formState.values.bankName
                                }
                                type="submit"
                                color="primary"
                                children="Create Decide Bank"
                                className="w-100 mb-4"
                              />
                            </Col>
                          </Row>
                        </Form>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        )}
        no={() => <Unauthorized />}
      />
    </Fragment>
  );
};

export default NewDecideBank;
