import React from 'react';
import GoogleLogin from 'react-google-login';
import { Redirect } from 'react-router-dom';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import { Loader, Logo } from '../../../components';

const LoginView = ({
  authenticated,
  loading,
  initiateLogin,
  loginFailure,
  referer,
}) => {
  return !authenticated ? (
    <section className="login">
      {loading && <Loader />}
      <Container>
        <Row className="justify-content-center">
          <Col lg={3} md={2} sm={1} />
          <Col lg={6} md={8} sm={10}>
            <Card className="o-hidden border-0 shadow-lg my-5">
              <CardBody className="p-0">
                <Row>
                  <Col>
                    <section className="d-block p-5 text-center">
                      <Logo className="mb-4" />

                      <GoogleLogin
                        clientId="137978631213-6ijb1usq09ga1pthci79rld1hnc3v8d6.apps.googleusercontent.com"
                        render={renderProps => (
                          <Button
                            block
                            color="primary"
                            className="btn-auth"
                            onClick={renderProps.onClick}
                            disabled={renderProps.disabled}
                            children="Login"
                          />
                        )}
                        onSuccess={response => initiateLogin(response, referer)}
                        onFailure={response => loginFailure(response)}
                        cookiePolicy="single_host_origin"
                      />
                    </section>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col lg={3} md={2} sm={1} />
        </Row>
      </Container>
    </section>
  ) : (
    <Redirect to={referer || '/dashboard'} />
  );
};

export default LoginView;
