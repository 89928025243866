import React, { Fragment } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useAuth } from '../../../context/authContext';
import { handleApiErrors } from '../../../lib/utils';
import { success } from '../../../config/constants';
import { deleteBank } from '../../../services';
import { toast } from 'react-toastify';

const DeleteBank = ({ selectedBank, toggle, isOpen, getBanks }) => {
  const { setLoading } = useAuth();

  const deleteDecideBank = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);
    const response = await deleteBank(selectedBank?.id);

    const apiErrors = handleApiErrors(response);

    setLoading(false);

    if (apiErrors) {
      toast.error(apiErrors);
      return;
    }
    toast.success(success.DECIDE_BANK_DELETED);
    if (response?.data?.status === 'success') {
      toggle();
      getBanks();
    }
  };
  return (
    <Fragment>
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>Confirm Delete</ModalHeader>
        <ModalBody>{`Are you sure you want to delete ${selectedBank?.name}?`}</ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={toggle}>
            Cancel
          </Button>
          <Button color="primary" onClick={deleteDecideBank}>
            Ok
          </Button>{' '}
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default DeleteBank;
