import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Alert,
  Badge,
  Button,
  Card,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  Row,
  Table,
} from 'reactstrap';
import { Can, PageTitle, Pagination, Unauthorized } from '../../components';
import { errors } from '../../config/constants';
import { useAuth } from '../../context/authContext';
import {
  extractNumber,
  filterMerchantAttribute,
  handleApiErrors,
} from '../../lib/utils';
import { fetchMerchants } from '../../services';

const Merchants = () => {
  const location = useLocation();
  const history = useHistory();
  const { setLoading } = useAuth();
  const initialPageNumber = location.search
    ? extractNumber(location.search)
    : 1;
  const [pageNumber, setPageNumber] = useState(initialPageNumber);
  const [error, setError] = useState(false);
  const [merchants, setMerchants] = useState([]);
  const [findParam, setFindParam] = useState('');

  const getMerchants = useCallback(async () => {
    setLoading(true);
    const response = await fetchMerchants({ skip: pageNumber * 20 - 20 });
    const apiErrors = handleApiErrors(response);
    setLoading(false);

    if (apiErrors) {
      toast.error(apiErrors);
      setError(apiErrors);
      return;
    }

    setMerchants(response.data.data.accounts);
  }, [pageNumber, setLoading]);

  useEffect(() => {
    getMerchants();

    window.scrollTo(0, 0);
  }, [getMerchants, pageNumber]);

  const handlePagination = async e => {
    const { value } = e.target;
    setPageNumber(parseInt(value));

    if (value > 1) {
      history.push(`merchants?page=${value}`);
    } else {
      history.push(`merchants`);
    }
  };

  const findMerchant = async e => {
    e.preventDefault();
    e.stopPropagation();

    if (!findParam) {
      toast.error(errors.ENTER_MERCHANT_SLUG);
      return;
    }

    setLoading(true);
    const response = await fetchMerchants({ slug: findParam });
    const apiErrors = handleApiErrors(response);
    setLoading(false);

    if (apiErrors) {
      toast.error(apiErrors);
      setError(apiErrors);
      return;
    }

    setMerchants(response.data.data.accounts);
  };

  return (
    <Fragment>
      <PageTitle
        title="Merchants"
        right={
          <Can
            perform="WRITE_MERCHANT"
            yes={() => (
              <Link to="/merchants/new" className="btn btn-primary">
                Add New
              </Link>
            )}
            no={() => ''}
          />
        }
        subtitle={`Page ${pageNumber}`}
      />

      <Can
        perform="READ_MERCHANT"
        yes={() => (
          <Container className="merchants">
            <Row>
              <Col lg={7} md={6} />
              <Col lg={5} md={6} className="mb-3">
                <Form onSubmit={findMerchant}>
                  <InputGroup>
                    <Input onChange={e => setFindParam(e.target.value)} />
                    <InputGroupAddon addonType="append">
                      <Button color="primary" className="px-4">
                        Search
                      </Button>
                    </InputGroupAddon>
                  </InputGroup>
                </Form>
              </Col>
              <Col xl={12}>
                <Card className="o-hidden border-0 shadow-lg mb-5">
                  <section className="p-3 p-md-4">
                    <Table borderless striped responsive hover className="m-0">
                      <thead>
                        <tr className="d-flex align-items-center">
                          <th className="col-4">Name</th>
                          <th className="col-3">Client Slug</th>
                          <th className="col-2 text-center">Status</th>
                          <th className="col-3" />
                        </tr>
                      </thead>

                      <tbody className="w-100">
                        {merchants.map((merchant, index) => (
                          <tr key={index} className="d-flex align-items-center">
                            <td className="col-4">{merchant.name}</td>

                            <td className="col-3">
                              {filterMerchantAttribute(
                                merchant.accountAttribute,
                                'clientSlug',
                              )}
                            </td>

                            <td className="col-2 text-center">
                              {merchant.status === 'active' ? (
                                <Badge
                                  pill
                                  color="success"
                                  children={merchant.status}
                                />
                              ) : merchant.status === 'inactive' ? (
                                <Badge
                                  pill
                                  color="danger"
                                  children={merchant.status}
                                />
                              ) : (
                                <Badge
                                  pill
                                  color="warning"
                                  children={merchant.status}
                                />
                              )}
                            </td>

                            <td align="right" className="col-3">
                              <Link
                                className="btn btn-primary btn-sm"
                                to={`/merchants/${filterMerchantAttribute(
                                  merchant.accountAttribute,
                                  'clientSlug',
                                )}`}
                              >
                                View merchant
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>

                    {findParam && merchants.length === 0 && (
                      <Alert color="warning" className="text-center">
                        Cannot find merchant with slug{' '}
                        <span className="font-weight-bold">{findParam}</span>
                        {/* <span onClick={getMerchants}>View all</span> */}
                        <Button color="link">View all</Button>
                      </Alert>
                    )}

                    {error && (
                      <div colSpan={3} className="text-center">
                        <Button color="primary" onClick={getMerchants}>
                          Reload
                        </Button>
                      </div>
                    )}
                  </section>
                </Card>
              </Col>

              <Col xs={12} className="mb-5">
                <Pagination
                  currentPage={pageNumber}
                  lastPage={20}
                  clickEvent={handlePagination}
                />
              </Col>
            </Row>
          </Container>
        )}
        no={() => <Unauthorized />}
      />
    </Fragment>
  );
};

export default Merchants;
