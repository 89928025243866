import React, { Fragment, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import {
  Card,
  Table,
  Button,
  FormGroup,
  Label,
  Input,
  Form,
  Row,
  Col,
  Badge,
} from 'reactstrap';
import { useFormState } from 'react-use-form-state';
import { handleApiErrors, isValidAccountNumber } from '../../../lib/utils';
import { createMerchantBankAccount } from '../../../services';
import { success, errors } from '../../../config/constants';
import { useAuth } from '../../../context/authContext';

const form = {
  accountName: 'accountName',
  accountNumber: 'accountNumber',
};

const SingleMerchantBankAccounts = ({
  merchantId,
  banks,
  merchantBanks,
  merchantFetchError,
  getMerchant,
}) => {
  const { setLoading } = useAuth();
  const [formState, { text }] = useFormState();
  const [openAddBankAccountForm, setOpenAddBankAccountForm] = useState(false);
  const [selectedBank, updateSelectedBank] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const updateBank = e => {
    const { value } = e.target;

    updateSelectedBank(value);
  };

  const submitForm = async e => {
    e.preventDefault();
    e.stopPropagation();

    const { accountName, accountNumber } = formState.values;

    if (!isValidAccountNumber(accountNumber)) {
      toast.error(errors.ACCOUNT_NUMBER_NOT_VALID);
      return;
    }

    const payload = {
      accountName,
      accountNumber,
      clientId: merchantId,
      bankId: selectedBank,
    };

    if (!selectedBank) {
      toast.error(errors.SELECT_BANK);
      return;
    }

    setLoading(true);
    const response = await createMerchantBankAccount(payload);
    const apiErrors = handleApiErrors(response);
    setLoading(false);

    if (apiErrors) {
      toast.error(apiErrors);
      return;
    }

    formState.clear();
    updateSelectedBank('');
    toggleForm();
    toast.success(success.MERCHANT_BANK_ACCOUNT_CREATED);
    getMerchant();
  };

  const toggleForm = () => {
    updateSelectedBank('');
    formState.clear();
    setOpenAddBankAccountForm(!openAddBankAccountForm);
  };

  return (
    <Fragment>
      <h4 className="mb-3 d-flex">
        <span>Bank Accounts</span>
        <Button
          size="sm"
          color={openAddBankAccountForm ? 'secondary' : 'primary'}
          className="ml-auto"
          onClick={toggleForm}
        >
          {openAddBankAccountForm ? 'View Bank Accounts' : 'Add Bank Accounts'}
        </Button>
      </h4>

      {openAddBankAccountForm && (
        <Card className="o-hidden border-0 p-4 shadow-lg mb-5">
          <Form className="p-4 p-md-5" onSubmit={submitForm}>
            <Row>
              <Col md={6}>
                <FormGroup tag="fieldset">
                  <Label for={form.accountName} className="text-capitalize">
                    Account Name
                  </Label>
                  <Input
                    type="text"
                    required
                    id={form.accountName}
                    {...text(form.accountName)}
                  />
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup tag="fieldset">
                  <Label for={form.accountNumber} className="text-capitalize">
                    Account Number
                  </Label>
                  <Input
                    type="text"
                    required
                    minLength={10}
                    id={form.accountNumber}
                    {...text(form.accountNumber)}
                  />
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup tag="fieldset">
                  <Label>Select Bank</Label>
                  <Input
                    type="select"
                    onChange={updateBank}
                    className="text-capitalize"
                  >
                    <option value="">-- Select Bank --</option>
                    {banks.map((bank, index) => (
                      <option value={bank.id} key={index}>
                        {bank.name}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
            </Row>

            <Button type="submit" color="primary" children="Add Bank Account" />
          </Form>
        </Card>
      )}

      {!openAddBankAccountForm && (
        <Card className="o-hidden border-0 p-4 shadow-lg mb-5">
          <Table borderless striped responsive hover className="m-0">
            <thead>
              <tr className="d-flex align-items-center">
                <th className="col-3">Bank Name</th>
                <th className="col-3">Account Name</th>
                <th className="col-3">Account Number</th>
                <th className="col-2">Status</th>
                <th className="col-2">Default</th>
              </tr>
            </thead>
            <tbody>
              {merchantBanks.map((bank, index) => (
                <tr key={index} className="d-flex align-items-center">
                  <td className="col-3">{bank.bank.name}</td>
                  <td className="col-3">{bank.accountName}</td>
                  <td className="col-3">{bank.accountNumber}</td>
                  <td className="col-2">
                    {bank.status === 'enabled' ? (
                      <Badge pill color="success" children={bank.status} />
                    ) : (
                      <Badge pill color="warning" children={bank.status} />
                    )}
                  </td>
                  <td className="col-2">{bank.isDefault ? 'Yes' : 'No'}</td>
                </tr>
              ))}

              {merchantFetchError && (
                <tr>
                  <td colSpan={4} className="text-center col-12">
                    <Button color="primary" onClick={getMerchant}>
                      Reload
                    </Button>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Card>
      )}
    </Fragment>
  );
};

SingleMerchantBankAccounts.defaultProps = {
  merchantId: '',
  banks: [],
  merchantBanks: [],
  merchantFetchError: false,
  getMerchant: null,
};

export default SingleMerchantBankAccounts;
