import PropTypes from 'prop-types';
import React, { Fragment, useState } from 'react';
import { Link, NavLink, useHistory } from 'react-router-dom';
import {
  Collapse,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarToggler,
  NavItem,
  Row,
  UncontrolledDropdown,
} from 'reactstrap';
import Can from '../../components/core/Can';
import Logo from '../icons/Logo';
import './_header.scss';

const Header = ({ loggedInUser, logout }) => {
  const history = useHistory();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(!dropdownOpen);

  return (
    <header className="header shadow-sm py-2 mb-4">
      <Container>
        <Row>
          <Navbar light expand="md" className="w-100">
            <Link to="/dashboard">
              <Logo className="logo" />
            </Link>

            <NavbarToggler onClick={toggle} />

            <Collapse isOpen={dropdownOpen} navbar className="mt-md-0 mt-2">
              <Nav className="ml-auto" navbar>
                <hr className="d-md-none" />
                <NavItem>
                  <NavLink
                    className="nav-link"
                    to="/dashboard"
                    children="Dashboard"
                  />
                </NavItem>

                <Can
                  perform="READ_MERCHANT"
                  yes={() => (
                    <Fragment>
                      <hr className="d-md-none" />
                      <NavItem className="ml-md-4">
                        <NavLink
                          className="nav-link"
                          to="/merchants"
                          children="Merchants"
                        />
                      </NavItem>
                    </Fragment>
                  )}
                />

                <Can
                  perform="READ_MERCHANT"
                  yes={() => (
                    <Fragment>
                      <hr className="d-md-none" />
                      <NavItem className="ml-md-4">
                        <NavLink
                          className="nav-link"
                          to="/decide-banks"
                          children="Decide Banks"
                        />
                      </NavItem>
                    </Fragment>
                  )}
                />

                <Can
                  perform="READ_REPORT"
                  yes={() => (
                    <Fragment>
                      <hr className="d-md-none" />
                      <NavItem className="ml-md-4">
                        <NavLink
                          className="nav-link"
                          to="/reports"
                          children="Reports"
                        />
                      </NavItem>
                    </Fragment>
                  )}
                />

                <hr className="d-md-none" />

                <UncontrolledDropdown nav inNavbar className="mt-md-0 mt-2">
                  <DropdownToggle nav caret className="ml-md-4 p-0">
                    <figure className="figure m-0">
                      <img
                        src={loggedInUser.imageUrl}
                        alt={loggedInUser.userName}
                        className="figure-img img-fluid rounded-circle m-0 mr-2 avatar"
                      />
                      <figcaption className="d-md-none mx-2 d-inline">
                        {loggedInUser.userName}
                      </figcaption>
                    </figure>
                  </DropdownToggle>

                  <DropdownMenu right className="mt-2 p-2 w-100">
                    <Fragment>
                      <DropdownItem
                        onClick={() => history.push('/settings')}
                        children="Settings"
                      />

                      <DropdownItem divider />
                    </Fragment>

                    <DropdownItem
                      className="logout"
                      onClick={logout}
                      children="Logout"
                    />
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Nav>
            </Collapse>
          </Navbar>
        </Row>
      </Container>
    </header>
  );
};

Header.defaultProps = {};

Header.propTypes = {
  logout: PropTypes.func,
};

export default Header;
