import React from 'react';
import { NavLink, Redirect, Route, Switch } from 'react-router-dom';
import { Col, Container, NavItem, Row } from 'reactstrap';
import { Roles, Users } from '../';
import { Sidebar } from '../../components';
import NewRole from '../NewRole';

const Settings = () => (
  <Container className="merchants">
    <Row>
      <Sidebar>
        <NavItem>
          <NavLink
            className="nav-link"
            to="/settings/users"
            children={`Users`}
          />
        </NavItem>
        <NavItem>
          <NavLink
            className="nav-link"
            to="/settings/roles"
            children={`Roles`}
          />
        </NavItem>
      </Sidebar>
      <Col md={8}>
        <Switch>
          <Route
            path="/settings/"
            exact
            render={() => (
              <Redirect
                to={{
                  pathname: `/settings/users`,
                }}
              />
            )}
          />
          <Route path="/settings/users" exact render={() => <Users />} />
          <Route path="/settings/roles" exact render={() => <Roles />} />
          <Route path="/settings/roles/new" exact render={() => <NewRole />} />
        </Switch>
      </Col>
    </Row>
  </Container>
);

Settings.defaultProps = {};

Settings.propTypes = {};

export default Settings;
