import React, { Fragment, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import NumberFormat from 'react-number-format';
import {
  Card,
  Button,
  FormGroup,
  Label,
  Input,
  Form,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import {
  removeUnderscore,
  handleApiErrors,
  splitCamelCase,
  removeCommas,
} from '../../../lib/utils';
import { success } from '../../../config/constants';
import { topUpMerchantWallet, updateMerchantWalletThreshold } from '../../../services';
import { useAuth } from '../../../context/authContext';

const form = {
  amountThreshold: 'amountThreshold',
};

const SingleMerchantWallet = ({
  merchantId,
  merchantName,
  merchantWallet,
  merchantFetchError,
  getMerchant,
}) => {
  const { setLoading } = useAuth();
  const [thresState, setThresState] = useState('');
  const [modal, setModal] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const updateThreshold = (e) => {
    const { value } = e.target;
    setThresState(value);
  }

  const submitForm = async e => {
    e.preventDefault();
    e.stopPropagation();

    const payload = {
      clientId: merchantId,
      amountThreshold: parseFloat(removeCommas(thresState)),
    };


    setLoading(true);
    const response = await updateMerchantWalletThreshold(payload);
    const apiErrors = handleApiErrors(response);
    setLoading(false);

    if (apiErrors) {
      toast.error(apiErrors);
      return;
    }

    toast.success(success.MERCHANT_WALLET_THRESHOLD_UPDATED);
    getMerchant();
  };



  const toggleModal = () => setModal(!modal);

  const topUpMerchantWalletBalance = async (e) => {
    e.preventDefault();
    
    const payload = {
      clientId: merchantId,
      clientName: merchantName,
    };

    setLoading(true);
    const response = await topUpMerchantWallet(payload);
    const apiErrors = handleApiErrors(response);
    toggleModal();
    setLoading(false);

    if (apiErrors) {
      toast.error(apiErrors);
      return;
    }

    toast.success(success.MERCHANT_WALLET_UPDATED);
    
  };


  return (
    <Fragment>
      <h4 className="mb-3">Wallet</h4>

        <Card className="o-hidden border-0 p-4 shadow-lg mb-5">
        <Col md={8}>
          <Form className="p-4 p-md-1" onSubmit={submitForm}>
            <Row>
            <Col md={6}>
                  <FormGroup>
                    <Label
                      htmlFor={form.amountThreshold}
                      className="text-capitalize"
                    >
                      {splitCamelCase(removeUnderscore(form.amountThreshold))}
                    </Label>
                    <NumberFormat
                      type="text"
                      id={form.amountThreshold}
                      customInput={Input}
                      thousandSeparator={true}
                      defaultValue={merchantWallet}
                      onChange={updateThreshold}
                    />
                  </FormGroup>
                </Col>
           </Row>

            <Button color="primary" type="submit" className="ml-auto" size="sm">
              Update Amount Threshold
            </Button>

          </Form>

          <div className="row mt-5">
            <div className="col mt-2">Top Up Merchant Wallet</div>
          <Button
              color="primary"
              className="ml-auto md-6 col"
              onClick={() => {
                toggleModal();
              }}
            >
              Top Up Merchant Wallet
          </Button>
          </div>

          </Col>


          <Modal isOpen={modal} toggle={toggleModal}>
              <ModalHeader toggle={toggleModal}>Top Up Merchant Wallet </ModalHeader>
              <ModalBody>
                Do you want to top up <b>{merchantName}'s</b> wallet balance?
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={topUpMerchantWalletBalance}>
                  Accept
                </Button>{' '}
                <Button color="secondary" onClick={toggleModal}>
                  Cancel
                </Button>
              </ModalFooter>  
          </Modal>


          {merchantFetchError && (
            <div className="text-center col-12">
              <Button color="primary" onClick={getMerchant}>
                Reload
              </Button>
            </div>
          )}

        </Card>
      
    </Fragment>
  );
};

SingleMerchantWallet.defaultProps = {
  merchantId: '',
  merchantName: '',
  merchantWallet: '',
  merchantFetchError: false,
};

export default SingleMerchantWallet;
