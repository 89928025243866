import PropTypes from 'prop-types';
import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import './_pagetitle.scss';

const PageTitle = ({ title, right, subtitle }) => (
  <Container className="mb-4">
    <Row>
      <Col>
        <h1 className="h3 m-0">{title}</h1>
      </Col>
      {right && <Col className="text-right">{right}</Col>}
    </Row>
    {subtitle && (
      <Row>
        <Col xs={12}>
          <p className="text-muted mb-1">{subtitle}</p>
        </Col>
      </Row>
    )}
  </Container>
);

PageTitle.propTypes = {
  left: PropTypes.string,
  right: PropTypes.any,
};

export default PageTitle;
