import React, { Fragment, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import {
  Card,
  Table,
  Button,
  FormGroup,
  Label,
  Input,
  Form,
  Row,
  Col,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { useFormState } from 'react-use-form-state';
import {
  removeUnderscore,
  handleApiErrors,
  isValidEmail,
  isValidPhone,
} from '../../../lib/utils';
import { success, errors, clientUserRoles } from '../../../config/constants';
import { createMerchantUser, deleteMerchantUser } from '../../../services';
import { useAuth } from '../../../context/authContext';

const form = {
  firstName: 'firstName',
  lastName: 'lastName',
  email: 'email',
  phone: 'phone',
};

const SingleMerchantUsers = ({
  merchantId,
  merchantUsers,
  merchantFetchError,
  getMerchant,
}) => {
  const { setLoading } = useAuth();
  const [formState, { text, email, tel }] = useFormState();
  const [openAddUserForm, setOpenAddUserForm] = useState(false);
  const [selectedRole, updateSelectedRole] = useState('');
  const [modal, setModal] = useState(false);
  const [activeUser, setActiveUser] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const updateRole = e => {
    const { value } = e.target;

    updateSelectedRole(value);
  };

  const submitForm = async e => {
    e.preventDefault();
    e.stopPropagation();

    const { firstName, lastName, email, phone } = formState.values;

    let allInputValid = true;

    if (!isValidPhone(phone)) {
      toast.error(errors.PHONE_NOT_VALID);
      allInputValid = false;
    }

    if (!isValidEmail(email)) {
      toast.error(errors.EMAIL_NOT_VALID);
      allInputValid = false;
    }

    if (!allInputValid) return;

    const payload = {
      firstName,
      lastName,
      email,
      phone,
      clientId: merchantId,
      roles: [selectedRole],
    };

    if (!clientUserRoles.includes(selectedRole)) {
      toast.error(errors.SELECT_ROLE);
      return;
    }

    setLoading(true);
    const response = await createMerchantUser(payload);
    const apiErrors = handleApiErrors(response);
    setLoading(false);

    if (apiErrors) {
      toast.error(apiErrors);
      return;
    }

    formState.clear();
    updateSelectedRole('');
    toggleForm();
    toast.success(success.MERCHANT_USER_CREATED);
    getMerchant();
  };

  const toggleForm = () => {
    setOpenAddUserForm(!openAddUserForm);
  };

  const toggleModal = () => setModal(!modal);

  const deleteUser = async () => {
    const payload = {
      clientId: merchantId,
      userId: activeUser.id,
    };

    setLoading(true);
    const response = await deleteMerchantUser(payload);
    const apiErrors = handleApiErrors(response);
    toggleModal();
    setLoading(false);

    if (apiErrors) {
      toast.error(apiErrors);
      return;
    }

    toast.success(success.MERCHANT_USER_DELETED);
    getMerchant();
  };

  return (
    <Fragment>
      <h4 className="mb-3 d-flex">
        <span>Users</span>
        <Button
          size="sm"
          color={openAddUserForm ? 'secondary' : 'primary'}
          className="ml-auto"
          onClick={toggleForm}
        >
          {openAddUserForm ? 'View Users' : 'Add User'}
        </Button>
      </h4>

      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Delete User</ModalHeader>
        <ModalBody>
          Delete user <b>{activeUser.email}</b>?
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={deleteUser}>
            Yes
          </Button>{' '}
          <Button color="secondary" onClick={toggleModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      {openAddUserForm && (
        <Card className="o-hidden border-0 p-4 shadow-lg mb-5">
          <Form className="p-4 p-md-5" onSubmit={submitForm}>
            <Row>
              <Col md={6}>
                <FormGroup tag="fieldset">
                  <Label for={form.firstName} className="text-capitalize">
                    {form.firstName}
                  </Label>
                  <Input
                    type="text"
                    required
                    minLength={2}
                    id={form.firstName}
                    {...text(form.firstName)}
                  />
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup tag="fieldset">
                  <Label for={form.lastName} className="text-capitalize">
                    {form.lastName}
                  </Label>
                  <Input
                    type="text"
                    required
                    minLength={2}
                    id={form.lastName}
                    {...text(form.lastName)}
                  />
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup tag="fieldset">
                  <Label for={form.email} className="text-capitalize">
                    {form.email}
                  </Label>
                  <Input
                    type="email"
                    required
                    id={form.email}
                    {...email(form.email)}
                  />
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup tag="fieldset">
                  <Label for={form.phone} className="text-capitalize">
                    {form.phone}
                  </Label>
                  <Input
                    type="tel"
                    required
                    maxLength={11}
                    id={form.phone}
                    {...tel(form.phone)}
                  />
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup tag="fieldset">
                  <Label>Select Role</Label>
                  <Input
                    type="select"
                    onChange={updateRole}
                    className="text-capitalize"
                  >
                    <option value="">-- Select Role --</option>
                    {clientUserRoles.map((role, index) => (
                      <option value={role} key={index}>
                        {removeUnderscore(role)}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
            </Row>

            <Button type="submit" color="primary" children="Add User" />
          </Form>
        </Card>
      )}

      {!openAddUserForm && (
        <Card className="o-hidden border-0 p-4 shadow-lg mb-5">
          <Table borderless striped responsive hover className="m-0">
            <thead>
              <tr className="d-flex align-items-center">
                <th className="col-3">Email</th>
                <th className="col-2">Phone</th>
                <th className="col-3">Role</th>
                <th className="col-2">Status</th>
                <th className="col-1"></th>
              </tr>
            </thead>
            <tbody>
              {merchantUsers.map((user, index) => (
                <tr key={index} className="d-flex align-items-center">
                  <td className="col-3">{user.user.email}</td>
                  <td className="col-2">{user.user.phone}</td>
                  <td className="col-3">
                    {user.roles[0] && user.roles[0].name}
                  </td>
                  <td className="col-2">
                    {user.status === 'active' ? (
                      <Badge pill color="success" children={user.status} />
                    ) : user.status === 'deleted' ? (
                      <Badge pill color="danger" children={user.status} />
                    ) : (
                      <Badge pill color="warning" children={user.status} />
                    )}
                  </td>
                  <td className="col-1">
                    {user.status !== 'deleted' && (
                      <Button
                        size="sm"
                        color="secondary"
                        className="ml-auto"
                        onClick={() => {
                          setActiveUser({
                            id: user.user.id,
                            email: user.user.email,
                          });
                          toggleModal();
                        }}
                      >
                        Delete
                      </Button>
                    )}
                  </td>
                </tr>
              ))}

              {merchantFetchError && (
                <tr>
                  <td colSpan={4} className="text-center col-12">
                    <Button color="primary" onClick={getMerchant}>
                      Reload
                    </Button>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Card>
      )}
    </Fragment>
  );
};

SingleMerchantUsers.defaultProps = {
  merchantName: '',
  merchantUsers: [],
  merchantFetchError: false,
  getMerchant: null,
};

export default SingleMerchantUsers;
