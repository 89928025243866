import requestClient from '../lib/requestClient';
import { errorHandler } from '../lib/utils';

/**
 *  @typedef {{
 *            thisUser: Boolean,
 *          }}
 */
let FetchUsersParams;

/**
 * @param {FetchUsersParams} params
 * @return {Object | Array} User Object or Array of all users
 */

export const fetchUsers = params => {
  const { thisUser } = params || {};
  const query = thisUser ? '' : 'all';

  return requestClient()
    .get(`/api/v1/users/${query}`)
    .catch(error => {
      return { errorMessage: errorHandler(error) };
    });
};

/**
 * @param {String} userId
 * @return {Object} user
 */

export const activateUser = userId => {
  return requestClient()
    .put(`/api/v1/users/activate/${userId}`)
    .catch(error => {
      return { errorMessage: errorHandler(error) };
    });
};

/**
 * @param {String} userId
 * @return {Object} user
 */

export const deactivateUser = userId => {
  return requestClient()
    .put(`/api/v1/users/deactivate/${userId}`)
    .catch(error => {
      return { errorMessage: errorHandler(error) };
    });
};

/**
 *  @typedef {{
 *            userId: String,
 *            roleId: String,
 *          }}
 */
let UpdateUserRolePayload;

/**
 * @param {UpdateUserRolePayload} params
 * @return {Array} Array of all Roles
 */

export const updateUserRole = params => {
  const { userId, roleId } = params || {};

  return requestClient()
    .post(`/api/v1/users/addrole`, { id: userId, roleId })
    .catch(error => {
      return { errorMessage: errorHandler(error) };
    });
};
