import React, { Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useFormState } from 'react-use-form-state';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Row,
} from 'reactstrap';
import { Can, PageTitle, Textarea, Unauthorized } from '../../components';
import { success, errors } from '../../config/constants';
import { useAuth } from '../../context/authContext';
import {
  handleApiErrors,
  removeUnderscore,
  splitCamelCase,
  isValidEmail,
  isValidPhone,
  isValidColor,
} from '../../lib/utils';
import {
  createMerchantAttributes,
  createNewMerchant,
  uploadAttributeImage,
} from '../../services';

const NewMerchant = () => {
  const history = useHistory();
  const { setLoading } = useAuth();
  const [formState, { text, email, tel, textarea }] = useFormState();
  const [stepNumber, setStepNumber] = useState(1);
  const [merchantId, setMerchantId] = useState(false);
  const [merchantSlug, setMerchantSlug] = useState(false);
  const [logoFile, setLogoFile] = useState();
  const [faviconFile, setFaviconFile] = useState();

  const form = {
    firstName: 'firstName',
    lastName: 'lastName',
    email: 'email',
    phone: 'phone',
    address: 'address',
    accountName: 'accountName',
    slug: 'slug',

    // Attributes Creation Form
    logo: 'logo',
    favicon: 'favicon',

    logoUrl: 'logoUrl',
    faviconUrl: 'faviconUrl',
    clientTheme__primaryColor: 'clientTheme__primaryColor',
    clientTheme__secondaryColor: 'clientTheme__secondaryColor',
    twilioVerifySID: 'twilioVerifySID',
    contactDetails__phone: 'contactDetails__phone',
    contactDetails__email: 'contactDetails__email',
    facebookAppKeys__appId: 'facebookAppKeys__appId',
    facebookAppKeys__appSecret: 'facebookAppKeys__appSecret',

    verifyWorkEmail: 'verifyWorkEmail',
  };

  const createMerchant = async e => {
    e.preventDefault();
    e.stopPropagation();

    const {
      firstName,
      lastName,
      email,
      phone,
      address,
      accountName,
      slug,
    } = formState.values;

    let allInputValid = true;

    if (!isValidPhone(phone)) {
      toast.error(errors.PHONE_NOT_VALID);
      allInputValid = false;
    }

    if (!isValidEmail(email)) {
      toast.error(errors.EMAIL_NOT_VALID);
      allInputValid = false;
    }

    if (!allInputValid) return;

    setLoading(true);
    const response = await createNewMerchant({
      firstName,
      lastName,
      email,
      phone,
      address,
      accountName,
      slug,
    });
    const apiErrors = handleApiErrors(response);
    setLoading(false);

    if (apiErrors) {
      toast.error(apiErrors);
      return;
    }

    toast.success(success.MERCHANT_CREATED);
    setMerchantId(response.data.data.account.id);
    setMerchantSlug(response.data.data.slug);
    setStepNumber(3);
  };

  const handleFileSelection = e => {
    const { name, files } = e.target;
    const file = files[0];

    if (name === form.logoUrl) {
      setLogoFile(file);
    } else if (name === form.faviconUrl) {
      setFaviconFile(file);
    }
  };

  const createFormData = (slug, attributeName, image) => {
    const formData = new FormData();
    formData.append('slug', slug);
    formData.append('attributeName', attributeName);
    formData.append('image', image);

    return formData;
  };

  const createAttribute = async e => {
    e.preventDefault();
    e.stopPropagation();

    const {
      clientTheme__primaryColor,
      clientTheme__secondaryColor,
      twilioVerifySID,
      contactDetails__phone,
      contactDetails__email,
      facebookAppKeys__appId,
      facebookAppKeys__appSecret,
      verifyWorkEmail,
    } = formState.values;

    let allInputValid = true;

    if (!isValidPhone(contactDetails__phone)) {
      toast.error(errors.PHONE_NOT_VALID);
      allInputValid = false;
    }

    if (!isValidEmail(contactDetails__email)) {
      toast.error(errors.EMAIL_NOT_VALID);
      allInputValid = false;
    }

    if (
      !isValidColor(clientTheme__primaryColor) ||
      !isValidColor(clientTheme__secondaryColor)
    ) {
      toast.error(errors.COLOR_NOT_VALID);
      allInputValid = false;
    }

    if (!allInputValid) return;

    let logoUrl;
    let faviconUrl;

    setLoading(true);

    if (logoFile) {
      const formData = createFormData(merchantSlug, 'logo', logoFile);
      const response = await uploadAttributeImage(formData);
      const apiErrors = handleApiErrors(response);

      if (apiErrors) {
        setLoading(false);
        toast.error(apiErrors);
        return;
      }

      logoUrl = response.data.data.url;
    }

    if (faviconFile) {
      const formData = createFormData(merchantSlug, 'favicon', faviconFile);
      const response = await uploadAttributeImage(formData);
      const apiErrors = handleApiErrors(response);

      if (apiErrors) {
        setLoading(false);
        toast.error(apiErrors);
        return;
      }

      faviconUrl = response.data.data.url;
    }

    const payload = {
      merchantId: { accountId: merchantId },
      attributes: {
        logoUrl,
        faviconUrl,
        twilioVerifySID,
        verifyWorkEmail,

        clientTheme: {
          primaryColor: clientTheme__primaryColor,
          secondaryColor: clientTheme__secondaryColor,
        },
        contactDetails: {
          phone: contactDetails__phone,
          email: contactDetails__email,
        },
        facebookAppKeys: {
          appId: facebookAppKeys__appId,
          appSecret: facebookAppKeys__appSecret,
        },
      },
    };

    const response = await createMerchantAttributes(payload);
    const apiErrors = handleApiErrors(response);
    setLoading(false);

    if (apiErrors) {
      toast.error(apiErrors);
      return;
    }

    formState.clear();
    toast.success(success.MERCHANT_ATTRIBUTE_UPDATED);

    history.push(`/merchants/${merchantSlug}`);
  };

  return (
    <Fragment>
      <PageTitle title="New Merchant" />

      <Can
        perform="WRITE_MERCHANT"
        yes={() => (
          <Container className="merchants">
            <Row>
              <Col xl={12}>
                <Card className="o-hidden border-0 shadow-lg mb-5">
                  <CardBody className="p-0">
                    <Row>
                      {stepNumber === 1 ? (
                        <Col lg={12} md={12}>
                          <Form
                            onSubmit={createMerchant}
                            className="p-4 p-md-5"
                          >
                            <Row>
                              <Col md={6}>
                                <FormGroup>
                                  <Label for={form.firstName}>First Name</Label>
                                  <Input
                                    type="text"
                                    autoFocus
                                    required
                                    id={form.firstName}
                                    {...text(form.firstName)}
                                  />
                                </FormGroup>
                              </Col>
                              <Col md={6}>
                                <FormGroup>
                                  <Label for={form.lastName}>Last Name</Label>
                                  <Input
                                    type="text"
                                    required
                                    id={form.lastName}
                                    {...text(form.lastName)}
                                  />
                                </FormGroup>
                              </Col>
                              <Col md={6}>
                                <FormGroup>
                                  <Label for={form.phone}>Phone Number</Label>
                                  <Input
                                    type="tel"
                                    required
                                    maxLength={11}
                                    id={form.phone}
                                    {...tel(form.phone)}
                                  />
                                </FormGroup>
                              </Col>
                              <Col md={6}>
                                <FormGroup>
                                  <Label for={form.email}>Contact Email</Label>
                                  <Input
                                    type="email"
                                    required
                                    id={form.email}
                                    {...email(form.email)}
                                  />
                                </FormGroup>
                              </Col>
                              <Col md={6}>
                                <FormGroup>
                                  <Label for={form.accountName}>
                                    Merchant Name
                                  </Label>
                                  <Input
                                    type="text"
                                    required
                                    id={form.accountName}
                                    {...text(form.accountName)}
                                  />
                                </FormGroup>
                              </Col>
                              <Col md={6}>
                                <FormGroup>
                                  <Label for={form.slug}>Merchant Slug</Label>
                                  <InputGroup>
                                    <Input
                                      required
                                      className="text-right"
                                      id={form.slug}
                                      {...text(form.slug)}
                                    />
                                    <InputGroupAddon addonType="append">
                                      <InputGroupText>
                                        .originate.ng
                                      </InputGroupText>
                                    </InputGroupAddon>
                                  </InputGroup>
                                </FormGroup>
                              </Col>
                              <Col xs={12}>
                                <FormGroup>
                                  <Label for={form.address}>
                                    Contact Address
                                  </Label>
                                  <Textarea
                                    required
                                    id={form.address}
                                    {...textarea(form.address)}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={6} className="mt-3">
                                <Button
                                  type="submit"
                                  color="primary"
                                  children="Create Merchant"
                                  className="w-100 mb-4"
                                />
                              </Col>
                            </Row>
                          </Form>
                        </Col>
                      ) : stepNumber === 2 ? (
                        <Fragment>
                          <Col xl={1} />
                          <Col xl={10} md={12}>
                            <section className="p-4 p-md-5">
                              <FormGroup tag="fieldset">
                                <legend>Uploads</legend>
                                <Row>
                                  <Col md={6}>
                                    <FormGroup>
                                      <Label
                                        htmlFor={form.logo}
                                        className="text-capitalize"
                                      >
                                        Merchant Logo
                                      </Label>
                                      <Input
                                        type="file"
                                        required
                                        id={form.logo}
                                        name={form.logo}
                                        onChange={handleFileSelection}
                                        accept="image/*"
                                      />
                                    </FormGroup>
                                  </Col>

                                  <Col md={6}>
                                    <FormGroup>
                                      <Label
                                        htmlFor={form.favicon}
                                        className="text-capitalize"
                                      >
                                        Merchant Favicon (512 x 512)
                                      </Label>
                                      <Input
                                        type="file"
                                        id={form.favicon}
                                        name={form.favicon}
                                        onChange={handleFileSelection}
                                        accept="image/*"
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </FormGroup>
                            </section>
                          </Col>
                        </Fragment>
                      ) : (
                        <Fragment>
                          <Col xl={1} />
                          <Col xl={10} md={12}>
                            <Form
                              className="p-4 p-md-5"
                              onSubmit={createAttribute}
                            >
                              <FormGroup tag="fieldset">
                                <legend className="mb-0">
                                  Merchant Details
                                </legend>
                                <hr className="mb-4" />
                                <Row>
                                  <Col md={6}>
                                    <FormGroup>
                                      <Label
                                        htmlFor={form.logoUrl}
                                        className="text-capitalize"
                                      >
                                        Merchant Logo (512 x 512)
                                      </Label>
                                      <Input
                                        type="file"
                                        id={form.logoUrl}
                                        name={form.logoUrl}
                                        required
                                        onChange={handleFileSelection}
                                        accept="image/*"
                                      />
                                    </FormGroup>
                                  </Col>

                                  <Col md={6}>
                                    <FormGroup>
                                      <Label
                                        htmlFor={form.faviconUrl}
                                        className="text-capitalize"
                                      >
                                        Merchant Favicon (512 x 512)
                                      </Label>
                                      <Input
                                        type="file"
                                        id={form.faviconUrl}
                                        name={form.faviconUrl}
                                        required
                                        onChange={handleFileSelection}
                                        accept="image/*"
                                      />
                                    </FormGroup>
                                  </Col>

                                  <Col md={6}>
                                    <FormGroup>
                                      <Label
                                        htmlFor="clientTheme__primaryColor"
                                        className="text-capitalize"
                                      >
                                        {splitCamelCase(
                                          removeUnderscore(
                                            form.clientTheme__primaryColor,
                                          ),
                                        )}
                                      </Label>
                                      <Input
                                        type="text"
                                        required
                                        id={form.clientTheme__primaryColor}
                                        {...text(
                                          form.clientTheme__primaryColor,
                                        )}
                                      />
                                    </FormGroup>
                                  </Col>

                                  <Col md={6}>
                                    <FormGroup>
                                      <Label
                                        htmlFor="clientTheme__secondaryColor"
                                        className="text-capitalize"
                                      >
                                        {splitCamelCase(
                                          removeUnderscore(
                                            form.clientTheme__secondaryColor,
                                          ),
                                        )}
                                      </Label>
                                      <Input
                                        type="text"
                                        required
                                        id={form.clientTheme__secondaryColor}
                                        {...text(
                                          form.clientTheme__secondaryColor,
                                        )}
                                      />
                                    </FormGroup>
                                  </Col>

                                  <Col md={6}>
                                    <FormGroup>
                                      <Label
                                        htmlFor="contactDetails__phone"
                                        className="text-capitalize"
                                      >
                                        Support Phone
                                      </Label>
                                      <Input
                                        type="tel"
                                        required
                                        maxLength={11}
                                        id={form.contactDetails__phone}
                                        {...text(form.contactDetails__phone)}
                                      />
                                    </FormGroup>
                                  </Col>

                                  <Col md={6}>
                                    <FormGroup>
                                      <Label
                                        htmlFor="contactDetails__email"
                                        className="text-capitalize"
                                      >
                                        Support Email
                                      </Label>
                                      <Input
                                        type="email"
                                        required
                                        id={form.contactDetails__email}
                                        {...text(form.contactDetails__email)}
                                      />
                                    </FormGroup>
                                  </Col>

                                  <Col md={6}>
                                    <FormGroup>
                                      <Label
                                        htmlFor="twilioVerifySID"
                                        className="text-capitalize"
                                      >
                                        {splitCamelCase(
                                          removeUnderscore(
                                            form.twilioVerifySID,
                                          ),
                                        )}
                                      </Label>
                                      <Input
                                        type="text"
                                        id={form.twilioVerifySID}
                                        {...text(form.twilioVerifySID)}
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </FormGroup>

                              <FormGroup tag="fieldset">
                                <legend className="mb-0">Integrations</legend>
                                <hr className="mb-4" />
                                <Row>
                                  <Col md={6}>
                                    <FormGroup>
                                      <Label
                                        htmlFor="facebookAppKeys__appId"
                                        className="text-capitalize"
                                      >
                                        {splitCamelCase(
                                          removeUnderscore(
                                            form.facebookAppKeys__appId,
                                          ),
                                        )}
                                      </Label>
                                      <Input
                                        type="text"
                                        id={form.facebookAppKeys__appId}
                                        {...text(form.facebookAppKeys__appId)}
                                      />
                                    </FormGroup>
                                  </Col>

                                  <Col md={6}>
                                    <FormGroup>
                                      <Label
                                        htmlFor="facebookAppKeys__appSecret"
                                        className="text-capitalize"
                                      >
                                        {splitCamelCase(
                                          removeUnderscore(
                                            form.facebookAppKeys__appSecret,
                                          ),
                                        )}
                                      </Label>
                                      <Input
                                        type="text"
                                        id={form.facebookAppKeys__appSecret}
                                        {...text(
                                          form.facebookAppKeys__appSecret,
                                        )}
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </FormGroup>

                              <Button color="primary" type="submit">
                                Update Merchant
                              </Button>
                            </Form>
                          </Col>
                        </Fragment>
                      )}
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        )}
        no={() => <Unauthorized />}
      />
    </Fragment>
  );
};

export default NewMerchant;
