import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useFormState } from 'react-use-form-state';
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import { Can, PageTitle, Unauthorized } from '../../components';
import { errors, success } from '../../config/constants';
import { useAuth } from '../../context/authContext';
import { handleApiErrors, removeUnderscore, titleCase } from '../../lib/utils';
import { createNewRole, fetchPermissions } from '../../services';

const NewRole = () => {
  const history = useHistory();
  const { setLoading } = useAuth();
  const [formState, { text, textarea, checkbox }] = useFormState();
  const [permissions, setPermissions] = useState([]);
  const [permissionError, setPermissionError] = useState(false);

  const getPermissions = useCallback(async () => {
    setLoading(true);
    const response = await fetchPermissions();
    const apiErrors = handleApiErrors(response);
    setLoading(false);

    if (apiErrors) {
      toast.error(apiErrors);
      setPermissionError(true);
      return;
    }

    setPermissions(response.data.data);
  }, [setLoading]);

  useEffect(() => {
    getPermissions();
  }, [getPermissions]);

  const createRole = async e => {
    e.preventDefault();
    e.stopPropagation();

    const { roleName, roleDescription, rolePermissions } = formState.values;

    if (rolePermissions.length === 0) {
      toast.error(errors.SELECT_PERMISSION);
      return;
    }

    setLoading(true);
    const response = await createNewRole({
      name: roleName,
      description: roleDescription,
      permissions: rolePermissions,
    });
    const apiErrors = handleApiErrors(response);
    setLoading(false);

    if (apiErrors) {
      toast.error(apiErrors);
      return;
    }

    toast.success(success.ROLE_CREATED);
    setTimeout(() => history.push(`/settings/roles`), 4000);
  };

  return (
    <Fragment>
      <PageTitle title="New Role" />

      <Can
        perform="WRITE_ROLE"
        yes={() => (
          <Row>
            <Col xs={12}>
              <Card className="o-hidden border-0 shadow-lg mb-5">
                <CardBody className="p-0">
                  <Row>
                    <Col xs={12}>
                      <Form onSubmit={createRole} className="p-4 p-md-5">
                        <Row>
                          <Col xs={12}>
                            <FormGroup>
                              <Label for="roleName">Role name</Label>
                              <Input
                                type="text"
                                id="roleName"
                                autoFocus
                                required
                                {...text('roleName')}
                              />
                            </FormGroup>
                          </Col>
                          <Col xs={12}>
                            <FormGroup>
                              <Label for="roleDescription">Description</Label>
                              <Input
                                id="roleDescription"
                                className="textarea"
                                required
                                {...textarea('roleDescription')}
                              />
                            </FormGroup>
                          </Col>
                          <Col xs={12}>
                            <p className="d-block mb-2">Permissions</p>

                            {permissions.map((permission, index) => (
                              <FormGroup check inline key={index}>
                                <Label check className="mb-2">
                                  <Input
                                    type="checkbox"
                                    {...checkbox(
                                      'rolePermissions',
                                      permission.name,
                                    )}
                                  />{' '}
                                  Can{' '}
                                  {titleCase(removeUnderscore(permission.name))}
                                </Label>
                              </FormGroup>
                            ))}

                            {permissionError && (
                              <Alert
                                color="danger text-center"
                                className="mb-4"
                                children={`${
                                  typeof permissionError === 'string'
                                    ? permissionError
                                    : 'Cannot get permissions. Please, try again later.'
                                }`}
                              />
                            )}
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={6} className="mt-3">
                            <Button
                              type="submit"
                              color="primary"
                              children="Create Role"
                              className="w-100 mb-4"
                              disabled={permissionError}
                            />
                          </Col>
                        </Row>
                      </Form>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
        no={() => <Unauthorized />}
      />
    </Fragment>
  );
};

export default NewRole;
