import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useFormState } from 'react-use-form-state';
import {
  Button,
  Card,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from 'reactstrap';
import { Can, PageTitle, Unauthorized } from '../../components';
import { useAuth } from '../../context/authContext';
import { handleApiErrors, removeUnderscore, titleCase } from '../../lib/utils';
import { fetchPermissions, fetchRoles, updateRole } from '../../services';

const Roles = () => {
  const { loggedInUser, setLoading } = useAuth();
  const [modal, setModal] = useState(false);
  const [selectedRole, setSelectedRole] = useState('');
  const [rolesFetchError, setRolesFetchError] = useState(false);
  const [formState, { checkbox }] = useFormState();
  const [roles, setRoles] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const form = {
    rolePermissions: 'rolePermissions',
  };

  const toggleModal = async role => {
    if (role && role.id) {
      if (permissions.length === 0) {
        await getPermissions();
      }

      setSelectedRole(role);
      formState.setField(form.rolePermissions, role.permissions);
    } else {
      formState.clear();
    }
    setModal(!modal);
  };

  const getRoles = useCallback(async () => {
    setLoading(true);
    const response = await fetchRoles();
    const apiErrors = handleApiErrors(response);
    setLoading(false);

    if (apiErrors) {
      toast.error(apiErrors);
      setRolesFetchError(apiErrors);
      return;
    }

    setRoles(response.data.data);
  }, [setLoading]);

  const getPermissions = useCallback(async () => {
    setLoading(true);
    const response = await fetchPermissions();
    const apiErrors = handleApiErrors(response);
    setLoading(false);

    if (apiErrors) {
      toast.error(apiErrors);
      setRolesFetchError(apiErrors);
      return;
    }

    setPermissions(response.data.data);
  }, [setLoading]);

  const modifyRole = async () => {
    const payload = {
      roleId: selectedRole.id,
      permissions: formState.values.rolePermissions,
    };

    setLoading(true);
    const response = await updateRole(payload);
    const apiErrors = handleApiErrors(response);
    setLoading(false);

    if (apiErrors) {
      toast.error(apiErrors);
      setRolesFetchError(apiErrors);
      return;
    }

    formState.clear();
    toggleModal();
    getRoles();
  };

  useEffect(() => {
    getRoles();
    getPermissions();
  }, [getPermissions, getRoles]);

  return (
    <Fragment>
      <PageTitle
        title="Roles"
        right={
          <Can
            perform="WRITE_ROLE"
            yes={() => (
              <Link to="/settings/roles/new" className="btn btn-primary">
                Add Role
              </Link>
            )}
            no={() => ''}
          />
        }
      />

      <Can
        perform="READ_ROLE"
        yes={() => (
          <Row>
            <Col xl={12}>
              <Card className="o-hidden border-0 shadow-lg mb-5">
                <section className="p-3 p-md-4">
                  <Row>
                    <Col>
                      <Table
                        borderless
                        striped
                        responsive
                        hover
                        className="m-0"
                      >
                        <thead>
                          <tr className="d-flex align-items-center">
                            <th className="col-4">Name</th>
                            <th className="col-5">Permissions</th>
                            <Can
                              perform="WRITE_ROLE"
                              yes={() => <th className="col-3" />}
                            />
                          </tr>
                        </thead>
                        <tbody>
                          {roles.map((role, index) => (
                            <tr
                              key={index}
                              className="d-flex align-items-center"
                            >
                              <td className="col-4">
                                {titleCase(removeUnderscore(role.name))}
                              </td>
                              <td className="col-5">
                                {role.permissions.map((permission, index) => (
                                  <li className="list-inline-item" key={index}>
                                    {titleCase(removeUnderscore(permission))}
                                    {index < role.permissions.length - 1 && ','}
                                  </li>
                                ))}
                              </td>
                              <Can
                                perform="WRITE_ROLE"
                                yes={() => (
                                  <td align="right" className="col-3">
                                    {loggedInUser.role.name !== role.name && (
                                      <Button
                                        color="primary"
                                        size="sm"
                                        children="Update"
                                        onClick={() => toggleModal(role)}
                                      />
                                    )}
                                  </td>
                                )}
                              />
                            </tr>
                          ))}

                          {rolesFetchError && (
                            <tr>
                              <td colSpan={4} className="text-center">
                                <Button color="primary" onClick={getRoles}>
                                  Reload
                                </Button>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </section>
              </Card>
            </Col>
          </Row>
        )}
        no={() => <Unauthorized />}
      />

      <Modal
        isOpen={modal}
        toggle={toggleModal}
        backdrop="static"
        returnFocusAfterClose={false}
      >
        <ModalHeader>
          Update {titleCase(removeUnderscore(selectedRole.name))}
        </ModalHeader>

        <ModalBody>
          {permissions.map((permission, index) => (
            <FormGroup check inline key={index}>
              <Label check className="mb-2">
                <Input
                  type="checkbox"
                  {...checkbox('rolePermissions', permission.name)}
                />
                Can {titleCase(removeUnderscore(permission.name))}
              </Label>
            </FormGroup>
          ))}
        </ModalBody>

        <ModalFooter>
          <Button color="danger" onClick={toggleModal}>
            Cancel
          </Button>

          <Button color="primary" onClick={modifyRole}>
            Update Role
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default Roles;
