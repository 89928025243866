import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Badge, Button, Card, Col, Container, Row, Table } from 'reactstrap';
import { Can, PageTitle } from '../../components';
import { useAuth } from '../../context/authContext';
import { filterMerchantAttribute, handleApiErrors } from '../../lib/utils';
import { fetchMerchants } from '../../services';

const Dashboard = () => {
  const { setLoading } = useAuth();
  const [merchantError, setMerchantError] = useState(false);
  const [merchants, setMerchants] = useState([]);

  const getMerchants = useCallback(async () => {
    setLoading(true);
    const response = await fetchMerchants({ limit: 5 });
    const apiErrors = handleApiErrors(response);
    setLoading(false);

    if (apiErrors) {
      toast.error(apiErrors);
      setMerchantError(apiErrors);
      return;
    }

    setMerchants(response.data.data.accounts);
  }, [setLoading]);

  useEffect(() => {
    getMerchants();
  }, [getMerchants]);

  return (
    <Fragment>
      <PageTitle title="Dashboard" />

      <Container>
        <Row>
          <Col xs={12}>
            <Card className="o-hidden border-0 shadow-lg mb-5">
              <section className="p-3 p-md-4">
                <Can
                  perform="READ_MERCHANT"
                  yes={() => (
                    <Table borderless striped responsive hover className="m-0">
                      <thead>
                        <tr className="d-flex align-items-center">
                          <th className="col-4">Name</th>
                          <th className="col-3">Client Slug</th>
                          <th className="col-2 text-center">Status</th>
                          <th className="col-3" />
                        </tr>
                      </thead>

                      <tbody className="w-100">
                        {merchants.map((merchant, index) => (
                          <tr key={index} className="d-flex align-items-center">
                            <td className="col-4">{merchant.name}</td>

                            <td className="col-3">
                              {filterMerchantAttribute(
                                merchant.accountAttribute,
                                'clientSlug',
                              )}
                            </td>

                            <td className="col-2 text-center">
                              {merchant.status === 'active' ? (
                                <Badge
                                  pill
                                  color="success"
                                  children={merchant.status}
                                />
                              ) : merchant.status === 'inactive' ? (
                                <Badge
                                  pill
                                  color="danger"
                                  children={merchant.status}
                                />
                              ) : (
                                <Badge
                                  pill
                                  color="warning"
                                  children={merchant.status}
                                />
                              )}
                            </td>

                            <td align="right" className="col-3">
                              <Link
                                className="btn btn-primary btn-sm"
                                to={`/merchants/${filterMerchantAttribute(
                                  merchant.accountAttribute,
                                  'clientSlug',
                                )}`}
                              >
                                View
                              </Link>
                            </td>
                          </tr>
                        ))}

                        {merchantError && (
                          <tr>
                            <td colSpan={3} className="text-center">
                              <Button color="primary" onClick={getMerchants}>
                                Reload
                              </Button>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  )}
                  no={() => (
                    <h3 className="text-center">
                      Welcome to Indicina Admin Portal
                    </h3>
                  )}
                />
              </section>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Dashboard;
