import React, { Fragment, useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useAuth } from '../../../context/authContext';
import { handleApiErrors } from '../../../lib/utils';
import { success } from '../../../config/constants';
import { updateBank } from '../../../services';
import { toast } from 'react-toastify';

const EnableBank = ({ selectedBank, toggle, isOpen, getBanks }) => {
  const { setLoading } = useAuth();

  const enableDecideBank = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);
    const response = await updateBank(selectedBank?.id, {
      enabled: true,
    });

    const apiErrors = handleApiErrors(response);
    setLoading(false);

    if (apiErrors) {
      toast.error(apiErrors);
      return;
    }

    toast.success(success.DECIDE_BANK_ENABLED);
    if (response?.data?.status === 'success') {
      toggle();
      getBanks();
    }
  };
  return (
    <Fragment>
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>Confirm Enable</ModalHeader>
        <ModalBody>{`Are you sure you want to disable ${selectedBank?.name}?`}</ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={toggle}>
            Cancel
          </Button>
          <Button color="primary" onClick={enableDecideBank}>
            Ok
          </Button>{' '}
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default EnableBank;
