import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Collapse,
} from 'reactstrap';
import { useAuth } from '../../../context/authContext';
import { ArrayObjectEditor } from '../../../components';
import {
  handleApiErrors,
  removeUnderscore,
  splitCamelCase,
  isValidColor,
  isValidEmail,
  isValidPhone,
  isValidUrl,
  removeCommas,
} from '../../../lib/utils';
import {
  updateMerchantAttributes,
  uploadAttributeImage,
} from '../../../services';
import {
  success,
  errors,
  booleanAttributes,
  fileAttributeNames,
  jsonArrayAttributes,
} from '../../../config/constants';
import NumberFormat from 'react-number-format';

const form = {
  contactDetails_email: 'contactDetails_email',
  contactDetails_phone: 'contactDetails_phone',
  businessName: 'businessName',
  clientSlug: 'clientSlug',
  logoUrl: 'logoUrl',
  faviconUrl: 'faviconUrl',
  termsAndConditionsUrl: 'termsAndConditionsUrl',
  privacyPolicyUrl: 'privacyPolicyUrl',
  clientAppUrl: 'clientAppUrl',
  cacNumber: 'cacNumber',
  clientAdminUrl: 'clientAdminUrl',
  clientWebhookUrl: 'clientWebhookUrl',
  twilioVerifySID: 'twilioVerifySID',
  remitaVerificationString: 'remitaVerificationString',
  approvalWorkflow: 'approvalWorkflow',
  eligibilityAmounts: 'eligibilityAmounts',
  verifyWorkEmail: 'verifyWorkEmail',
  notificationAddress_MERCHANT_APPLICATION_APPROVED:
    'notificationAddress_MERCHANT_APPLICATION_APPROVED',
  notificationAddress_MERCHANT_APPLICATION_DENIED:
    'notificationAddress_MERCHANT_APPLICATION_DENIED',
  notificationAddress_MERCHANT_APPLICATION_REVIEW:
    'notificationAddress_MERCHANT_APPLICATION_REVIEW',
  notificationAddress_MERCHANT_LOAN_DISBURSED:
    'notificationAddress_MERCHANT_LOAN_DISBURSED',
  notificationAddress_MERCHANT_LOAN_REPAYMENT:
    'notificationAddress_MERCHANT_LOAN_REPAYMENT',
  notificationAddress_WALLET_THRESHOLD: 'notificationAddress_WALLET_THRESHOLD',
  clientTheme_primaryColor: 'clientTheme_primaryColor',
  clientTheme_secondaryColor: 'clientTheme_secondaryColor',
  facebookAppKeys_appId: 'facebookAppKeys_appId',
  facebookAppKeys_appSecret: 'facebookAppKeys_appSecret',
  mbsApiKeys_clientId: 'mbsApiKeys_clientId',
  mbsApiKeys_clientSecret: 'mbsApiKeys_clientSecret',
  creditBureauApiCreds_username: 'creditBureauApiCreds_username',
  creditBureauApiCreds_password: 'creditBureauApiCreds_password',
  remitaApiCreds_merchantId: 'remitaApiCreds_merchantId',
  remitaApiCreds_merchantApiKey: 'remitaApiCreds_merchantApiKey',
  remitaApiCreds_merchantToken: 'remitaApiCreds_merchantToken',
  remitaServiceTypeId: 'remitaServiceTypeId',
  modelManagerCreds_modelName: 'modelManagerCreds_modelName',
  paystackApiCreds_publicKey: 'paystackApiCreds_publicKey',
  paystackApiCreds_secretKey: 'paystackApiCreds_secretKey',
  addCardCharge: 'addCardCharge',
  rollbackEligibility: 'rollbackEligibility',
  requiresBankStatement: 'requiresBankStatement',
  requiresCreditScore: 'requiresCreditScore',
  requiresCreditBureauReport: 'requiresCreditBureauReport',
  requiresMaxApprovedLoanAmount: 'requiresMaxApprovedLoanAmount',
  requiresBankStatementUpload: 'requiresBankStatementUpload',
  useRemita: 'useRemita',
  useRemitaWithTenor: 'useRemitaWithTenor',
  requireWorkEmail: 'requireWorkEmail',
  requiresSelfieUpload: 'requiresSelfieUpload',
  requiresWorkIDUpload: 'requiresWorkIDUpload',
  requiresExternalDisbursement: 'requiresExternalDisbursement',
  requiresCreditRegistryReport: 'requiresCreditRegistryReport',
  creditRegistryApiCreds_subscriberId: 'creditRegistryApiCreds_subscriberId',
  creditRegistryApiCreds_email: 'creditRegistryApiCreds_email',
  creditRegistryApiCreds_password: 'creditRegistryApiCreds_password',
  requiresReferee: 'requiresReferee',
  requiredSupportingDocuments: 'requiredSupportingDocuments',
  requiredSocialMediaAccounts: 'requiredSocialMediaAccounts',
  allowedGeoLocationBoundaries: 'allowedGeoLocationBoundaries',
  loanMonthlyDurations: 'loanMonthlyDurations',
  requiresGovernmentId: 'requiresGovernmentId',
  mbsCbEarlyRepaymentGrace: 'mbsCbEarlyRepaymentGrace',
  mbsCbChargeAmount: 'mbsCbChargeAmount',
  noCardImplementation: 'noCardImplementation',
  deactivateMessageService: 'deactivateMessageService',
  requiresEventTrigger: 'requiresEventTrigger',
  requiresOnePipeLookUp: 'requiresOnePipeLookUp',
  addCardCharges: 'addCardCharges',
  enablePartialDebit: 'enablePartialDebit',
  requiresCrcReport: 'requiresCrcReport',
  requiresCrcMicroReport: 'requiresCrcMicroReport',
  crcApiCreds_strUserID: 'crcApiCreds_strUserID',
  crcApiCreds_strPassword: 'crcApiCreds_strPassword',
  requiresOkraTransactions: 'requiresOkraTransactions',
  okraApiCreds_publicKey: 'okraApiCreds_publicKey',
  okraApiCreds_privateKey: 'okraApiCreds_privateKey',
  okraApiCreds_clientToken: 'okraApiCreds_clientToken',
  okraApiCreds_widgetUrl: 'okraApiCreds_widgetUrl',
  okraApiCreds_requiresEnhancedBalance: 'okraApiCreds_requiresEnhancedBalance',
  mbsCbValidityPeriod: 'mbsCbValidityPeriod',
  enableEmailVerificationCode: 'enableEmailVerificationCode',
  externalBankStatementTenor: 'externalBankStatementTenor',
  okraValidityPeriod: 'okraValidityPeriod',
  requiresFacebookUrl: 'requiresFacebookUrl',
  enableWebhooksConfig: 'enableWebhooksConfig',
  requiresRemitaMandate: 'requiresRemitaMandate',
  loanOfferLetterFeatureIsEnabled: 'loanOfferLetterFeatureIsEnabled',
  loanAndApplicationServiceEnabled: 'loanAndApplicationServiceEnabled',
  okraDirectDebitIsEnabled: 'okraDirectDebitIsEnabled',
  manualOfferLetter: 'manualOfferLetter',
  requiresDecide: 'requiresDecide',
  decideCreds_clientKey: 'decideCreds_clientKey',
  decideCreds_clientSecret: 'decideCreds_clientSecret',
  monoTransactionsIsEnabled: 'monoTransactionsIsEnabled',
  monoApiCreds_publicKey: 'monoApiCreds_publicKey',
  monoApiCreds_secretKey: 'monoApiCreds_secretKey',
  useLoanGroups: 'useLoanGroups',
  recovaApiCreds_token: 'recovaApiCreds_token',
  requiresRecova: 'requiresRecova',
  requiresDecidePdfAnalysis: 'requiresDecidePdfAnalysis',
};

const SingleMerchantAttributes = ({
  merchantId,
  merchantAttributes,
  merchantFetchError,
  getMerchant,
}) => {
  const { setLoading } = useAuth();
  const [logoFile, setLogoFile] = useState();
  const [faviconFile, setFaviconFile] = useState();
  const [termsAndConditionsFile, setTermsAndConditionsFile] = useState();
  const [toggleTermsAndConditions, setToggleTermsAndConditions] = useState(
    false,
  );
  const [collapseState, setCollapseState] = useState({
    merchantDetails: false,
    uploads: false,
    notificationAddresses: false,
    integrations: false,
  });
  const [formState, setFormState] = useState(() => {
    const initialState = {};

    Object.keys(form).forEach(key => {
      if (jsonArrayAttributes.has(key)) initialState[key] = [];
      else initialState[key] = '';
    });

    return initialState;
  });
  const [privacyPolicyFile, setPrivacyPolicyFile] = useState();
  const [togglePrivacyPolicy, setTogglePrivacyPolicy] = useState(
    false,
  );

  const extractIndividualAttribute = useCallback(() => {
    setLoading(true);
    const extractedAttributes = Object.assign({}, formState);

    if (merchantAttributes) {
      for (let i = 0; i < merchantAttributes.length; i += 1) {
        const attributeName = merchantAttributes[i].attribute.name;
        const attributeValue = merchantAttributes[i].value;
        const attributeData = merchantAttributes[i].data;

        if (attributeValue) {
          if (booleanAttributes.has(attributeName)) {
            extractedAttributes[attributeName] = attributeValue === 'true';
          } else {
            extractedAttributes[attributeName] = attributeValue;
          }
        } else if (attributeData) {
          if (jsonArrayAttributes.has(attributeName)) {
            extractedAttributes[attributeName] = attributeData;
          } else {
            for (let key in attributeData) {
              extractedAttributes[`${attributeName}_${key}`] =
                attributeData[key];
            }
          }
        }
      }
    }

    setFormState(extractedAttributes);
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantAttributes, setLoading]);

  useEffect(() => {
    if (merchantAttributes.length > 0) {
      extractIndividualAttribute();
    }
  }, [extractIndividualAttribute, merchantAttributes]);

  const createAttribute = async e => {
    e.preventDefault();
    e.stopPropagation();

    const {
      contactDetails_email,
      contactDetails_phone,
      businessName,
      clientSlug,
      clientAppUrl,
      cacNumber,
      clientAdminUrl,
      clientWebhookUrl,
      twilioVerifySID,
      // remitaVerificationString,
      approvalWorkflow,
      // eligibilityAmounts,
      verifyWorkEmail,
      notificationAddress_MERCHANT_APPLICATION_APPROVED,
      notificationAddress_MERCHANT_APPLICATION_DENIED,
      notificationAddress_MERCHANT_APPLICATION_REVIEW,
      notificationAddress_MERCHANT_LOAN_DISBURSED,
      notificationAddress_MERCHANT_LOAN_REPAYMENT,
      notificationAddress_WALLET_THRESHOLD,
      clientTheme_primaryColor,
      clientTheme_secondaryColor,
      facebookAppKeys_appId,
      facebookAppKeys_appSecret,
      mbsApiKeys_clientId,
      mbsApiKeys_clientSecret,
      creditBureauApiCreds_username,
      creditBureauApiCreds_password,
      remitaApiCreds_merchantId,
      remitaApiCreds_merchantApiKey,
      remitaApiCreds_merchantToken,
      remitaServiceTypeId,
      modelManagerCreds_modelName,
      paystackApiCreds_publicKey,
      paystackApiCreds_secretKey,
      addCardCharge,
      rollbackEligibility,
      requiresBankStatement,
      requiresCreditScore,
      requiresCreditBureauReport,
      requiresMaxApprovedLoanAmount,
      requiresBankStatementUpload,
      useRemita,
      useRemitaWithTenor,
      requireWorkEmail,
      requiresSelfieUpload,
      requiresWorkIDUpload,
      requiresExternalDisbursement,
      requiresCreditRegistryReport,
      creditRegistryApiCreds_subscriberId,
      creditRegistryApiCreds_email,
      creditRegistryApiCreds_password,
      requiresReferee,
      requiredSupportingDocuments,
      allowedGeoLocationBoundaries,
      loanMonthlyDurations,
      termsAndConditionsUrl,
      privacyPolicyUrl,
      requiresGovernmentId,
      mbsCbEarlyRepaymentGrace,
      mbsCbChargeAmount,
      noCardImplementation,
      deactivateMessageService,
      requiresEventTrigger,
      requiresOnePipeLookUp,
      addCardCharges,
      enablePartialDebit,
      requiresCrcReport,
      requiresCrcMicroReport,
      crcApiCreds_strUserID,
      crcApiCreds_strPassword,
      requiresOkraTransactions,
      okraApiCreds_publicKey,
      okraApiCreds_privateKey,
      okraApiCreds_clientToken,
      okraApiCreds_widgetUrl,
      okraApiCreds_requiresEnhancedBalance,
      requiresDecide,
      decideCreds_clientKey,
      decideCreds_clientSecret,
      mbsCbValidityPeriod,
      enableEmailVerificationCode,
      externalBankStatementTenor,
      okraValidityPeriod,
      requiresFacebookUrl,
      enableWebhooksConfig,
      requiresRemitaMandate,
      loanOfferLetterFeatureIsEnabled,
      loanAndApplicationServiceEnabled,
      okraDirectDebitIsEnabled,
      requiredSocialMediaAccounts,
      manualOfferLetter,
      monoTransactionsIsEnabled,
      monoApiCreds_publicKey,
      monoApiCreds_secretKey,
      useLoanGroups,
      recovaApiCreds_token,
      requiresRecova,
      requiresDecidePdfAnalysis,
    } = formState;

    let allInputValid = true;

    if (contactDetails_phone || contactDetails_email) {
      if (!isValidPhone(contactDetails_phone)) {
        toast.error(errors.PHONE_NOT_VALID);
        allInputValid = false;
      }

      if (!isValidEmail(contactDetails_email.trim())) {
        toast.error(errors.EMAIL_NOT_VALID);
        allInputValid = false;
      }
    }

    if (clientTheme_primaryColor || clientTheme_secondaryColor) {
      if (
        !isValidColor(clientTheme_primaryColor) ||
        !isValidColor(clientTheme_secondaryColor)
      ) {
        toast.error(errors.COLOR_NOT_VALID);
        allInputValid = false;
      }
    }

    if (clientAppUrl && !isValidUrl(clientAppUrl)) {
      toast.error(errors.URL_NOT_VALID);
      allInputValid = false;
    }

    if (clientAdminUrl && !isValidUrl(clientAdminUrl)) {
      toast.error(errors.URL_NOT_VALID);
      allInputValid = false;
    }

    if (clientWebhookUrl && !isValidUrl(clientWebhookUrl)) {
      toast.error(errors.URL_NOT_VALID);
      allInputValid = false;
    }

    if (termsAndConditionsUrl && !isValidUrl(termsAndConditionsUrl)) {
      toast.error(errors.URL_NOT_VALID);
      allInputValid = false;
    }

    if (privacyPolicyUrl && !isValidUrl(privacyPolicyUrl)) {
      toast.error(errors.URL_NOT_VALID);
      allInputValid = false;
    }

    if (
      requiresCreditBureauReport &&
      (creditBureauApiCreds_username.trim().length === 0 ||
        creditBureauApiCreds_password.trim().length === 0)
    ) {
      toast.error(errors.CRED_BUREAU_EMPTY);
      allInputValid = false;
    }

    if (
      useRemita &&
      (remitaApiCreds_merchantToken.trim().length === 0 ||
        remitaApiCreds_merchantApiKey.trim().length === 0 ||
        remitaApiCreds_merchantId.trim().length === 0 ||
        remitaServiceTypeId.trim().length === 0)
    ) {
      toast.error(errors.REMITA_EMPTY);
      allInputValid = false;
    }

    if (
      requiresBankStatement &&
      (mbsApiKeys_clientId.trim().length === 0 ||
        mbsApiKeys_clientSecret.trim().length === 0)
    ) {
      toast.error(errors.MBS_EMPTY);
      allInputValid = false;
    }

    if (
      requiresCreditRegistryReport &&
      (!isValidEmail(creditRegistryApiCreds_email) ||
        creditRegistryApiCreds_password.trim().length === 0 ||
        creditRegistryApiCreds_subscriberId.trim().length === 0)
    ) {
      toast.error(errors.CRED_REGISTRY_EMPTY);
      allInputValid = false;
    }

    if (
      requiresCrcReport &&
      (crcApiCreds_strUserID.trim().length === 0 ||
        crcApiCreds_strPassword.trim().length === 0)
    ) {
      toast.error(errors.CRC_CREDIT_BUREAU_EMPTY);
      allInputValid = false;
    }

    if (
      requiresCrcMicroReport &&
      (crcApiCreds_strUserID.trim().length === 0 ||
        crcApiCreds_strPassword.trim().length === 0)
    ) {
      toast.error(errors.CRC_CREDIT_BUREAU_EMPTY);
      allInputValid = false;
    }

    if (
      requiresOkraTransactions &&
      okraApiCreds_widgetUrl.trim().length === 0
    ) {
      toast.error(errors.OKRA_CREDS_EMPTY);
      allInputValid = false;
    }

    if (
      requiresOnePipeLookUp &&
      !(Array.isArray(addCardCharges) && addCardCharges.length > 0)
    ) {
      toast.error(errors.ONEPIPE_CARD_CHARGE_EMPTY);
      allInputValid = false;
    }

    if (
      monoTransactionsIsEnabled &&
      (monoApiCreds_publicKey.trim().length === 0 ||
        monoApiCreds_secretKey.trim().length === 0)
    ) {
      toast.error(errors.MONO_CREDS_EMPTY);
      allInputValid = false;
    }

    if (
      requiresDecide &&
      (decideCreds_clientKey.trim().length === 0 ||
      decideCreds_clientSecret.trim().length === 0)
    ) {
      toast.error(errors.DECIDE_CREDS_EMPTY);
      allInputValid = false;
    }

    if (
      requiresRecova &&
      (recovaApiCreds_token.trim().length === 0)
    ) {
      toast.error(errors.RECOVA_CREDS_EMPTY);
      allInputValid = false;
    }

    if (!allInputValid) return;

    let logoUrl;
    let faviconUrl;
    let resolvedTermsAndConditionsUrl;
    let resolvedPrivacyPolicyUrl;

    setLoading(true);

    if (logoFile) {
      const formData = createFormData(
        clientSlug,
        fileAttributeNames.LOGO,
        logoFile,
      );
      const response = await uploadAttributeImage(formData);
      const apiErrors = handleApiErrors(response);

      if (apiErrors) {
        setLoading(false);
        toast.error(apiErrors);
        return;
      }

      logoUrl = response.data.data.url;
    }

    if (faviconFile) {
      const formData = createFormData(
        clientSlug,
        fileAttributeNames.FAVICON,
        faviconFile,
      );
      const response = await uploadAttributeImage(formData);
      const apiErrors = handleApiErrors(response);

      if (apiErrors) {
        setLoading(false);
        toast.error(apiErrors);
        return;
      }

      faviconUrl = response.data.data.url;
    }

    if (termsAndConditionsFile) {
      const formData = createFormData(
        clientSlug,
        fileAttributeNames.TERMS_AND_CONDITION,
        termsAndConditionsFile,
      );

      const response = await uploadAttributeImage(formData);
      const apiErrors = handleApiErrors(response);

      if (apiErrors) {
        setLoading(false);
        toast.error(apiErrors);
        return;
      }

      resolvedTermsAndConditionsUrl = response.data.data.url;
    } else {
      resolvedTermsAndConditionsUrl = termsAndConditionsUrl;
    }

    if (privacyPolicyFile) {
      const formData = createFormData(
        clientSlug,
        fileAttributeNames.PRIVACY_POLICY,
        privacyPolicyFile,
      );

      const response = await uploadAttributeImage(formData);
      const apiErrors = handleApiErrors(response);

      if (apiErrors) {
        setLoading(false);
        toast.error(apiErrors);
        return;
      }

      resolvedPrivacyPolicyUrl = response.data.data.url;
    } else {
      resolvedPrivacyPolicyUrl = privacyPolicyUrl;
    }

    setFormState({
      ...formState,
      ...(logoUrl && { logoUrl }),
      ...(faviconUrl && { faviconUrl }),
      ...(resolvedTermsAndConditionsUrl && {
        termsAndConditionsUrl: resolvedTermsAndConditionsUrl,
      }),
      ...(resolvedPrivacyPolicyUrl && {
        privacyPolicyUrl: resolvedPrivacyPolicyUrl,
      }),
    });

    const payload = {
      merchantId: { accountId: merchantId },
      attributes: {
        clientSlug,
        verifyWorkEmail: verifyWorkEmail ? 'true' : 'false',
        rollbackEligibility: rollbackEligibility ? 'true' : 'false',
        requiresCreditRegistryReport: requiresCreditRegistryReport
          ? 'true'
          : 'false',
        requiresBankStatement: requiresBankStatement ? 'true' : 'false',
        requiresCreditScore: requiresCreditScore ? 'true' : 'false',
        requiresCreditBureauReport: requiresCreditBureauReport
          ? 'true'
          : 'false',
        requiresMaxApprovedLoanAmount: requiresMaxApprovedLoanAmount
          ? 'true'
          : 'false',
        requiresBankStatementUpload: requiresBankStatementUpload
          ? 'true'
          : 'false',
        useRemita: useRemita ? 'true' : 'false',
        useRemitaWithTenor: useRemitaWithTenor ? 'true' : 'false',
        requiresReferee: requiresReferee ? 'true' : 'false',
        requiresGovernmentId: requiresGovernmentId ? 'true' : 'false',
        requireWorkEmail: requireWorkEmail ? 'true' : 'false',
        requiresSelfieUpload: requiresSelfieUpload ? 'true' : 'false',
        requiresWorkIDUpload: requiresWorkIDUpload ? 'true' : 'false',
        requiresExternalDisbursement: requiresExternalDisbursement
          ? 'true'
          : 'false',
        noCardImplementation: noCardImplementation ? 'true' : 'false',
        deactivateMessageService: deactivateMessageService ? 'true' : 'false',
        requiresEventTrigger: requiresEventTrigger ? 'true' : 'false',
        requiresOnePipeLookUp: requiresOnePipeLookUp ? 'true' : 'false',
        enablePartialDebit: enablePartialDebit ? 'true' : 'false',
        requiresCrcReport: requiresCrcReport ? 'true' : 'false',
        requiresCrcMicroReport: requiresCrcMicroReport ? 'true' : 'false',
        requiresOkraTransactions: requiresOkraTransactions ? 'true' : 'false',
        enableEmailVerificationCode: enableEmailVerificationCode
          ? 'true'
          : 'false',
        requiresFacebookUrl: requiresFacebookUrl ? 'true' : 'false',
        enableWebhooksConfig: enableWebhooksConfig ? 'true' : 'false',
        requiresRemitaMandate: requiresRemitaMandate ? 'true' : 'false',
        loanOfferLetterFeatureIsEnabled: loanOfferLetterFeatureIsEnabled
          ? 'true'
          : 'false',
        loanAndApplicationServiceEnabled: loanAndApplicationServiceEnabled
          ? 'true'
          : 'false',
        okraDirectDebitIsEnabled: okraDirectDebitIsEnabled ? 'true' : 'false',
        manualOfferLetter: manualOfferLetter ? 'true' : 'false',
        monoTransactionsIsEnabled: monoTransactionsIsEnabled ? 'true' : 'false',
        requiresDecide: requiresDecide ? 'true' : 'false',
        useLoanGroups: useLoanGroups ? 'true' : 'false',
        requiresRecova: requiresRecova ? 'true' : 'false',
        requiresDecidePdfAnalysis: requiresDecidePdfAnalysis ? 'true' : 'false',
        requiredSupportingDocuments,
        requiredSocialMediaAccounts,
        allowedGeoLocationBoundaries,
        loanMonthlyDurations,
        addCardCharges,
        ...(mbsCbEarlyRepaymentGrace && { mbsCbEarlyRepaymentGrace }),
        ...(mbsCbChargeAmount && { mbsCbChargeAmount }),
        ...(addCardCharge && { addCardCharge }),
        ...(businessName && { businessName }),
        ...(logoUrl && { logoUrl }),
        ...(faviconUrl && { faviconUrl }),
        ...(resolvedTermsAndConditionsUrl && {
          termsAndConditionsUrl: resolvedTermsAndConditionsUrl,
        }),
        ...(resolvedPrivacyPolicyUrl && {
          privacyPolicyUrl: resolvedPrivacyPolicyUrl,
        }),
        ...(clientAppUrl && { clientAppUrl }),
        ...(cacNumber && { cacNumber }),
        ...(clientAdminUrl && { clientAdminUrl }),
        ...(twilioVerifySID && { twilioVerifySID }),
        // ...(remitaVerificationString && { remitaVerificationString }),
        ...(approvalWorkflow && { approvalWorkflow }),
        // ...(eligibilityAmounts && { eligibilityAmounts }),
        ...(clientTheme_primaryColor &&
          clientTheme_secondaryColor && {
            clientTheme: {
              primaryColor: clientTheme_primaryColor,
              secondaryColor: clientTheme_secondaryColor,
            },
          }),
        ...(contactDetails_phone &&
          contactDetails_email && {
            contactDetails: {
              phone: contactDetails_phone,
              email: contactDetails_email.trim(),
            },
          }),
        ...(facebookAppKeys_appId && {
          facebookAppKeys: {
            appId: facebookAppKeys_appId,
            appSecret: facebookAppKeys_appSecret,
          },
        }),
        ...{
          mbsApiKeys: {
            clientId: mbsApiKeys_clientId,
            clientSecret: mbsApiKeys_clientSecret,
          },
        },
        ...{
          creditBureauApiCreds: {
            username: creditBureauApiCreds_username,
            password: creditBureauApiCreds_password,
          },
        },
        ...{
          remitaApiCreds: {
            merchantId: remitaApiCreds_merchantId,
            merchantApiKey: remitaApiCreds_merchantApiKey,
            merchantToken: remitaApiCreds_merchantToken,
          },
        },
        remitaServiceTypeId: remitaServiceTypeId,
        ...{
          decideCreds: {
            clientKey: decideCreds_clientKey,
            clientSecret: decideCreds_clientSecret,
          },
        },
        ...{
          crcApiCreds: {
            strUserID: crcApiCreds_strUserID,
            strPassword: crcApiCreds_strPassword,
          },
        },
        ...{
          modelManagerCreds: {
            modelName: modelManagerCreds_modelName,
          },
        },
        ...(paystackApiCreds_publicKey && {
          paystackApiCreds: {
            publicKey: paystackApiCreds_publicKey,
            secretKey: paystackApiCreds_secretKey,
          },
        }),
        ...((notificationAddress_MERCHANT_APPLICATION_APPROVED ||
          notificationAddress_MERCHANT_APPLICATION_DENIED ||
          notificationAddress_MERCHANT_APPLICATION_REVIEW ||
          notificationAddress_MERCHANT_LOAN_DISBURSED ||
          notificationAddress_MERCHANT_LOAN_REPAYMENT ||
          notificationAddress_WALLET_THRESHOLD) && {
          notificationAddress: {
            MERCHANT_APPLICATION_APPROVED: notificationAddress_MERCHANT_APPLICATION_APPROVED,
            MERCHANT_APPLICATION_DENIED: notificationAddress_MERCHANT_APPLICATION_DENIED,
            MERCHANT_APPLICATION_REVIEW: notificationAddress_MERCHANT_APPLICATION_REVIEW,
            MERCHANT_LOAN_DISBURSED: notificationAddress_MERCHANT_LOAN_DISBURSED,
            MERCHANT_LOAN_REPAYMENT: notificationAddress_MERCHANT_LOAN_REPAYMENT,
            WALLET_THRESHOLD: notificationAddress_WALLET_THRESHOLD,
          },
        }),
        ...{
          creditRegistryApiCreds: {
            subscriberId: creditRegistryApiCreds_subscriberId,
            email: creditRegistryApiCreds_email,
            password: creditRegistryApiCreds_password,
          },
        },
        ...(okraApiCreds_widgetUrl && {
          okraApiCreds: {
            publicKey: okraApiCreds_publicKey,
            privateKey: okraApiCreds_privateKey,
            clientToken: okraApiCreds_clientToken,
            widgetUrl: okraApiCreds_widgetUrl,
            requiresEnhancedBalance: okraApiCreds_requiresEnhancedBalance
              ? true
              : false,
          },
        }),
        ...{
          monoApiCreds: {
            publicKey: monoApiCreds_publicKey,
            secretKey: monoApiCreds_secretKey,
          },
        },
        ...{
          recovaApiCreds: {
            token: recovaApiCreds_token,
          },
        },
        ...(mbsCbValidityPeriod && { mbsCbValidityPeriod }),
        ...(externalBankStatementTenor && { externalBankStatementTenor }),
        ...(okraValidityPeriod && { okraValidityPeriod }),
      },
    };

    const response = await updateMerchantAttributes(payload);
    let apiErrors;

    if (response.data?.errors) {
      // Expected errors if unique attributes are not edited
      const clientSlugNotUniqueError = {
        attribute: 'clientSlug',
        message: 'clientSlug name unavailable',
      };
      const logoUrlNotUniqueError = {
        attribute: 'logoUrl',
        message: 'logoUrl name unavailable',
      };
      const clientAppUrlNotUniqueError = {
        attribute: 'clientAppUrl',
        message: 'clientAppUrl name unavailable',
      };

      response.data.errors = response.data.errors.filter(
        error =>
          JSON.stringify(error) !== JSON.stringify(clientSlugNotUniqueError) &&
          JSON.stringify(error) !== JSON.stringify(logoUrlNotUniqueError) &&
          JSON.stringify(error) !== JSON.stringify(clientAppUrlNotUniqueError),
      );
    }

    apiErrors = handleApiErrors(response);
    setLoading(false);

    if (apiErrors) {
      toast.error(apiErrors);
      return;
    }

    toast.success(success.MERCHANT_ATTRIBUTE_UPDATED);
  };

  const onFormChange = e => {
    const { id, value } = e.target;
    delete formState[id];
    setFormState({
      ...formState,
      [id]: value
        ? id === 'addCardCharge' || id === 'mbsCbChargeAmount'
          ? removeCommas(value)
          : value
        : ' ',
    });
  };

  const createFormData = (slug, attributeName, image) => {
    const formData = new FormData();
    formData.append('slug', slug);
    formData.append('attributeName', attributeName);
    formData.append('image', image);

    return formData;
  };

  const handleFileSelection = e => {
    const { name, files } = e.target;
    const file = files[0];

    if (name === form.logoUrl) {
      setLogoFile(file);
    } else if (name === form.faviconUrl) {
      setFaviconFile(file);
    } else if (name === form.termsAndConditionsUrl) {
      setTermsAndConditionsFile(file);
    } else if (name === form.privacyPolicyUrl) {
      setPrivacyPolicyFile(file);
    }
  };

  const handleCheckboxChange = e => {
    const { id, checked } = e.target;

    setFormState({
      ...formState,
      [id]: checked,
    });
  };

  const toggleCollapse = propertyName => {
    setCollapseState({
      ...collapseState,
      [propertyName]: !collapseState[propertyName],
    });
  };

  const handleToggleTermsAndConditions = () =>
    setToggleTermsAndConditions(!toggleTermsAndConditions);

  const handleTogglePivacyPolicy = () => setTogglePrivacyPolicy(!togglePrivacyPolicy);

  return (
    <Fragment>
      <h4 className="mb-3">Attributes</h4>

      <Card className="o-hidden border-0 p-4 shadow-lg mb-5">
        <Form className="p-4 p-md-5" onSubmit={createAttribute}>
          <FormGroup tag="fieldset">
            <legend className="mb-0">Merchant Details</legend>
            <hr className="mb-4" />

            <Col
              className="text-right"
              style={{
                cursor: 'pointer',
                color: collapseState.merchantDetails ? 'red' : 'green',
              }}
              onClick={() => toggleCollapse('merchantDetails')}
            >
              <h6>
                {collapseState.merchantDetails ? '[-] Collapse' : '[+] Expand'}
              </h6>
            </Col>

            <Collapse isOpen={collapseState.merchantDetails}>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label
                      htmlFor={form.clientSlug}
                      className="text-capitalize"
                    >
                      {splitCamelCase(removeUnderscore(form.clientSlug))}
                    </Label>
                    <Input
                      type="text"
                      required
                      id={form.clientSlug}
                      value={formState.clientSlug}
                      onChange={onFormChange}
                      disabled
                    />
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup>
                    <Label
                      htmlFor={form.businessName}
                      className="text-capitalize"
                    >
                      {splitCamelCase(removeUnderscore(form.businessName))}
                    </Label>
                    <Input
                      type="text"
                      id={form.businessName}
                      value={formState.businessName}
                      onChange={onFormChange}
                    />
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup>
                    <Label
                      htmlFor={form.clientTheme_primaryColor}
                      className="text-capitalize"
                    >
                      Primary Color
                    </Label>
                    <Input
                      type="text"
                      id={form.clientTheme_primaryColor}
                      value={formState.clientTheme_primaryColor}
                      onChange={onFormChange}
                    />
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup>
                    <Label
                      htmlFor={form.clientTheme_secondaryColor}
                      className="text-capitalize"
                    >
                      Secondary Color
                    </Label>
                    <Input
                      type="text"
                      id={form.clientTheme_secondaryColor}
                      value={formState.clientTheme_secondaryColor}
                      onChange={onFormChange}
                    />
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup>
                    <Label
                      htmlFor={form.twilioVerifySID}
                      className="text-capitalize"
                    >
                      {splitCamelCase(removeUnderscore(form.twilioVerifySID))}
                    </Label>
                    <Input
                      type="text"
                      id={form.twilioVerifySID}
                      value={formState.twilioVerifySID}
                      onChange={onFormChange}
                    />
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup>
                    <Label
                      htmlFor={form.approvalWorkflow}
                      className="text-capitalize"
                    >
                      {splitCamelCase(removeUnderscore(form.approvalWorkflow))}
                    </Label>
                    <Input
                      type="text"
                      id={form.approvalWorkflow}
                      value={formState.approvalWorkflow}
                      onChange={onFormChange}
                    />
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup>
                    <Label
                      htmlFor={form.contactDetails_phone}
                      className="text-capitalize"
                    >
                      Support Phone
                    </Label>
                    <Input
                      type="tel"
                      id={form.contactDetails_phone}
                      value={formState.contactDetails_phone}
                      onChange={onFormChange}
                    />
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup>
                    <Label
                      htmlFor={form.contactDetails_email}
                      className="text-capitalize"
                    >
                      Support Email
                    </Label>
                    <Input
                      type="email"
                      id={form.contactDetails_email}
                      value={formState.contactDetails_email}
                      onChange={onFormChange}
                    />
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup>
                    <Label htmlFor={form.cacNumber} className="text-capitalize">
                      {splitCamelCase(removeUnderscore(form.cacNumber))}
                    </Label>
                    <Input
                      type="text"
                      id={form.cacNumber}
                      value={formState.cacNumber}
                      onChange={onFormChange}
                    />
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup>
                    <Label
                      htmlFor={form.clientAppUrl}
                      className="text-capitalize"
                    >
                      {splitCamelCase(removeUnderscore(form.clientAppUrl))}
                    </Label>
                    <Input
                      type="text"
                      id={form.clientAppUrl}
                      value={formState.clientAppUrl}
                      onChange={onFormChange}
                    />
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup>
                    <Label
                      htmlFor={form.clientAdminUrl}
                      className="text-capitalize"
                    >
                      {splitCamelCase(removeUnderscore(form.clientAdminUrl))}
                    </Label>
                    <Input
                      type="text"
                      id={form.clientAdminUrl}
                      value={formState.clientAdminUrl}
                      onChange={onFormChange}
                    />
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup>
                    <Label
                      htmlFor={form.clientWebhookUrl}
                      className="text-capitalize"
                    >
                      {splitCamelCase(removeUnderscore(form.clientWebhookUrl))}
                    </Label>
                    <Input
                      type="text"
                      id={form.clientWebhookUrl}
                      value={formState.clientWebhookUrl}
                      onChange={onFormChange}
                      disabled
                    />
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup>
                    <Label
                      htmlFor={form.modelManagerCreds_modelName}
                      className="text-capitalize"
                    >
                      Model Name (Seperate By Comma For Multiple Models)
                    </Label>
                    <Input
                      type="text"
                      id={form.modelManagerCreds_modelName}
                      value={formState.modelManagerCreds_modelName}
                      onChange={onFormChange}
                    />
                  </FormGroup>
                </Col>

                {/* <Col md={6}>
                <FormGroup>
                  <Label
                    htmlFor={form.eligibilityAmounts}
                    className="text-capitalize"
                  >
                    {splitCamelCase(removeUnderscore(form.eligibilityAmounts))}
                  </Label>
                  <Input
                    type="text"
                    id={form.eligibilityAmounts}
                    value={formState.eligibilityAmounts}
                    onChange={onFormChange}
                  />
                </FormGroup>
              </Col> */}

                <Col md={6}>
                  <FormGroup>
                    <Label
                      htmlFor={form.addCardCharge}
                      className="text-capitalize"
                    >
                      {splitCamelCase(removeUnderscore(form.addCardCharge))}
                    </Label>
                    <NumberFormat
                      type="text"
                      id={form.addCardCharge}
                      value={formState.addCardCharge}
                      customInput={Input}
                      thousandSeparator={true}
                      onChange={onFormChange}
                    />
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup>
                    <Label
                      htmlFor={form.mbsCbEarlyRepaymentGrace}
                      className="text-capitalize"
                    >
                      {splitCamelCase(
                        removeUnderscore(form.mbsCbEarlyRepaymentGrace),
                      )}
                    </Label>
                    <Input
                      type="text"
                      id={form.mbsCbEarlyRepaymentGrace}
                      value={formState.mbsCbEarlyRepaymentGrace}
                      onChange={onFormChange}
                      placeholder="In days"
                    />
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup>
                    <Label
                      htmlFor={form.mbsCbChargeAmount}
                      className="text-capitalize"
                    >
                      {splitCamelCase(removeUnderscore(form.mbsCbChargeAmount))}
                    </Label>
                    <NumberFormat
                      type="text"
                      id={form.mbsCbChargeAmount}
                      value={formState.mbsCbChargeAmount}
                      customInput={Input}
                      thousandSeparator={true}
                      onChange={onFormChange}
                    />
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup>
                    <Label
                      htmlFor={form.mbsCbValidityPeriod}
                      className="text-capitalize"
                    >
                      {splitCamelCase(
                        removeUnderscore(form.mbsCbValidityPeriod),
                      )}
                    </Label>
                    <Input
                      type="text"
                      id={form.mbsCbValidityPeriod}
                      value={formState.mbsCbValidityPeriod}
                      onChange={onFormChange}
                    />
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup>
                    <Label
                      htmlFor={form.externalBankStatementTenor}
                      className="text-capitalize"
                    >
                      {splitCamelCase(
                        removeUnderscore(form.externalBankStatementTenor),
                      )}
                    </Label>
                    <Input
                      type="number"
                      id={form.externalBankStatementTenor}
                      value={formState.externalBankStatementTenor}
                      onChange={onFormChange}
                      placeholder="In months, Eg 5"
                    />
                  </FormGroup>
                </Col>

                <Col xs={12}>
                  <FormGroup>
                    <Label
                      check
                      htmlFor={form.requiresReferee}
                      className="text-capitalize mr-3"
                    >
                      {splitCamelCase(removeUnderscore(form.requiresReferee))}
                      <Input
                        type="checkbox"
                        className="ml-2"
                        id={form.requiresReferee}
                        checked={formState.requiresReferee}
                        onChange={handleCheckboxChange}
                      />
                    </Label>
                  </FormGroup>
                </Col>

                <Col xs={12}>
                  <FormGroup>
                    <Label
                      check
                      htmlFor={form.requiresGovernmentId}
                      className="text-capitalize mr-3"
                    >
                      {splitCamelCase(
                        removeUnderscore(form.requiresGovernmentId),
                      )}
                      <Input
                        type="checkbox"
                        className="ml-2"
                        id={form.requiresGovernmentId}
                        checked={formState.requiresGovernmentId}
                        onChange={handleCheckboxChange}
                      />
                    </Label>
                  </FormGroup>
                </Col>

                <Col xs={12}>
                  <ArrayObjectEditor
                    attributeName={form.requiredSupportingDocuments}
                    state={formState}
                    updateState={setFormState}
                    schema={[
                      {
                        propertyName: 'documentName',
                        displayName: 'Document Name',
                        type: 'text',
                      },
                      {
                        propertyName: 'description',
                        displayName: 'Description',
                        type: 'text',
                      },
                      {
                        propertyName: 'supportingDocLevel',
                        displayName: 'Supporting Document Level',
                        type: 'select',
                        inputData: ['USER_LEVEL', 'APPLICATION_LEVEL'],
                      },
                    ]}
                    formLabel={splitCamelCase(form.requiredSupportingDocuments)}
                  />
                </Col>

                <Col xs={12}>
                  <ArrayObjectEditor
                    attributeName={form.allowedGeoLocationBoundaries}
                    state={formState}
                    updateState={setFormState}
                    schema={[
                      {
                        propertyName: 'lat',
                        displayName: 'Latitude',
                        type: 'text',
                      },
                      {
                        propertyName: 'lon',
                        displayName: 'Longitude',
                        type: 'text',
                      },
                      {
                        propertyName: 'radius',
                        displayName: 'Radius (km)',
                        type: 'text',
                      },
                    ]}
                    formLabel={splitCamelCase(
                      form.allowedGeoLocationBoundaries,
                    )}
                  />
                </Col>

                <Col xs={12}>
                  <ArrayObjectEditor
                    attributeName={form.loanMonthlyDurations}
                    state={formState}
                    updateState={setFormState}
                    schema={[
                      {
                        propertyName: 'duration',
                        displayName: 'Duration',
                        type: 'text',
                      },
                      {
                        propertyName: 'durationType',
                        displayName: 'Duration Type',
                        type: 'select',
                        inputData: ['days', 'weeks', 'months', 'years'],
                      },
                      {
                        propertyName: 'minLoanAmount',
                        displayName: 'Min Amount',
                        type: 'text',
                      },
                      {
                        propertyName: 'maxLoanAmount',
                        displayName: 'Max Amount',
                        type: 'text',
                      },
                    ]}
                    formLabel={splitCamelCase(form.loanMonthlyDurations)}
                  />
                </Col>

                <Col xs={12}>
                  <FormGroup>
                    <Label
                      check
                      htmlFor={form.useRemitaWithTenor}
                      className="text-capitalize mr-3"
                    >
                      {splitCamelCase(
                        removeUnderscore(form.useRemitaWithTenor),
                      )}
                      <Input
                        type="checkbox"
                        className="ml-2"
                        id={form.useRemitaWithTenor}
                        checked={formState.useRemitaWithTenor}
                        onChange={handleCheckboxChange}
                      />
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
            </Collapse>
          </FormGroup>

          <FormGroup tag="fieldset">
            <legend className="mb-0">Uploads</legend>
            <hr className="mb-4" />

            <Col
              className="text-right"
              style={{
                cursor: 'pointer',
                color: collapseState.uploads ? 'red' : 'green',
              }}
              onClick={() => toggleCollapse('uploads')}
            >
              <h6>{collapseState.uploads ? '[-] Collapse' : '[+] Expand'}</h6>
            </Col>

            <Collapse isOpen={collapseState.uploads}>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label htmlFor={form.logoUrl} className="text-capitalize">
                      Merchant Logo (512 x 512)
                    </Label>
                    <Input
                      type="file"
                      id={form.logoUrl}
                      name={form.logoUrl}
                      onChange={handleFileSelection}
                      accept="image/*"
                    />
                    {formState.logoUrl && (
                      <a
                        href={formState.logoUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View Logo
                      </a>
                    )}
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup>
                    <Label
                      htmlFor={form.faviconUrl}
                      className="text-capitalize"
                    >
                      Merchant Favicon (512 x 512)
                    </Label>
                    <Input
                      type="file"
                      id={form.faviconUrl}
                      name={form.faviconUrl}
                      onChange={handleFileSelection}
                      accept="image/*"
                    />
                    {formState.faviconUrl && (
                      <a
                        href={formState.faviconUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View Favicon
                      </a>
                    )}
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup>
                    <Label htmlFor={form.termsAndConditionsUrl}>
                      Terms and Conditions Document (<i>*.pdf</i>)
                    </Label>

                    <Input
                      type="file"
                      id={form.termsAndConditionsUrl}
                      name={form.termsAndConditionsUrl}
                      onChange={handleFileSelection}
                      accept="application/pdf"
                    />

                    <Button
                      color="secondary"
                      size="sm"
                      onClick={handleToggleTermsAndConditions}
                      style={{
                        marginTop: '1rem',
                        marginBottom: '1rem',
                      }}
                    >
                      {toggleTermsAndConditions
                        ? 'Close URL Input'
                        : 'Enter document URL'}
                    </Button>
                    <Collapse isOpen={toggleTermsAndConditions}>
                      <Input
                        type="text"
                        id={form.termsAndConditionsUrl}
                        value={formState.termsAndConditionsUrl}
                        onChange={onFormChange}
                      />
                    </Collapse>
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup>
                    <Label htmlFor={form.privacyPolicyUrl}>
                      Privacy Policy Document (<i>*.pdf</i>)
                    </Label>

                    <Input
                      type="file"
                      id={form.privacyPolicyUrl}
                      name={form.privacyPolicyUrl}
                      onChange={handleFileSelection}
                      accept="application/pdf"
                    />

                    <Button
                      color="secondary"
                      size="sm"
                      onClick={handleTogglePivacyPolicy }
                      style={{
                        marginTop: '1rem',
                        marginBottom: '1rem',
                      }}
                    >
                      {togglePrivacyPolicy
                        ? 'Close URL Input'
                        : 'Enter document URL'}
                    </Button>
                    <Collapse isOpen={togglePrivacyPolicy}>
                      <Input
                        type="text"
                        id={form.privacyPolicyUrl}
                        value={formState.privacyPolicyUrl}
                        onChange={onFormChange}
                      />
                    </Collapse>
                  </FormGroup>
                </Col>
              </Row>
            </Collapse>
          </FormGroup>

          <FormGroup tag="fieldset">
            <legend className="mb-0">Notification Address</legend>
            <hr className="mb-4" />

            <Col
              className="text-right"
              style={{
                cursor: 'pointer',
                color: collapseState.notificationAddresses ? 'red' : 'green',
              }}
              onClick={() => toggleCollapse('notificationAddresses')}
            >
              <h6>
                {collapseState.notificationAddresses
                  ? '[-] Collapse'
                  : '[+] Expand'}
              </h6>
            </Col>

            <Collapse isOpen={collapseState.notificationAddresses}>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label
                      htmlFor={
                        form.notificationAddress_MERCHANT_APPLICATION_APPROVED
                      }
                      className="text-capitalize"
                    >
                      Merchant Application Approved
                    </Label>
                    <Input
                      type="text"
                      id={
                        form.notificationAddress_MERCHANT_APPLICATION_APPROVED
                      }
                      value={
                        formState.notificationAddress_MERCHANT_APPLICATION_APPROVED
                      }
                      onChange={onFormChange}
                    />
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup>
                    <Label
                      htmlFor={
                        form.notificationAddress_MERCHANT_APPLICATION_DENIED
                      }
                      className="text-capitalize"
                    >
                      Merchant Application Denied
                    </Label>
                    <Input
                      type="text"
                      id={form.notificationAddress_MERCHANT_APPLICATION_DENIED}
                      value={
                        formState.notificationAddress_MERCHANT_APPLICATION_DENIED
                      }
                      onChange={onFormChange}
                    />
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup>
                    <Label
                      htmlFor={
                        form.notificationAddress_MERCHANT_APPLICATION_REVIEW
                      }
                      className="text-capitalize"
                    >
                      Merchant Application Review
                    </Label>
                    <Input
                      type="text"
                      id={form.notificationAddress_MERCHANT_APPLICATION_REVIEW}
                      value={
                        formState.notificationAddress_MERCHANT_APPLICATION_REVIEW
                      }
                      onChange={onFormChange}
                    />
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup>
                    <Label
                      htmlFor={form.notificationAddress_MERCHANT_LOAN_DISBURSED}
                      className="text-capitalize"
                    >
                      Merchant Loan Disbursed
                    </Label>
                    <Input
                      type="text"
                      id={form.notificationAddress_MERCHANT_LOAN_DISBURSED}
                      value={
                        formState.notificationAddress_MERCHANT_LOAN_DISBURSED
                      }
                      onChange={onFormChange}
                    />
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup>
                    <Label
                      htmlFor={form.notificationAddress_MERCHANT_LOAN_REPAYMENT}
                      className="text-capitalize"
                    >
                      Merchant Loan Repayment
                    </Label>
                    <Input
                      type="text"
                      id={form.notificationAddress_MERCHANT_LOAN_REPAYMENT}
                      value={
                        formState.notificationAddress_MERCHANT_LOAN_REPAYMENT
                      }
                      onChange={onFormChange}
                    />
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup>
                    <Label
                      htmlFor={form.notificationAddress_WALLET_THRESHOLD}
                      className="text-capitalize"
                    >
                      Wallet Threshold
                    </Label>
                    <Input
                      type="text"
                      id={form.notificationAddress_WALLET_THRESHOLD}
                      value={formState.notificationAddress_WALLET_THRESHOLD}
                      onChange={onFormChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Collapse>
          </FormGroup>

          <FormGroup tag="fieldset">
            <legend className="mb-0">Integrations</legend>
            <hr className="mb-4" />

            <Col
              className="text-right"
              style={{
                cursor: 'pointer',
                color: collapseState.integrations ? 'red' : 'green',
              }}
              onClick={() => toggleCollapse('integrations')}
            >
              <h6>
                {collapseState.integrations ? '[-] Collapse' : '[+] Expand'}
              </h6>
            </Col>

            <Collapse isOpen={collapseState.integrations}>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label
                      htmlFor={form.facebookAppKeys_appId}
                      className="text-capitalize"
                    >
                      {splitCamelCase(
                        removeUnderscore(form.facebookAppKeys_appId),
                      )}
                    </Label>
                    <Input
                      type="text"
                      id={form.facebookAppKeys_appId}
                      value={formState.facebookAppKeys_appId}
                      onChange={onFormChange}
                    />
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup>
                    <Label
                      htmlFor={form.facebookAppKeys_appSecret}
                      className="text-capitalize"
                    >
                      {splitCamelCase(
                        removeUnderscore(form.facebookAppKeys_appSecret),
                      )}
                    </Label>
                    <Input
                      type="text"
                      id={form.facebookAppKeys_appSecret}
                      value={formState.facebookAppKeys_appSecret}
                      onChange={onFormChange}
                    />
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup>
                    <Label
                      htmlFor={form.paystackApiCreds_publicKey}
                      className="text-capitalize"
                    >
                      {splitCamelCase(
                        removeUnderscore(form.paystackApiCreds_publicKey),
                      )}
                    </Label>
                    <Input
                      type="text"
                      id={form.paystackApiCreds_publicKey}
                      value={formState.paystackApiCreds_publicKey}
                      onChange={onFormChange}
                    />
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup>
                    <Label
                      htmlFor={form.paystackApiCreds_secretKey}
                      className="text-capitalize"
                    >
                      {splitCamelCase(
                        removeUnderscore(form.paystackApiCreds_secretKey),
                      )}
                    </Label>
                    <Input
                      type="text"
                      id={form.paystackApiCreds_secretKey}
                      value={formState.paystackApiCreds_secretKey}
                      onChange={onFormChange}
                    />
                  </FormGroup>
                </Col>

                <Col xs={12}>
                  <ArrayObjectEditor
                    attributeName={form.requiredSocialMediaAccounts}
                    state={formState}
                    updateState={setFormState}
                    schema={[
                      {
                        propertyName: 'name',
                        displayName: 'Social Media Name',
                        type: 'select',
                        inputData: [
                          'facebook',
                          'linkedin',
                          'twitter',
                          'instagram',
                        ],
                        unique: true,
                      },
                    ]}
                    formLabel={splitCamelCase(form.requiredSocialMediaAccounts)}
                  />
                </Col>

                <Col xs={12}>
                  <FormGroup>
                    <Label
                      check
                      htmlFor={form.requiresDecide}
                      className="text-capitalize mr-3"
                    >
                      {splitCamelCase(
                        removeUnderscore(form.requiresDecide),
                      )}
                      <Input
                        type="checkbox"
                        className="ml-2"
                        id={form.requiresDecide}
                        checked={formState.requiresDecide}
                        onChange={handleCheckboxChange}
                      />
                    </Label>
                  </FormGroup>
                </Col>

                {formState.requiresDecide && (
                  <Fragment>
                    <Col md={6}>
                      <FormGroup>
                        <Label
                          htmlFor={form.decideCreds_clientKey}
                          className="text-capitalize"
                        >
                          {splitCamelCase(
                            removeUnderscore(form.decideCreds_clientKey),
                          )}
                        </Label>
                        <Input
                          type="text"
                          id={form.decideCreds_clientKey}
                          value={formState.decideCreds_clientKey}
                          onChange={onFormChange}
                        />
                      </FormGroup>
                    </Col>

                    <Col md={6}>
                      <FormGroup>
                        <Label
                          htmlFor={form.decideCreds_clientSecret}
                          className="text-capitalize"
                        >
                          {splitCamelCase(
                            removeUnderscore(form.decideCreds_clientSecret),
                          )}
                        </Label>
                        <Input
                          type="text"
                          id={form.decideCreds_clientSecret}
                          value={formState.decideCreds_clientSecret}
                          onChange={onFormChange}
                        />
                      </FormGroup>
                    </Col>

                    <Col xs={12}>
                    <FormGroup>
                    <Label
                      check
                      htmlFor={form.requiresDecidePdfAnalysis}
                      className="text-capitalize mr-3"
                    >
                      {splitCamelCase(
                        removeUnderscore(form.requiresDecidePdfAnalysis),
                      )}
                      <Input
                        type="checkbox"
                        className="ml-2"
                        id={form.requiresDecidePdfAnalysis}
                        checked={formState.requiresDecidePdfAnalysis}
                        onChange={handleCheckboxChange}
                      />
                    </Label>
                    </FormGroup>
                   </Col>
                  </Fragment>
                )}        

                <Col xs={12}>
                  <FormGroup>
                    <Label
                      check
                      htmlFor={form.requiresBankStatement}
                      className="text-capitalize mr-3"
                    >
                      {splitCamelCase(
                        removeUnderscore(form.requiresBankStatement),
                      )}
                      <Input
                        type="checkbox"
                        className="ml-2"
                        id={form.requiresBankStatement}
                        checked={formState.requiresBankStatement}
                        onChange={handleCheckboxChange}
                      />
                    </Label>
                  </FormGroup>
                </Col>

                {formState.requiresBankStatement && (
                  <Fragment>
                    <Col md={6}>
                      <FormGroup>
                        <Label
                          htmlFor={form.mbsApiKeys_clientId}
                          className="text-capitalize"
                        >
                          {splitCamelCase(
                            removeUnderscore(form.mbsApiKeys_clientId),
                          )}
                        </Label>
                        <Input
                          type="text"
                          id={form.mbsApiKeys_clientId}
                          value={formState.mbsApiKeys_clientId}
                          onChange={onFormChange}
                        />
                      </FormGroup>
                    </Col>

                    <Col md={6}>
                      <FormGroup>
                        <Label
                          htmlFor={form.mbsApiKeys_clientSecret}
                          className="text-capitalize"
                        >
                          {splitCamelCase(
                            removeUnderscore(form.mbsApiKeys_clientSecret),
                          )}
                        </Label>
                        <Input
                          type="text"
                          id={form.mbsApiKeys_clientSecret}
                          value={formState.mbsApiKeys_clientSecret}
                          onChange={onFormChange}
                        />
                      </FormGroup>
                    </Col>
                  </Fragment>
                )}

                <Col xs={12}>
                  <FormGroup>
                    <Label
                      check
                      htmlFor={form.requiresCreditBureauReport}
                      className="text-capitalize mr-3"
                    >
                      {splitCamelCase(
                        removeUnderscore(form.requiresCreditBureauReport),
                      )}
                      <Input
                        type="checkbox"
                        className="ml-2"
                        id={form.requiresCreditBureauReport}
                        checked={formState.requiresCreditBureauReport}
                        onChange={handleCheckboxChange}
                      />
                    </Label>
                  </FormGroup>
                </Col>

                {formState.requiresCreditBureauReport && (
                  <Fragment>
                    <Col md={6}>
                      <FormGroup>
                        <Label
                          htmlFor={form.creditBureauApiCreds_username}
                          className="text-capitalize"
                        >
                          {splitCamelCase(
                            removeUnderscore(
                              form.creditBureauApiCreds_username,
                            ),
                          )}
                        </Label>
                        <Input
                          type="text"
                          id={form.creditBureauApiCreds_username}
                          value={formState.creditBureauApiCreds_username}
                          onChange={onFormChange}
                        />
                      </FormGroup>
                    </Col>

                    <Col md={6}>
                      <FormGroup>
                        <Label
                          htmlFor={form.creditBureauApiCreds_password}
                          className="text-capitalize"
                        >
                          {splitCamelCase(
                            removeUnderscore(
                              form.creditBureauApiCreds_password,
                            ),
                          )}
                        </Label>
                        <Input
                          type="text"
                          id={form.creditBureauApiCreds_password}
                          value={formState.creditBureauApiCreds_password}
                          onChange={onFormChange}
                        />
                      </FormGroup>
                    </Col>
                  </Fragment>
                )}

                <Col xs={12}>
                  <FormGroup>
                    <Label
                      check
                      htmlFor={form.useRemita}
                      className="text-capitalize mr-3"
                    >
                      {splitCamelCase(removeUnderscore(form.useRemita))}
                      <Input
                        type="checkbox"
                        className="ml-2"
                        id={form.useRemita}
                        checked={formState.useRemita}
                        onChange={handleCheckboxChange}
                      />
                    </Label>
                  </FormGroup>
                </Col>

                {formState.useRemita && (
                  <Fragment>
                    <Col md={6}>
                      <FormGroup>
                        <Label
                          htmlFor={form.remitaApiCreds_merchantId}
                          className="text-capitalize"
                        >
                          {splitCamelCase(
                            removeUnderscore(form.remitaApiCreds_merchantId),
                          )}
                        </Label>
                        <Input
                          type="text"
                          id={form.remitaApiCreds_merchantId}
                          value={formState.remitaApiCreds_merchantId}
                          onChange={onFormChange}
                        />
                      </FormGroup>
                    </Col>

                    <Col md={6}>
                      <FormGroup>
                        <Label
                          htmlFor={form.remitaApiCreds_merchantApiKey}
                          className="text-capitalize"
                        >
                          {splitCamelCase(
                            removeUnderscore(
                              form.remitaApiCreds_merchantApiKey,
                            ),
                          )}
                        </Label>
                        <Input
                          type="text"
                          id={form.remitaApiCreds_merchantApiKey}
                          value={formState.remitaApiCreds_merchantApiKey}
                          onChange={onFormChange}
                        />
                      </FormGroup>
                    </Col>

                    <Col md={6}>
                      <FormGroup>
                        <Label
                          htmlFor={form.remitaApiCreds_merchantToken}
                          className="text-capitalize"
                        >
                          {splitCamelCase(
                            removeUnderscore(form.remitaApiCreds_merchantToken),
                          )}
                        </Label>
                        <Input
                          type="text"
                          id={form.remitaApiCreds_merchantToken}
                          value={formState.remitaApiCreds_merchantToken}
                          onChange={onFormChange}
                        />
                      </FormGroup>
                    </Col>

                    <Col md={6}>
                      <FormGroup>
                        <Label
                          htmlFor={form.remitaServiceTypeId}
                          className="text-capitalize"
                        >
                          {splitCamelCase(
                            removeUnderscore(form.remitaServiceTypeId),
                          )}
                        </Label>
                        <Input
                          type="text"
                          id={form.remitaServiceTypeId}
                          value={formState.remitaServiceTypeId}
                          onChange={onFormChange}
                        />
                      </FormGroup>
                    </Col>

                    <Col xs={12}>
                      <FormGroup>
                        <Label
                          check
                          htmlFor={form.requiresRemitaMandate}
                          className="text-capitalize mr-3"
                        >
                          {splitCamelCase(
                            removeUnderscore(form.requiresRemitaMandate),
                          )}
                          <Input
                            type="checkbox"
                            className="ml-2"
                            id={form.requiresRemitaMandate}
                            checked={formState.requiresRemitaMandate}
                            onChange={handleCheckboxChange}
                          />
                        </Label>
                      </FormGroup>
                    </Col>
                  </Fragment>
                )}

                <Col xs={12}>
                  <FormGroup>
                    <Label
                      check
                      htmlFor={form.requiresCreditRegistryReport}
                      className="text-capitalize mr-3"
                    >
                      {splitCamelCase(
                        removeUnderscore(form.requiresCreditRegistryReport),
                      )}
                      <Input
                        type="checkbox"
                        className="ml-2"
                        id={form.requiresCreditRegistryReport}
                        checked={formState.requiresCreditRegistryReport}
                        onChange={handleCheckboxChange}
                      />
                    </Label>
                  </FormGroup>
                </Col>

                {formState.requiresCreditRegistryReport && (
                  <Fragment>
                    <Col md={6}>
                      <FormGroup>
                        <Label
                          htmlFor={form.creditRegistryApiCreds_subscriberId}
                          className="text-capitalize"
                        >
                          Credit Registry Subscriber ID
                        </Label>
                        <Input
                          type="text"
                          id={form.creditRegistryApiCreds_subscriberId}
                          value={formState.creditRegistryApiCreds_subscriberId}
                          onChange={onFormChange}
                        />
                      </FormGroup>
                    </Col>

                    <Col md={6}>
                      <FormGroup>
                        <Label
                          htmlFor={form.creditRegistryApiCreds_email}
                          className="text-capitalize"
                        >
                          Credit Registry Email
                        </Label>
                        <Input
                          type="text"
                          id={form.creditRegistryApiCreds_email}
                          value={formState.creditRegistryApiCreds_email}
                          onChange={onFormChange}
                        />
                      </FormGroup>
                    </Col>

                    <Col md={6}>
                      <FormGroup>
                        <Label
                          htmlFor={form.creditRegistryApiCreds_password}
                          className="text-capitalize"
                        >
                          Credit Registry Password
                        </Label>
                        <Input
                          type="text"
                          id={form.creditRegistryApiCreds_password}
                          value={formState.creditRegistryApiCreds_password}
                          onChange={onFormChange}
                        />
                      </FormGroup>
                    </Col>
                  </Fragment>
                )}

                <Col xs={12}>
                  <FormGroup>
                    <Label
                      check
                      htmlFor={form.requiresCrcReport}
                      className="text-capitalize mr-3"
                    >
                      Requires CRC Credit Bureau Nano Report
                      <Input
                        type="checkbox"
                        className="ml-2"
                        id={form.requiresCrcReport}
                        checked={formState.requiresCrcReport}
                        onChange={handleCheckboxChange}
                      />
                    </Label>
                  </FormGroup>
                </Col>

                {formState.requiresCrcReport && (
                  <Fragment>
                    <Col md={6}>
                      <FormGroup>
                        <Label
                          htmlFor={form.crcApiCreds_strUserID}
                          className="text-capitalize"
                        >
                          CRC Api Creds UserId
                        </Label>
                        <Input
                          type="text"
                          id={form.crcApiCreds_strUserID}
                          value={formState.crcApiCreds_strUserID}
                          onChange={onFormChange}
                        />
                      </FormGroup>
                    </Col>

                    <Col md={6}>
                      <FormGroup>
                        <Label
                          htmlFor={form.crcApiCreds_strPassword}
                          className="text-capitalize"
                        >
                          CRC Api Creds Password
                        </Label>
                        <Input
                          type="text"
                          id={form.crcApiCreds_strPassword}
                          value={formState.crcApiCreds_strPassword}
                          onChange={onFormChange}
                        />
                      </FormGroup>
                    </Col>
                  </Fragment>
                )}

                <Col xs={12}>
                  <FormGroup>
                    <Label
                      check
                      htmlFor={form.requiresCrcMicroReport}
                      className="text-capitalize mr-3"
                    >
                      Requires CRC Credit Bureau Micro Report
                      <Input
                        type="checkbox"
                        className="ml-2"
                        id={form.requiresCrcMicroReport}
                        checked={formState.requiresCrcMicroReport}
                        onChange={handleCheckboxChange}
                      />
                    </Label>
                  </FormGroup>
                </Col>

                {formState.requiresCrcMicroReport && (
                  <Fragment>
                    <Col md={6}>
                      <FormGroup>
                        <Label
                          htmlFor={form.crcApiCreds_strUserID}
                          className="text-capitalize"
                        >
                          CRC Api Creds UserId
                        </Label>
                        <Input
                          type="text"
                          id={form.crcApiCreds_strUserID}
                          value={formState.crcApiCreds_strUserID}
                          onChange={onFormChange}
                        />
                      </FormGroup>
                    </Col>

                    <Col md={6}>
                      <FormGroup>
                        <Label
                          htmlFor={form.crcApiCreds_strPassword}
                          className="text-capitalize"
                        >
                          CRC Api Creds Password
                        </Label>
                        <Input
                          type="text"
                          id={form.crcApiCreds_strPassword}
                          value={formState.crcApiCreds_strPassword}
                          onChange={onFormChange}
                        />
                      </FormGroup>
                    </Col>
                  </Fragment>
                )}

                <Col xs={12}>
                  <FormGroup>
                    <Label
                      check
                      htmlFor={form.requiresOkraTransactions}
                      className="text-capitalize mr-3"
                    >
                      {splitCamelCase(
                        removeUnderscore(form.requiresOkraTransactions),
                      )}
                      <Input
                        type="checkbox"
                        className="ml-2"
                        id={form.requiresOkraTransactions}
                        checked={formState.requiresOkraTransactions}
                        onChange={handleCheckboxChange}
                      />
                    </Label>
                  </FormGroup>
                </Col>

                {formState.requiresOkraTransactions && (
                  <Fragment>
                    <Col md={6}>
                      <FormGroup>
                        <Label
                          htmlFor={form.okraValidityPeriod}
                          className="text-capitalize"
                        >
                          {splitCamelCase(
                            removeUnderscore(form.okraValidityPeriod),
                          )}
                        </Label>
                        <Input
                          type="text"
                          placeholder="Default: 30 days"
                          id={form.okraValidityPeriod}
                          value={formState.okraValidityPeriod}
                          onChange={onFormChange}
                        />
                      </FormGroup>
                    </Col>

                    <Col md={6}>
                      <FormGroup>
                        <Label
                          htmlFor={form.okraApiCreds_widgetUrl}
                          className="text-capitalize"
                        >
                          {splitCamelCase(
                            removeUnderscore(form.okraApiCreds_widgetUrl),
                          )}
                        </Label>
                        <Input
                          type="text"
                          id={form.okraApiCreds_widgetUrl}
                          value={formState.okraApiCreds_widgetUrl}
                          onChange={onFormChange}
                        />
                      </FormGroup>
                    </Col>

                    <Col md={6}>
                      <FormGroup>
                        <Label
                          htmlFor={form.okraApiCreds_publicKey}
                          className="text-capitalize"
                        >
                          {splitCamelCase(
                            removeUnderscore(form.okraApiCreds_publicKey),
                          )}
                        </Label>
                        <Input
                          type="text"
                          id={form.okraApiCreds_publicKey}
                          value={formState.okraApiCreds_publicKey}
                          onChange={onFormChange}
                        />
                      </FormGroup>
                    </Col>

                    <Col md={6}>
                      <FormGroup>
                        <Label
                          htmlFor={form.okraApiCreds_privateKey}
                          className="text-capitalize"
                        >
                          {splitCamelCase(
                            removeUnderscore(form.okraApiCreds_privateKey),
                          )}
                        </Label>
                        <Input
                          type="text"
                          id={form.okraApiCreds_privateKey}
                          value={formState.okraApiCreds_privateKey}
                          onChange={onFormChange}
                        />
                      </FormGroup>
                    </Col>

                    <Col md={6}>
                      <FormGroup>
                        <Label
                          htmlFor={form.okraApiCreds_clientToken}
                          className="text-capitalize"
                        >
                          {splitCamelCase(
                            removeUnderscore(form.okraApiCreds_clientToken),
                          )}
                        </Label>
                        <Input
                          type="text"
                          id={form.okraApiCreds_clientToken}
                          value={formState.okraApiCreds_clientToken}
                          onChange={onFormChange}
                        />
                      </FormGroup>
                    </Col>

                    <Col xs={12}>
                      <FormGroup>
                        <Label
                          check
                          htmlFor={form.okraApiCreds_requiresEnhancedBalance}
                          className="text-capitalize mr-3"
                        >
                          {splitCamelCase(
                            removeUnderscore(
                              form.okraApiCreds_requiresEnhancedBalance,
                            ),
                          )}
                          <Input
                            type="checkbox"
                            className="ml-2"
                            id={form.okraApiCreds_requiresEnhancedBalance}
                            checked={
                              formState.okraApiCreds_requiresEnhancedBalance
                            }
                            onChange={handleCheckboxChange}
                          />
                        </Label>
                      </FormGroup>
                    </Col>
                  </Fragment>
                )}

                <Col xs={12}>
                  <FormGroup>
                    <Label
                      check
                      htmlFor={form.okraDirectDebitIsEnabled}
                      className="text-capitalize mr-3"
                    >
                      {splitCamelCase(
                        removeUnderscore(form.okraDirectDebitIsEnabled),
                      )}
                      <Input
                        type="checkbox"
                        className="ml-2"
                        id={form.okraDirectDebitIsEnabled}
                        checked={formState.okraDirectDebitIsEnabled}
                        onChange={handleCheckboxChange}
                      />
                    </Label>
                  </FormGroup>
                </Col>

                <Col xs={12}>
                  <FormGroup>
                    <Label
                      check
                      htmlFor={form.requiresOnePipeLookUp}
                      className="text-capitalize mr-3"
                    >
                      {splitCamelCase(
                        removeUnderscore(form.requiresOnePipeLookUp),
                      )}
                      <Input
                        type="checkbox"
                        className="ml-2"
                        id={form.requiresOnePipeLookUp}
                        checked={formState.requiresOnePipeLookUp}
                        onChange={handleCheckboxChange}
                      />
                    </Label>
                  </FormGroup>
                </Col>

                {formState.requiresOnePipeLookUp && (
                  <Fragment>
                    <Col xs={12}>
                      <ArrayObjectEditor
                        attributeName={form.addCardCharges}
                        state={formState}
                        updateState={setFormState}
                        schema={[
                          {
                            propertyName: 'amount',
                            displayName: 'Charge Amount',
                            type: 'text',
                          },
                        ]}
                        formLabel={splitCamelCase(form.addCardCharges)}
                      />
                    </Col>
                  </Fragment>
                )}

                <Col xs={12}>
                  <FormGroup>
                    <Label
                      check
                      htmlFor={form.monoTransactionsIsEnabled}
                      className="text-capitalize mr-3"
                    >
                      {splitCamelCase(
                        removeUnderscore(form.monoTransactionsIsEnabled),
                      )}
                      <Input
                        type="checkbox"
                        className="ml-2"
                        id={form.monoTransactionsIsEnabled}
                        checked={formState.monoTransactionsIsEnabled}
                        onChange={handleCheckboxChange}
                      />
                    </Label>
                  </FormGroup>
                </Col>

                {formState.monoTransactionsIsEnabled && (
                  <Fragment>
                    <Col md={6}>
                      <FormGroup>
                        <Label
                          htmlFor={form.monoApiCreds_publicKey}
                          className="text-capitalize"
                        >
                          {splitCamelCase(
                            removeUnderscore(form.monoApiCreds_publicKey),
                          )}
                        </Label>
                        <Input
                          type="text"
                          id={form.monoApiCreds_publicKey}
                          value={formState.monoApiCreds_publicKey}
                          onChange={onFormChange}
                        />
                      </FormGroup>
                    </Col>

                    <Col md={6}>
                      <FormGroup>
                        <Label
                          htmlFor={form.monoApiCreds_secretKey}
                          className="text-capitalize"
                        >
                          {splitCamelCase(
                            removeUnderscore(form.monoApiCreds_secretKey),
                          )}
                        </Label>
                        <Input
                          type="text"
                          id={form.monoApiCreds_secretKey}
                          value={formState.monoApiCreds_secretKey}
                          onChange={onFormChange}
                        />
                      </FormGroup>
                    </Col>
                  </Fragment>
                )}

                <Col xs={12}>
                  <FormGroup>
                    <Label
                      check
                      htmlFor={form.requiresRecova}
                      className="text-capitalize mr-3"
                    >
                      {splitCamelCase(
                        removeUnderscore(form.requiresRecova),
                      )}
                      <Input
                        type="checkbox"
                        className="ml-2"
                        id={form.requiresRecova}
                        checked={formState.requiresRecova}
                        onChange={handleCheckboxChange}
                      />
                    </Label>
                  </FormGroup>
                </Col>

                {formState.requiresRecova && (
                  <Fragment>
                    <Col md={6}>
                      <FormGroup>
                        <Label
                          htmlFor={form.recovaApiCreds_token}
                          className="text-capitalize"
                        >
                          {splitCamelCase(
                            removeUnderscore(form.recovaApiCreds_token),
                          )}
                        </Label>
                        <Input
                          type="text"
                          id={form.recovaApiCreds_token}
                          value={formState.recovaApiCreds_token}
                          onChange={onFormChange}
                        />
                      </FormGroup>
                    </Col>
                  </Fragment>
                )}

                <Col xs={12}>
                  <FormGroup>
                    <Label
                      check
                      htmlFor={form.requiresCreditScore}
                      className="text-capitalize mr-3"
                    >
                      {splitCamelCase(
                        removeUnderscore(form.requiresCreditScore),
                      )}
                      <Input
                        type="checkbox"
                        className="ml-2"
                        id={form.requiresCreditScore}
                        checked={formState.requiresCreditScore}
                        onChange={handleCheckboxChange}
                      />
                    </Label>
                  </FormGroup>
                </Col>

                <Col xs={12}>
                  <FormGroup>
                    <Label
                      check
                      htmlFor={form.rollbackEligibility}
                      className="text-capitalize mr-3"
                    >
                      {splitCamelCase(
                        removeUnderscore(form.rollbackEligibility),
                      )}
                      <Input
                        type="checkbox"
                        className="ml-2"
                        id={form.rollbackEligibility}
                        checked={formState.rollbackEligibility}
                        onChange={handleCheckboxChange}
                      />
                    </Label>
                  </FormGroup>
                </Col>

                <Col xs={12}>
                  <FormGroup>
                    <Label
                      check
                      htmlFor={form.requiresMaxApprovedLoanAmount}
                      className="text-capitalize mr-3"
                    >
                      {splitCamelCase(
                        removeUnderscore(form.requiresMaxApprovedLoanAmount),
                      )}
                      <Input
                        type="checkbox"
                        className="ml-2"
                        id={form.requiresMaxApprovedLoanAmount}
                        checked={formState.requiresMaxApprovedLoanAmount}
                        onChange={handleCheckboxChange}
                      />
                    </Label>
                  </FormGroup>
                </Col>

                <Col xs={12}>
                  <FormGroup>
                    <Label
                      check
                      htmlFor={form.requiresBankStatementUpload}
                      className="text-capitalize mr-3"
                    >
                      {splitCamelCase(
                        removeUnderscore(form.requiresBankStatementUpload),
                      )}
                      <Input
                        type="checkbox"
                        className="ml-2"
                        id={form.requiresBankStatementUpload}
                        checked={formState.requiresBankStatementUpload}
                        onChange={handleCheckboxChange}
                      />
                    </Label>
                  </FormGroup>
                </Col>

                <Col xs={12}>
                  <FormGroup>
                    <Label
                      check
                      htmlFor={form.verifyWorkEmail}
                      className="text-capitalize mr-3"
                    >
                      {splitCamelCase(removeUnderscore(form.verifyWorkEmail))}
                      <Input
                        type="checkbox"
                        className="ml-2"
                        id={form.verifyWorkEmail}
                        checked={formState.verifyWorkEmail}
                        onChange={handleCheckboxChange}
                      />
                    </Label>
                  </FormGroup>
                </Col>

                <Col xs={12}>
                  <FormGroup>
                    <Label
                      check
                      htmlFor={form.requireWorkEmail}
                      className="text-capitalize mr-3"
                    >
                      {splitCamelCase(removeUnderscore(form.requireWorkEmail))}
                      <Input
                        type="checkbox"
                        className="ml-2"
                        id={form.requireWorkEmail}
                        checked={formState.requireWorkEmail}
                        onChange={handleCheckboxChange}
                      />
                    </Label>
                  </FormGroup>
                </Col>

                <Col xs={12}>
                  <FormGroup>
                    <Label
                      check
                      htmlFor={form.requiresSelfieUpload}
                      className="text-capitalize mr-3"
                    >
                      {splitCamelCase(
                        removeUnderscore(form.requiresSelfieUpload),
                      )}
                      <Input
                        type="checkbox"
                        className="ml-2"
                        id={form.requiresSelfieUpload}
                        checked={formState.requiresSelfieUpload}
                        onChange={handleCheckboxChange}
                      />
                    </Label>
                  </FormGroup>
                </Col>

                <Col xs={12}>
                  <FormGroup>
                    <Label
                      check
                      htmlFor={form.requiresWorkIDUpload}
                      className="text-capitalize mr-3"
                    >
                      {splitCamelCase(
                        removeUnderscore(form.requiresWorkIDUpload),
                      )}
                      <Input
                        type="checkbox"
                        className="ml-2"
                        id={form.requiresWorkIDUpload}
                        checked={formState.requiresWorkIDUpload}
                        onChange={handleCheckboxChange}
                      />
                    </Label>
                  </FormGroup>
                </Col>

                <Col xs={12}>
                  <FormGroup>
                    <Label
                      check
                      htmlFor={form.requiresExternalDisbursement}
                      className="text-capitalize mr-3"
                    >
                      {splitCamelCase(
                        removeUnderscore(form.requiresExternalDisbursement),
                      )}
                      <Input
                        type="checkbox"
                        className="ml-2"
                        id={form.requiresExternalDisbursement}
                        checked={formState.requiresExternalDisbursement}
                        onChange={handleCheckboxChange}
                      />
                    </Label>
                  </FormGroup>
                </Col>

                <Col xs={12}>
                  <FormGroup>
                    <Label
                      check
                      htmlFor={form.deactivateMessageService}
                      className="text-capitalize mr-3"
                    >
                      {splitCamelCase(
                        removeUnderscore(form.deactivateMessageService),
                      )}
                      <Input
                        type="checkbox"
                        className="ml-2"
                        id={form.deactivateMessageService}
                        checked={formState.deactivateMessageService}
                        onChange={handleCheckboxChange}
                      />
                    </Label>
                  </FormGroup>
                </Col>

                <Col xs={12}>
                  <FormGroup>
                    <Label
                      check
                      htmlFor={form.noCardImplementation}
                      className="text-capitalize mr-3"
                    >
                      {splitCamelCase(
                        removeUnderscore(form.noCardImplementation),
                      )}
                      <Input
                        type="checkbox"
                        className="ml-2"
                        id={form.noCardImplementation}
                        checked={formState.noCardImplementation}
                        onChange={handleCheckboxChange}
                      />
                    </Label>
                  </FormGroup>
                </Col>

                <Col xs={12}>
                  <FormGroup>
                    <Label
                      check
                      htmlFor={form.requiresEventTrigger}
                      className="text-capitalize mr-3"
                    >
                      {splitCamelCase(
                        removeUnderscore(form.requiresEventTrigger),
                      )}
                      <Input
                        type="checkbox"
                        className="ml-2"
                        id={form.requiresEventTrigger}
                        checked={formState.requiresEventTrigger}
                        onChange={handleCheckboxChange}
                      />
                    </Label>
                  </FormGroup>
                </Col>

                <Col xs={12}>
                  <FormGroup>
                    <Label
                      check
                      htmlFor={form.enablePartialDebit}
                      className="text-capitalize mr-3"
                    >
                      {splitCamelCase(
                        removeUnderscore(form.enablePartialDebit),
                      )}
                      <Input
                        type="checkbox"
                        className="ml-2"
                        id={form.enablePartialDebit}
                        checked={formState.enablePartialDebit}
                        onChange={handleCheckboxChange}
                      />
                    </Label>
                  </FormGroup>
                </Col>

                <Col xs={12}>
                  <FormGroup>
                    <Label
                      check
                      htmlFor={form.enableEmailVerificationCode}
                      className="text-capitalize mr-3"
                    >
                      {splitCamelCase(
                        removeUnderscore(form.enableEmailVerificationCode),
                      )}
                      <Input
                        type="checkbox"
                        className="ml-2"
                        id={form.enableEmailVerificationCode}
                        checked={formState.enableEmailVerificationCode}
                        onChange={handleCheckboxChange}
                      />
                    </Label>
                  </FormGroup>
                </Col>

                <Col xs={12}>
                  <FormGroup>
                    <Label
                      check
                      htmlFor={form.requiresFacebookUrl}
                      className="text-capitalize mr-3"
                    >
                      {splitCamelCase(
                        removeUnderscore(form.requiresFacebookUrl),
                      )}
                      <Input
                        type="checkbox"
                        className="ml-2"
                        id={form.requiresFacebookUrl}
                        checked={formState.requiresFacebookUrl}
                        onChange={handleCheckboxChange}
                      />
                    </Label>
                  </FormGroup>
                </Col>

                <Col xs={12}>
                  <FormGroup>
                    <Label
                      check
                      htmlFor={form.enableWebhooksConfig}
                      className="text-capitalize mr-3"
                    >
                      {splitCamelCase(
                        removeUnderscore(form.enableWebhooksConfig),
                      )}
                      <Input
                        type="checkbox"
                        className="ml-2"
                        id={form.enableWebhooksConfig}
                        checked={formState.enableWebhooksConfig}
                        onChange={handleCheckboxChange}
                      />
                    </Label>
                  </FormGroup>
                </Col>

                <Col xs={12}>
                  <FormGroup>
                    <Label
                      check
                      htmlFor={form.loanOfferLetterFeatureIsEnabled}
                      className="text-capitalize mr-3"
                    >
                      {splitCamelCase(
                        removeUnderscore(form.loanOfferLetterFeatureIsEnabled),
                      )}
                      <Input
                        type="checkbox"
                        className="ml-2"
                        id={form.loanOfferLetterFeatureIsEnabled}
                        checked={formState.loanOfferLetterFeatureIsEnabled}
                        onChange={handleCheckboxChange}
                      />
                    </Label>
                  </FormGroup>
                </Col>

                <Col xs={12}>
                  <FormGroup>
                    <Label
                      check
                      htmlFor={form.loanAndApplicationServiceEnabled}
                      className="text-capitalize mr-3"
                    >
                      {splitCamelCase(
                        removeUnderscore(form.loanAndApplicationServiceEnabled),
                      )}
                      <Input
                        type="checkbox"
                        className="ml-2"
                        id={form.loanAndApplicationServiceEnabled}
                        checked={formState.loanAndApplicationServiceEnabled}
                        onChange={handleCheckboxChange}
                      />
                    </Label>
                  </FormGroup>
                </Col>

                <Col xs={12}>
                  <FormGroup>
                    <Label
                      check
                      htmlFor={form.manualOfferLetter}
                      className="text-capitalize mr-3"
                    >
                      {splitCamelCase(removeUnderscore(form.manualOfferLetter))}
                      <Input
                        type="checkbox"
                        className="ml-2"
                        id={form.manualOfferLetter}
                        checked={formState.manualOfferLetter}
                        onChange={handleCheckboxChange}
                      />
                    </Label>
                  </FormGroup>
                </Col>

                <Col xs={12}>
                  <FormGroup>
                    <Label
                      check
                      htmlFor={form.useLoanGroups}
                      className="text-capitalize mr-3"
                    >
                      Loan Group Feature Is Enabled
                      <Input
                        type="checkbox"
                        className="ml-2"
                        id={form.useLoanGroups}
                        checked={formState.useLoanGroups}
                        onChange={handleCheckboxChange}
                      />
                    </Label>
                  </FormGroup>
                </Col>

              </Row>
            </Collapse>
          </FormGroup>

          <Button color="primary" type="submit">
            Update Attributes
          </Button>
        </Form>

        {merchantFetchError && (
          <div className="text-center col-12">
            <Button color="primary" onClick={getMerchant}>
              Reload
            </Button>
          </div>
        )}
      </Card>
    </Fragment>
  );
};

SingleMerchantAttributes.defaultProps = {
  merchantAttributes: [],
};

export default SingleMerchantAttributes;
