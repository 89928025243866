import React, { useState } from 'react';
import { AuthProvider } from '../../context/authContext';
import { authTypes } from '../../config/constants';

const Auth = ({ children }) => {
  const existingAuthData = JSON.parse(localStorage.getItem('auth'));
  const [authData, setAuthData] = useState(existingAuthData);
  const [loading, setLoading] = useState(false);

  const setAuth = data => {
    data
      ? localStorage.setItem('auth', JSON.stringify(data))
      : localStorage.clear();
    setAuthData(data);
  };

  const setSession = (data, type = authTypes.FIXED) => {
    const { tokenId, imageUrl, isActive, userName, role, id } = data;

    const loggedInUser = {
      userName,
      imageUrl,
      id,
      role,
    };

    if (type === authTypes.FIXED && !isActive) return logout();

    setAuth({
      authenticated: type === authTypes.FIXED,
      apiKey: tokenId,
      loggedInUser,
    });
  };

  const logout = () => {
    setAuth();
  };

  const authProviderValue = {
    ...authData,
    setSession,
    loading,
    setLoading,
    logout,
  };

  return <AuthProvider value={authProviderValue}>{children}</AuthProvider>;
};

export default Auth;
