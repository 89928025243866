import React, { useState, Fragment } from 'react';
import { Input, Table, Button, Label } from 'reactstrap';
import { toast } from 'react-toastify';
import { errors } from '../../config/constants';
import NumberFormat from 'react-number-format';
import { removeUnderscore, removeCommas } from '../../lib/utils';

const ArrayObjectEditor = ({
  attributeName,
  state,
  updateState,
  schema,
  formLabel,
}) => {
  const [newObj, setNewObj] = useState(() => {
    const initialState = {};

    schema.forEach(({ propertyName }) => {
      initialState[propertyName] = '';
    });

    return initialState;
  });

  // Amount fields
  const amountFieldsArray = ['minLoanAmount', 'maxLoanAmount', 'amount', ];

  const propertyNameArray = schema.map(item => item.propertyName);

  let isAmountField = null;

  for(const val of propertyNameArray) {
    isAmountField = amountFieldsArray.includes(val) ? true : false;
  } 
 

  const updateNewObjectInput = e => {
    const { name, value } = e.target;

    setNewObj({
      ...newObj,
      [name]: removeCommas(value),
    });
  };

  const updateNewObjectSelectInput = (e, propName) => {
    const { value } = e.target;

    setNewObj({
      ...newObj,
      [propName]: value,
    });
  };

  const clearNewObjectInput = () => {
    const newState = {};

    schema.forEach(({ propertyName, type, targetElement }) => {
      if (type === 'select') {
        targetElement.selectedIndex = 0;
      } else {
        newState[propertyName] = '';
      }
    });

    setNewObj(newState);
  };

  const addNew = () => {
    let allFieldsProvided = true;
    let repeatedItem = '';

    const stateSlice = [...state[attributeName]];
    schema.forEach(({ propertyName, unique }) => {
      if (!newObj[propertyName]) allFieldsProvided = false;
      if (unique) {
        for (let i = 0; i < stateSlice.length; i++) {
          if (stateSlice[i][propertyName] === newObj[propertyName]) {
            repeatedItem = newObj[propertyName];
            break;
          }
        }
      }
    });

    if (repeatedItem !== '') {
      toast.error(`${errors.DUPLICATE_ITEM}: '${repeatedItem}'`);
      return;
    }

    if (!allFieldsProvided) {
      toast.error(errors.ENTER_ALL_FIELD);
      return;
    }

    stateSlice.push(newObj);

    clearNewObjectInput();
    updateState({
      ...state,
      [attributeName]: stateSlice,
    });
  };

  const onDelete = position => {
    const stateSlice = [...state[attributeName]];
    stateSlice.splice(position, 1);

    updateState({
      ...state,
      [attributeName]: stateSlice,
    });
  };

  const onChange = (e, position) => {
    const { id, value } = e.target;

    const stateSlice = [...state[attributeName]];
    stateSlice[position] = {
      ...stateSlice[position],
      [id]: value,
    };

    updateState({
      ...state,
      [attributeName]: stateSlice,
    });
  };

  const updateSelectInput = (e, position, propName) => {
    const { value } = e.target;

    const stateSlice = [...state[attributeName]];
    stateSlice[position] = {
      ...stateSlice[position],
      [propName]: value,
    };

    updateState({
      ...state,
      [attributeName]: stateSlice,
    });
  };

  const registerElement = (e, position) => {
    schema[position].targetElement = e.target;
  };

  return (
    <Fragment>
      <Label className="text-capitalize">{formLabel}</Label>

      <Table borderless responsive className="m-0">
        <thead>
          <tr className="d-flex align-items-center">
            <td className="col-1"></td>

            {schema.map(({ displayName }, index) => (
              <td key={index} className="col-2">
                {displayName}
              </td>
            ))}

            <th className="col-2"></th>
          </tr>
        </thead>
        <tbody>
          {state[attributeName].map((obj, index) => (
            <tr key={index} className="d-flex align-items-center">
              <td key={`${index}_index`} className="col-1">
                {index + 1}
              </td>

              {schema.map(({ propertyName, type, inputData, unique }, pos) =>
              type === 'text' ? (
                !isAmountField ? (
                <td key={pos} className="col-2">
                <Input
                  type={type}
                  id={propertyName}
                  value={obj[propertyName]}
                  onChange={e => {
                    onChange(e, index);
                  }}
                />
               </td>
              ) : (
                <td key={pos} className="col-2">
                  <NumberFormat
                    id={propertyName}
                    customInput={Input}
                    value={obj[propertyName]}
                    thousandSeparator={true}
                    onChange={e => {
                      onChange(e, index);
                    }}
                  />
                </td>
                )
              ) : (
                <td key={pos} className={unique ? 'col-4' : 'col-2'}>
                  <Input
                    type={unique ? 'text' : type}
                    value={obj[propertyName]}
                    disabled={unique}
                    onChange={e => {
                      updateSelectInput(e, index, propertyName);
                    }}
                  >
                    {inputData.map((item, index) => (
                      <option value={item} key={index}>
                        {removeUnderscore(item)}
                      </option>
                    ))}
                  </Input>
                </td>
              ),
            )}

              <td key={`${index}_button`} className="col-2">
                <Button
                  size="sm"
                  color="danger"
                  className="ml-auto"
                  onClick={() => {
                    onDelete(index);
                  }}
                >
                  Delete
                </Button>
              </td>
            </tr>
          ))}
          <tr className="d-flex align-items-center">
            <td className="col-1">{state[attributeName].length + 1}</td>

            {schema.map(({ propertyName, type, inputData, unique }, index) =>
              type === 'text' ? (
                !isAmountField ? (
                <td key={index} className="col-2">
                <Input
                  type={type}
                  name={propertyName}
                  value={newObj[propertyName]}
                  onChange={updateNewObjectInput}
                />
               </td>
              ) : (
                <td key={index} className="col-2">
                  <NumberFormat
                    name={propertyName}
                    customInput={Input}
                    value={newObj[propertyName]}
                    thousandSeparator={true}
                    onChange={updateNewObjectInput}
                  />
                </td>
                )
              ) : (
                <td key={index} className={unique ? 'col-4' : 'col-2'}>
                  <Input
                    type={type}
                    onChange={e => {
                      updateNewObjectSelectInput(e, propertyName);
                    }}
                    onClick={e => {
                      registerElement(e, index);
                    }}
                  >
                    <option value="">-- Select --</option>
                    {inputData.map((item, index) => (
                      <option value={item} key={index}>
                        {removeUnderscore(item)}
                      </option>
                    ))}
                  </Input>
                </td>
              ),
            )}
            <td className="col-2">
              <Button
                size="sm"
                color="secondary"
                className="ml-auto"
                onClick={addNew}
              >
                Add
              </Button>
            </td>
          </tr>
        </tbody>
      </Table>

      <hr className="mb-4" />
    </Fragment>
  );
};

export default ArrayObjectEditor;
