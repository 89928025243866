import React from 'react';
import { Card, Col, Container, Row } from 'reactstrap';

const Page404 = () => (
  <Container className="roles">
    <Row>
      <Col xl={12}>
        <Card className="o-hidden border-0 shadow-lg mb-5 ">
          <section className="p-4 p-sm-5">
            <Row>
              <Col>
                <h2 className="display-2 text-center mb-3">Oops!</h2>
                <h3 className="text-center">404 - Page Not Found</h3>
              </Col>
            </Row>
          </section>
        </Card>
      </Col>
    </Row>
  </Container>
);

export default Page404;
