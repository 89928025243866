import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { NavLink, Redirect, Route, Switch, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Badge, Col, Container, NavItem, Row } from 'reactstrap';
import { PageTitle, Sidebar } from '../../components';
import { useAuth } from '../../context/authContext';
import { handleApiErrors } from '../../lib/utils';
import {
  fetchMerchants,
  fetchBanks,
  getWalletBalance,
  getClientInfo,
} from '../../services';
import {
  SingleMerchantAttributes,
  SingleMerchantUsers,
  SingleMerchantBankAccounts,
  SingleMerchantWallet,
  SingleMerchantCustomers,
  SingleMerchantDecideWallet,
} from './';
import SingleMerchantDecideMonoConfig from './sub-sections/SingleMerchantDecideMonoConfig';

const SingleMerchant = () => {
  const { setLoading } = useAuth();
  const param = useParams();
  const [merchant, setMerchant] = useState([]);
  const [banks, setBanks] = useState([]);
  const [merchantFetchError, setMerchantFetchError] = useState(false);
  const [decideWallet, setDecideWallet] = useState({});

  const getMerchant = useCallback(async () => {
    const slug = param.slug;

    setLoading(true);
    const response = await fetchMerchants({ slug });
    const apiErrors = handleApiErrors(response);

    const bankResponse = await fetchBanks();
    const bankApiErrors = handleApiErrors(bankResponse);

    setLoading(false);
    if (apiErrors || bankApiErrors) {
      toast.error(apiErrors || bankApiErrors);
      setMerchantFetchError(apiErrors || bankApiErrors);
      return;
    }

    // temp fix to make sure we get the exact merchant
    const accountsList = response.data.data.accounts || [];
    let merchants = accountsList[0];

    if (accountsList.length > 0) {
      merchants = accountsList.find(m => {
        const slugAttrib =
          m.accountAttribute &&
          m.accountAttribute.find(
            accAttr => accAttr.attribute.name === 'clientSlug',
          );

        if (slugAttrib && slugAttrib.value === slug) return true;
        return false;
      });
    }
    setMerchantFetchError(false);
    setMerchant(merchants);

    //decide logic
    const merchantId = merchants && merchants.id;

    const decideWalletResponse = await getWalletBalance(slug, merchantId);
    const decideWalletErrors = handleApiErrors(decideWalletResponse);

    if (decideWalletErrors) {
      toast.error(decideWalletErrors);
      setMerchantFetchError(decideWalletErrors);
      return;
    }

    const walletBalance =
      decideWalletResponse &&
      decideWalletResponse.data &&
      decideWalletResponse.data.data;
    setDecideWallet(walletBalance);

    setBanks(bankResponse.data.data);
    localStorage.setItem('merchantId', merchants.id);
  }, [param.slug, setLoading]);

  useEffect(() => {
    getMerchant();
  }, [getMerchant]);

  useEffect(() => {
    document.title = `${merchant && merchant.name} Details`;
  }, [merchant]);

  const balance =
    decideWallet.wallet_balance && decideWallet.wallet_balance.amount
      ? parseInt(decideWallet.wallet_balance.amount) / 100
      : 0;

  const transactionFee =
    decideWallet.transaction_fee && decideWallet.transaction_fee.amount
      ? parseInt(decideWallet.transaction_fee.amount) / 100
      : 0;

  const prePaidBalance =
    decideWallet &&
    decideWallet.wallets &&
    decideWallet.wallets.find(item => item && item.type === 'pre_paid');

  const postPaidBalance =
    decideWallet &&
    decideWallet.wallets &&
    decideWallet.wallets.find(item => item && item.type === 'post_paid');

  return (
    <Fragment>
      <PageTitle
        title={merchant && merchant.name}
        right={
          merchant && merchant.status === 'active' ? (
            <Badge
              pill
              color="success"
              children={merchant && merchant.status}
            />
          ) : merchant && merchant.status === 'inactive' ? (
            <Badge pill color="danger" children={merchant && merchant.status} />
          ) : (
            <Badge
              pill
              color="warning"
              children={merchant && merchant.status}
            />
          )
        }
        subtitle={merchant && merchant.id}
      />

      <Container className="merchants">
        <Row>
          <Sidebar>
            <NavItem>
              <NavLink
                className="nav-link"
                to={`/merchants/${param.slug}/attributes`}
                children="Attributes"
              />
            </NavItem>
            <NavItem>
              <NavLink
                className="nav-link"
                to={`/merchants/${param.slug}/users`}
                children="Users"
              />
            </NavItem>
            <NavItem>
              <NavLink
                className="nav-link"
                to={`/merchants/${param.slug}/bank_accounts`}
                children="Bank Accounts"
              />
            </NavItem>
            <NavItem>
              <NavLink
                className="nav-link"
                to={`/merchants/${param.slug}/wallet`}
                children="Wallet"
              />
            </NavItem>
            <NavItem>
              <NavLink
                className="nav-link"
                to={`/merchants/${param.slug}/customers`}
                children="Customers"
              />
            </NavItem>
            <NavItem>
              <NavLink
                className="nav-link"
                to={`/merchants/${param.slug}/decidewallet`}
                children="Decide Wallet"
              />
            </NavItem>
            <NavItem>
              <NavLink
                className="nav-link"
                to={`/merchants/${param.slug}/decideMonoConfig`}
                children="Decide Mono Config"
              />
            </NavItem>
          </Sidebar>

          <Col md={8}>
            <Switch>
              <Route
                path="/merchants/:slug/"
                exact
                render={() => (
                  <Redirect
                    to={{
                      pathname: `/merchants/${param.slug}/attributes`,
                    }}
                  />
                )}
              />
              <Route
                path="/merchants/:slug/attributes"
                exact
                render={() => (
                  <SingleMerchantAttributes
                    merchantId={merchant && merchant.id}
                    merchantName={merchant && merchant.name}
                    getMerchant={getMerchant}
                    merchantAttributes={merchant && merchant.accountAttribute}
                    merchantFetchError={merchantFetchError}
                  />
                )}
              />
              <Route
                path="/merchants/:slug/users"
                exact
                render={() => (
                  <SingleMerchantUsers
                    merchantId={merchant && merchant.id}
                    merchantName={merchant && merchant.name}
                    getMerchant={getMerchant}
                    merchantUsers={merchant && merchant.accountUsers}
                    merchantFetchError={merchantFetchError}
                  />
                )}
              />
              <Route
                path="/merchants/:slug/bank_accounts"
                exact
                render={() => (
                  <SingleMerchantBankAccounts
                    merchantId={merchant && merchant.id}
                    getMerchant={getMerchant}
                    banks={banks}
                    merchantBanks={merchant && merchant.accountBanks}
                    merchantFetchError={merchantFetchError}
                  />
                )}
              />
              <Route
                path="/merchants/:slug/wallet"
                exact
                render={() => (
                  <SingleMerchantWallet
                    merchantId={merchant && merchant.id}
                    merchantName={merchant && merchant.name}
                    getMerchant={getMerchant}
                    merchantWallet={
                      merchant && merchant.wallet
                        ? (merchant && merchant.wallet.amountThreshold) || 0
                        : ''
                    }
                    merchantFetchError={merchantFetchError}
                  />
                )}
              />
              <Route
                path="/merchants/:slug/customers"
                exact
                render={() => (
                  <SingleMerchantCustomers
                    merchantId={merchant && merchant.id}
                    merchantFetchError={merchantFetchError}
                  />
                )}
              />
              <Route
                path="/merchants/:slug/decidewallet"
                exact
                render={() => (
                  <SingleMerchantDecideWallet
                    merchantId={merchant && merchant.id}
                    merchantName={merchant && merchant.name}
                    merchantSlug={param.slug}
                    getMerchant={getMerchant}
                    transactions={decideWallet.transaction_count}
                    merchantWallet={balance}
                    merchantFee={transactionFee}
                    merchantFetchError={merchantFetchError}
                    prePaidBalance={
                      prePaidBalance && prePaidBalance.amount / 100
                    }
                    postPaidBalance={
                      postPaidBalance && postPaidBalance.amount / 100
                    }
                  />
                )}
              />

              <Route
                path="/merchants/:slug/decideMonoConfig"
                exact
                render={() => (
                  <SingleMerchantDecideMonoConfig
                    getMerchant={getMerchant}
                    merchantFetchError={merchantFetchError}
                    merchantSlug={param.slug}
                  />
                )}
              />
            </Switch>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

SingleMerchant.defaultProps = {};

SingleMerchant.propTypes = {};

export default SingleMerchant;
