import requestClient from '../lib/requestClient';
import { errorHandler } from '../lib/utils';

export const fetchReports = params => {
  let query = '';
  const urlParams = [];
  let { limit = 20, skip, name } = params || {};

  const queryParams = { limit, skip, name };

  for (let key in queryParams) {
    const value = queryParams[key];
    value && urlParams.push(`${key}=${value}`);
  }

  if (urlParams.length > 0) {
    query = `?${encodeURI(urlParams.join('&'))}`;
  }
  return requestClient()
    .get(`/api/v1/reports${query}`)
    .catch(error => {
      return { errorMessage: errorHandler(error) };
    });
};

export const createNewReport = payload => {
  const { name, reportUrl, reportType } = payload;

  return requestClient()
    .post(`/api/v1/reports`, {
      name,
      reportUrl,
      reportType,
    })
    .catch(error => {
      return { errorMessage: errorHandler(error) };
    });
};

export const updateReport = (reportId, payload) => {
  const { name, reportUrl, reportType } = payload;

  return requestClient()
    .put(`/api/v1/reports/${reportId}`, { name, reportUrl, reportType })
    .catch(error => {
      return { errorMessage: errorHandler(error) };
    });
};

export const fetchReportById = reportId => {
  return requestClient()
    .get(`/api/v1/reports/${reportId}`)
    .catch(error => {
      return { errorMessage: errorHandler(error) };
    });
};

export const dropReport = payload => {
  const { reportId } = payload;

  return requestClient()
    .delete(`/api/v1/reports/${reportId}`, {
      data: {
        reportId,
      },
    })
    .catch(error => {
      return { errorMessage: errorHandler(error) };
    });
};
