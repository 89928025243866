import React, { useEffect } from 'react';
import { BrowserRouter as Router, Redirect, Route } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import config from './config/config';
import { Auth } from './components/core';
import Layout from './components/Layout';
import { AuthConsumer } from './context/authContext';
import Login from './pages/Login/';

const App = () => {
  toast.configure();

  const redirectToHttps = () => {
    if (config.node_env !== 'development') {
      if (window.location.protocol !== 'https:') {
        window.location.href = `https:${window.location.href.substring(
          window.location.protocol.length,
        )}`;
      }
    }
  };

  useEffect(() => {
    redirectToHttps();
  });

  return (
    <Auth>
      <Router>
        <AuthConsumer>
          {({ authenticated }) =>
            authenticated ? (
              <Route path="/" component={Layout} />
            ) : (
              <div>
                <Redirect
                  to={{
                    pathname: '/login',
                    state: { referer: window.location.pathname },
                  }}
                />
              </div>
            )
          }
        </AuthConsumer>
        <Route path="/login" component={Login} />
      </Router>
    </Auth>
  );
};

export default App;
