import React from 'react';
import { Card, Col, Nav } from 'reactstrap';
import './_sidebar.scss';

const Sidebar = ({ children }) => (
  <Col md={4}>
    <Card className="sidebar o-hidden border-0 p-4 shadow-lg mb-5 sticky">
      <Nav vertical>{children}</Nav>
    </Card>
  </Col>
);

export default Sidebar;
