import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Badge, Button, Card, Col, Container, Row, Table } from 'reactstrap';
import { Can, PageTitle, Pagination, Unauthorized } from '../../components';
import { useAuth } from '../../context/authContext';
import { extractNumber, handleApiErrors } from '../../lib/utils';
import { getBanks } from '../../services';
import { DisableBank, DeleteBank, EnableBank, EditBank } from './Components';

const DecideBanks = () => {
  const location = useLocation();
  const history = useHistory();
  const { setLoading } = useAuth();
  const initialPageNumber = location.search
    ? extractNumber(location.search)
    : 1;
  const [pageNumber, setPageNumber] = useState(initialPageNumber);
  const [error, setError] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [enableModal, setEnableModal] = useState(false);
  const [disableModal, setDisableModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [banks, setBanks] = useState([]);
  const [selectedBank, setSelectedBank] = useState({});

  const handlePagination = async (e) => {
    const { value } = e.target;
    setPageNumber(parseInt(value));

    if (value > 1) {
      history.push(`decide-banks?page=${value}`);
    } else {
      history.push(`decide-banks`);
    }
  };

  const toggleDisableModal = () => setDisableModal(!disableModal);
  const toggleEnableModal = () => setEnableModal(!enableModal);
  const toggleDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };
  const toggleEditModal = () => setEditModal(!editModal);

  const getDecideBanks = useCallback(async () => {
    setLoading(true);
    const response = await getBanks();

    const apiErrors = handleApiErrors(response);
    setError(apiErrors);
    setLoading(false);

    if (apiErrors) {
      toast.error(apiErrors);
      return;
    }

    setBanks(response?.data?.data?.data);
  }, []);

  useEffect(() => {
    getDecideBanks();
  }, [getDecideBanks]);

  return (
    <Fragment>
      <PageTitle
        title="Decide Banks"
        right={
          <Can
            perform="WRITE_MERCHANT"
            yes={() => (
              <Link to="/decide-banks/new" className="btn btn-primary">
                Add New
              </Link>
            )}
            no={() => ''}
          />
        }
        subtitle={`Page ${pageNumber}`}
      />
      <DeleteBank
        selectedBank={selectedBank}
        isOpen={deleteModal}
        toggle={toggleDeleteModal}
        getBanks={getDecideBanks}
      />

      <DisableBank
        selectedBank={selectedBank}
        isOpen={disableModal}
        toggle={toggleDisableModal}
        getBanks={getDecideBanks}
      />

      <EnableBank
        selectedBank={selectedBank}
        isOpen={enableModal}
        toggle={toggleEnableModal}
        getBanks={getDecideBanks}
      />

      <EditBank
        selectedBank={selectedBank}
        isOpen={editModal}
        toggle={toggleEditModal}
        getBanks={getDecideBanks}
      />

      <Can
        perform="READ_MERCHANT"
        yes={() => (
          <Container className="merchants">
            <Row>
              <Col lg={7} md={6} />
              <Col xl={12}>
                <Card className="o-hidden border-0 shadow-lg mb-5">
                  <section className="p-3 p-md-4">
                    <Table borderless striped responsive hover className="m-0">
                      <thead>
                        <tr className="d-flex align-items-center">
                          <th className="col-4">Bank Name</th>
                          <th className="col-3">Bank Code</th>
                          <th className="col-2 text-center">Status</th>
                          <th className="col-3" />
                        </tr>
                      </thead>

                      <tbody className="w-100">
                        {banks.map((bank) => (
                          <tr
                            key={bank?.id}
                            className="d-flex align-items-center "
                          >
                            <td className="col-4">{bank?.name}</td>

                            <td className="col-3 pl- ">{bank?.code}</td>

                            <td className="col-2 text-center">
                              {bank?.enabled === true ? (
                                <Badge pill color="success" children="active" />
                              ) : (
                                <Badge
                                  pill
                                  color="danger"
                                  children="inactive"
                                />
                              )}
                            </td>

                            <td
                              align="right"
                              className="col-3 d-flex justify-content-end"
                            >
                              <Button
                                className="ml-1"
                                color="primary"
                                size="sm"
                                onClick={() => {
                                  toggleEditModal();
                                  setSelectedBank(bank);
                                }}
                              >
                                Edit
                              </Button>
                              {bank?.enabled ? (
                                <Button
                                  className="ml-1"
                                  color="warning"
                                  size="sm"
                                  onClick={() => {
                                    toggleDisableModal();
                                    setSelectedBank(bank);
                                  }}
                                >
                                  Disable
                                </Button>
                              ) : (
                                <Button
                                  className="ml-1"
                                  color="success"
                                  size="sm"
                                  onClick={() => {
                                    toggleEnableModal();
                                    setSelectedBank(bank);
                                  }}
                                >
                                  Enable
                                </Button>
                              )}
                              <Button
                                size="sm"
                                onClick={() => {
                                  toggleDeleteModal();
                                  setSelectedBank(bank);
                                }}
                                className="ml-1 mr-2"
                                color="danger"
                              >
                                Delete
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>

                    {error && (
                      <div colSpan={3} className="text-center">
                        <Button
                          color="primary"
                          onClick={() => getDecideBanks()}
                        >
                          Reload
                        </Button>
                      </div>
                    )}
                  </section>
                </Card>
              </Col>

              {banks?.length >= 19 && (
                <Col xs={12} className="mb-5">
                  <Pagination
                    currentPage={pageNumber}
                    lastPage={20}
                    clickEvent={handlePagination}
                  />
                </Col>
              )}
            </Row>
          </Container>
        )}
        no={() => <Unauthorized />}
      />
    </Fragment>
  );
};

export default DecideBanks;
