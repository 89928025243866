const CONSTANTS = {
  authTypes: {
    TEMPORARY: 'TEMPORARY',
    FIXED: 'FIXED',
  },
  errors: {
    REQUEST_ERROR:
      'There was an error processing your request. Please, try again later',
    SELECT_PERMISSION: 'Please, select at least one permission',
    ENTER_MERCHANT_SLUG: 'Please, enter the merchant slug to search',
    PHONE_NOT_VALID: 'Please enter a valid phone number',
    EMAIL_NOT_VALID: 'Please enter a valid email address',
    URL_NOT_VALID: 'Please enter a valid URL',
    COLOR_NOT_VALID: 'Color must be a valid HEX Color Code',
    ACCOUNT_NUMBER_NOT_VALID: 'Please enter a valid account number',
    SELECT_ROLE: 'Please select a role',
    SELECT_BANK: 'Please select a bank',
    ENTER_ALL_FIELD: 'Please provide values for all fields.',
    DUPLICATE_ITEM: 'Duplicate item cannot be added.',
    CRED_BUREAU_EMPTY:
      'Require Credit Bureau API is enabled but no credentials provided.',
    REMITA_EMPTY: 'Require Remita is enabled but no credentials provided.',
    MBS_EMPTY: 'Require bank statement is enabled but no credentials provided.',
    CRED_REGISTRY_EMPTY:
      'Require Credit Registry is enabled but no credentials provided.',
    CRC_CREDIT_BUREAU_EMPTY:
      'Requires CRC credit bureau is enabled but no credentials provided',
    OKRA_CREDS_EMPTY:
      'Requires Okra Transactions is enabled but no credentials provided',
    ONEPIPE_CARD_CHARGE_EMPTY:
      'Require OnePipe Lookup is enabled but card charges not provided',
    ENTER_REPORT_NAME: 'Please, enter the report name to search',
    REPORT_ID_MISSING: 'Report ID missing, please select a valid report',
    ENTER_CUSTOMER_EMAIL: 'Please, enter the customer email to search',
    SELECT_CRC_REPORT: 'Please, select just one CRC report type',
    MONO_CREDS_EMPTY:
      'Mono transactions is enable but no credentials is provided',
    DECIDE_AMOUNT_NOT_VALID: 'The amount must be greater than or equal 100.',
    DECIDE_FEE_NOT_VALID: 'Fee must be greater than 1',
    DECIDE_CREDS_EMPTY: 'Decide is enabled but no credentials provided',
    RECOVA_CREDS_EMPTY: 'Recova is enabled but no credential provided.',
  },
  success: {
    MERCHANT_CREATED: 'Merchant created',
    MERCHANT_ATTRIBUTE_UPDATED: 'Merchant attributes have been updated',
    MERCHANT_BANK_ACCOUNT_CREATED: 'Merchant bank account created',
    MERCHANT_USER_CREATED: 'User created',
    MERCHANT_USER_DELETED: 'User deleted',
    ROLE_CREATED: 'Role created',
    MERCHANT_WALLET_UPDATED: 'Merchant wallet top up successful',
    MERCHANT_WALLET_THRESHOLD_UPDATED:
      'Merchant wallet threshold has been updated',
    REPORT_CREATED: 'Report created',
    MERCHANT_USER_DEACTIVATED: 'Customer deactivated',
    DECIDE_FEE_UPDATE: 'Merchant fee successfully changed',
    DECIDE_BANK_CREATED: 'Decide bank has been created successfully',
    DECIDE_BANK_DISABLED: 'Decide bank has been disabled successfully',
    DECIDE_BANK_ENABLED: 'Decide bank has been enabled successfully',
    DECIDE_BANK_UPDATED: 'Decide bank has been updated successfully',
    DECIDE_BANK_DELETED: 'Decide bank has been deleted successfully',
    DECIDE_MONO_CONFIG_CREATED:
      'Decide Mono config has been created successfully',
    DECIDE_MONO_CONFIG_UPDATED:
      'Decide Mono config has been updated successfully',
  },
  clientUserRoles: [
    'Admin',
    'Client_Developer',
    'Application_Reviewer',
    'Loan_Officer',
    'Disbursement_Officer',
    'Operations_Manager',
  ],
  booleanAttributes: new Set([
    'rollbackEligibility',
    'requiresBankStatement',
    'requiresCreditScore',
    'requiresCreditBureauReport',
    'requiresMaxApprovedLoanAmount',
    'requiresBankStatementUpload',
    'useRemita',
    'useRemitaWithTenor',
    'requireWorkEmail',
    'requiresSelfieUpload',
    'requiresWorkIDUpload',
    'requiresExternalDisbursement',
    'verifyWorkEmail',
    'requiresCreditRegistryReport',
    'requiresReferee',
    'requiresGovernmentId',
    'deactivateMessageService',
    'noCardImplementation',
    'enablePartialDebit',
    'requiresEventTrigger',
    'requiresOnePipeLookUp',
    'requiresCrcReport',
    'requiresCrcMicroReport',
    'requiresOkraTransactions',
    'enableEmailVerificationCode',
    'requiresFacebookUrl',
    'enableWebhooksConfig',
    'requiresRemitaMandate',
    'loanOfferLetterFeatureIsEnabled',
    'loanAndApplicationServiceEnabled',
    'okraDirectDebitIsEnabled',
    'manualOfferLetter',
    'monoTransactionsIsEnabled',
    'requiresDecide',
    'useLoanGroups',
    'requiresRecova',
    'requiresDecidePdfAnalysis',
  ]),
  jsonArrayAttributes: new Set([
    'requiredSupportingDocuments',
    'allowedGeoLocationBoundaries',
    'loanMonthlyDurations',
    'addCardCharges',
    'requiredSocialMediaAccounts',
  ]),
  fileAttributeNames: {
    LOGO: 'logo',
    FAVICON: 'favicon',
    TERMS_AND_CONDITION: 'termsAndCondition',
    PRIVACY_POLICY: 'privacyPolicy',
  },
};

module.exports = CONSTANTS;
