import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Card,
  Table,
  Button,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  Form,
  InputGroup,
  Input,
  InputGroupAddon,
  Alert,
} from 'reactstrap';
import { extractNumber, handleApiErrors } from '../../../lib/utils';
import {
  getCustomers,
  deactivateCustomer,
} from '../../../services';
import { useAuth } from '../../../context/authContext';
import { Pagination } from '../../../components';
import { errors, success } from '../../../config/constants';
import Container from 'reactstrap/es/Container';
import Row from 'reactstrap/es/Row';

const SingleMerchantCustomers = ({ merchantId, merchantFetchError }) => {
  const { setLoading } = useAuth();
  const [error, setError] = useState(false);
  const [modal, setModal] = useState(false);
  const [customers, setCustomers] = useState([]);
  const location = useLocation();
  const history = useHistory();
  const initialPageNumber = location.search
    ? extractNumber(location.search)
    : 1;
  const [pageNumber, setPageNumber] = useState(initialPageNumber);
  const [activeUser, setActiveUser] = useState({});
  const [findParam, setFindParam] = useState('');

  const toggleModal = () => setModal(!modal);

  const handlePagination = async e => {
    const { value } = e.target;
    setPageNumber(parseInt(value));

    if (value > 1) {
      history.push(`customers?page=${value}`);
    } else {
      history.push(`customers`);
    }
  };

  const fetchCustomers = useCallback(async () => {
    const merchant = localStorage.getItem('merchantId');

    const response = await getCustomers({
      skip: pageNumber * 20 - 20,
      merchantId: merchantId || merchant,
    });
    const apiErrors = handleApiErrors(response);
    setLoading(false);

    if (apiErrors) {
      toast.error(apiErrors);
      setError(apiErrors);
      return;
    }

    setCustomers(response.data.data.nodes);
  }, [setLoading, pageNumber]);

  const deactivateSingleCustomer = async () => {
    const payload = {
      clientId: merchantId,
      accountId: activeUser.id,
    };

    setLoading(true);
    const response = await deactivateCustomer(payload);
    const apiErrors = handleApiErrors(response);
    toggleModal();
    setLoading(false);

    if (apiErrors) {
      toast.error(apiErrors);
      return;
    }

    toast.success(success.MERCHANT_USER_DEACTIVATED);
    fetchCustomers();
  };

  const findCustomer = async e => {
    e.preventDefault();
    e.stopPropagation();

    if (!findParam || !merchantId) {
      toast.error(errors.ENTER_CUSTOMER_EMAIL);
      return;
    }

    setLoading(true);
    const response = await getCustomers({
      email: findParam,
      merchantId: merchantId,
    });
    const apiErrors = handleApiErrors(response);
    setLoading(false);

    if (apiErrors) {
      toast.error(apiErrors);
      setError(apiErrors);
      return;
    }

    setCustomers(response.data.data.nodes);
  };

  useEffect(() => {
    fetchCustomers();

    window.scrollTo(0, 0);
  }, [fetchCustomers, pageNumber]);

  return (
    <Fragment>
      <h4 className="mb-3 d-flex">
        <span>Customers</span>
      </h4>

      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Deactivate Customer</ModalHeader>
        <ModalBody>
          Deactivate Customer <b>{activeUser.email}</b>?
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={deactivateSingleCustomer}>
            Yes
          </Button>{' '}
          <Button color="secondary" onClick={toggleModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      {
        <Container className="merchants">
          <Row>
            <Col lg={5} md={6} />
            <Col lg={7} md={6} className="mb-3">
              <Form onSubmit={findCustomer}>
                <InputGroup>
                  <Input
                    onChange={e => setFindParam(e.target.value)}
                    placeholder="example@email.com"
                  />
                  <InputGroupAddon addonType="append">
                    <Button color="primary" className="px-4">
                      Search
                    </Button>
                  </InputGroupAddon>
                </InputGroup>
              </Form>
            </Col>
          </Row>
          <Card className="o-hidden border-0 p-4 shadow-lg mb-5">
            <Table borderless striped responsive hover className="m-0">
              <thead>
                <tr className="d-flex align-items-center">
                  <th className="col-2">Id</th>
                  <th className="col-3">Email</th>
                  <th className="col-3">Customer Name</th>
                  <th className="col-2">Status</th>
                  <th className="col-1"></th>
                </tr>
              </thead>
              <tbody>
                {customers.map((customer, index) => (
                  <tr key={index} className="d-flex align-items-center">
                    <td className="col-2">{customer.name}</td>
                    <td className="col-3">{customer.user.email}</td>
                    <td className="col-3">
                      {customer.user.firstName + ' ' + customer.user.lastName}
                    </td>
                    <td className="col-2">
                      {customer.status === 'active' ? (
                        <Badge
                          pill
                          color="success"
                          children={customer.status}
                        />
                      ) : customer.status === 'inactive' ? (
                        <Badge pill color="danger" children={customer.status} />
                      ) : (
                        <Badge
                          pill
                          color="warning"
                          children={customer.status}
                        />
                      )}
                    </td>
                    <td className="col-1">
                      {customer.status !== 'inactive' && (
                        <Button
                          size="sm"
                          color="secondary"
                          className="ml-auto"
                          onClick={() => {
                            setActiveUser({
                              id: customer.id,
                              email: customer.user.email,
                            });
                            toggleModal();
                          }}
                        >
                          Deactivate
                        </Button>
                      )}
                    </td>
                  </tr>
                ))}

                {error ||
                  (merchantFetchError && (
                    <tr>
                      <td colSpan={4} className="text-center col-12">
                        <Button color="primary" onClick={fetchCustomers}>
                          Reload
                        </Button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>

            {findParam && customers.length === 0 && (
              <Alert color="warning" className="text-center">
                Cannot find customer with email{'  '}
                <span className="font-weight-bold">{findParam}</span>
                <Button color="link" onClick={fetchCustomers}>
                  View all
                </Button>
              </Alert>
            )}
            <Col xs={12} className="mb-5">
              {!findParam && (
                <Pagination
                  currentPage={pageNumber}
                  lastPage={20}
                  clickEvent={handlePagination}
                />
              )}
            </Col>
          </Card>
        </Container>
      }
    </Fragment>
  );
};

SingleMerchantCustomers.defaultProps = {
  merchantId: '',
  merchantFetchError: false,
  fetchCustomers: null,
};

export default SingleMerchantCustomers;
