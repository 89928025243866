import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import {
  Card,
  Button,
  FormGroup,
  Label,
  Input,
  Form,
  Row,
  Col,
} from 'reactstrap';
import { handleApiErrors } from '../../../lib/utils';
import { success, errors } from '../../../config/constants';
import {
  addDecideMonoConfig,
  getDecideMonoConfig,
  updateDecideMonoConfig,
  getClientInfo,
} from '../../../services';
import { useAuth } from '../../../context/authContext';

const form = {
  client_key: 'client_key', // mono client key
  client_secret: 'client_secret', // mono client secret
  webhook_secret: 'webhook_secret', //mono webhook secret
};

const SingleMerchantDecideMonoConfig = ({
  merchantFetchError,
  getMerchant,
  merchantSlug,
}) => {
  const { setLoading } = useAuth();
  const [monoKey, setMonoKey] = useState('');
  const [monoSecret, setMonoSecret] = useState('');
  const [monoWebhookSecret, setMonoWebhookSecret] = useState('');
  const [clientId, setClientId] = useState('');
  const [keyState, setKeyState] = useState('');
  const [secretState, setSecretState] = useState('');
  const [webSecretState, setWebSecretState] = useState('');

  const getMonoConfig = useCallback(async () => {
    setLoading(true);
    const response = await getClientInfo(merchantSlug);
    const apiErrors = handleApiErrors(response);
    setLoading(false);

    if (apiErrors) {
      toast.error(apiErrors);
      return;
    }

    const id =
      response &&
      response.data &&
      response.data.data &&
      response.data.data.client_id;
    setClientId(id);

    setLoading(true);
    const configResponse = await getDecideMonoConfig(id);
    const configApiErrors = handleApiErrors(configResponse);
    setLoading(false);

    if (configApiErrors) {
      toast.error(configApiErrors);
      return;
    }

    setMonoKey(
      configResponse &&
        configResponse.data &&
        configResponse.data.data &&
        configResponse.data.data.client_key,
    );

    setMonoSecret(
      configResponse &&
        configResponse.data &&
        configResponse.data.data &&
        configResponse.data.data.client_secret,
    );

    setMonoWebhookSecret(
      configResponse &&
        configResponse.data &&
        configResponse.data.data &&
        configResponse.data.data.webhook_secret,
    );
  }, [setLoading, clientId]);

  useEffect(() => {
    getMonoConfig();
    window.scrollTo(0, 0);
  }, []);

  const updateClientKey = e => {
    const { value } = e.target;
    setKeyState(value);
  };

  const updateClientSecret = e => {
    const { value } = e.target;
    setSecretState(value);
  };

  const updateWebhookSecret = e => {
    const { value } = e.target;
    setWebSecretState(value);
  };

  const addMonoConfig = async e => {
    e.preventDefault();
    e.stopPropagation();

    const payload = {
      client_key: keyState,
      client_secret: secretState,
      webhook_secret: webSecretState,
      clientId,
    };

    if (!keyState) {
      toast.error(errors.ENTER_ALL_FIELD);
      return;
    }

    if (!secretState) {
      toast.error(errors.ENTER_ALL_FIELD);
      return;
    }

    if (!webSecretState) {
      toast.error(errors.ENTER_ALL_FIELD);
      return;
    }

    setLoading(true);
    const response = await addDecideMonoConfig(payload);

    const apiErrors = handleApiErrors(response);
    setLoading(false);

    setSecretState('');
    setKeyState('');
    setWebSecretState('');

    if (apiErrors) {
      toast.error(apiErrors);
      return;
    }

    toast.success(success.DECIDE_MONO_CONFIG_CREATED);
    getMonoConfig();
  };

  const updateMonoConfig = async e => {
    e.preventDefault();
    e.stopPropagation();

    const payload = {
      client_key: keyState,
      client_secret: secretState,
      webhook_secret: webSecretState,
      clientId,
    };

    if (!keyState) {
      toast.error(errors.ENTER_ALL_FIELD);
      return;
    }

    if (!secretState) {
      toast.error(errors.ENTER_ALL_FIELD);
      return;
    }

    if (!webSecretState) {
      toast.error(errors.ENTER_ALL_FIELD);
      return;
    }

    setLoading(true);
    const response = await updateDecideMonoConfig(payload);

    const apiErrors = handleApiErrors(response);
    setLoading(false);

    setSecretState('');
    setKeyState('');
    setWebSecretState('');

    if (apiErrors) {
      toast.error(apiErrors);
      return;
    }

    toast.success(success.DECIDE_MONO_CONFIG_UPDATED);
    getMonoConfig();
  };

  return (
    <Fragment>
      <h4 className="mb-3">Decide Mono Configuration</h4>

      <Card className="o-hidden border-0 p-4 shadow-lg mb-5">
        <div className="p-4 p-md-1">
          <h5>Decide Mono Creds</h5>
          <div>
            <p>{`Client Key:  ${monoKey ? monoKey : 'N/A'}`}</p>
            <p>{`Client Secret:  ${monoSecret ? monoSecret : 'N/A'}`}</p>
            <p>{`Webhook Secret:  ${
              monoWebhookSecret ? monoWebhookSecret : 'N/A'
            }`}</p>
          </div>
        </div>
        <Form
          className="p-4 p-md-1"
          onSubmit={
            monoKey || monoSecret || monoWebhookSecret
              ? updateMonoConfig
              : addMonoConfig
          }
        >
          <Row className="d-flex align-items-center">
            <Col md={8}>
              <FormGroup>
                <Label htmlFor={form.client_key} className="text-capitalize">
                  Client Key
                </Label>
                <Input
                  type="text"
                  minLength={2}
                  value={keyState}
                  onChange={updateClientKey}
                />
              </FormGroup>

              <FormGroup>
                <Label for={form.client_secret} className="text-capitalize">
                  Client Secret
                </Label>
                <Input
                  type="text"
                  minLength={2}
                  value={secretState}
                  onChange={updateClientSecret}
                />
              </FormGroup>

              <FormGroup>
                <Label for={form.webhook_secret} className="text-capitalize">
                  Webhook Secret
                </Label>
                <Input
                  type="text"
                  minLength={2}
                  value={webSecretState}
                  onChange={updateWebhookSecret}
                />
              </FormGroup>
            </Col>
          </Row>

          <Button color="primary" type="submit" className="ml-auto" size="sm">
            {monoKey || monoSecret || monoWebhookSecret
              ? 'Update Mono Config'
              : 'Add Mono Config'}
          </Button>
        </Form>
        {/* </Col> */}

        {merchantFetchError && (
          <div className="text-center col-12">
            <Button color="primary" onClick={getMerchant}>
              Reload
            </Button>
          </div>
        )}
      </Card>
    </Fragment>
  );
};

SingleMerchantDecideMonoConfig.defaultProps = {
  merchantFetchError: false,
  merchantSlug: '',
};

export default SingleMerchantDecideMonoConfig;
