import React from 'react';
import { Spinner } from 'reactstrap';
import './_loader.scss';

const Loader = () => {
  return (
    <section className="loader d-flex position-fixed justify-content-center align-items-center h-100 w-100">
      <Spinner color="primary" style={{ width: '5rem', height: '5rem' }} />
    </section>
  );
};

export default Loader;
