import React, { useEffect } from 'react';

const Page = ({ title, component }) => {
  useEffect(() => {
    document.title = title;
  }, [title]);

  const PageComponent = component;

  return <PageComponent />;
};

export default Page;
