import requestClient from '../lib/requestClient';
import { errorHandler } from '../lib/utils';

/**
 * @return {Array} Array of all Roles
 */

export const fetchRoles = () => {
  return requestClient()
    .get(`/api/v1/roles/all`)
    .catch(error => {
      return { errorMessage: errorHandler(error) };
    });
};

/**
 * @return {Array} Array of all Roles
 */

export const fetchPermissions = () => {
  return requestClient()
    .get(`/api/v1/permissions`)
    .catch(error => {
      return { errorMessage: errorHandler(error) };
    });
};

/**
 *  @typedef {{
 *            name: String,
 *            description: String,
 *            permissions: Array,
 *          }}
 */
let CreateNewRolePayload;

/**
 * @param {CreateNewRolePayload} params
 * @return {Array} Array of all Roles
 */

export const createNewRole = params => {
  const { name, description, permissions } = params || {};

  return requestClient()
    .post(`/api/v1/roles`, {
      name,
      description,
      permissions,
    })
    .catch(error => {
      return { errorMessage: errorHandler(error) };
    });
};

/**
 *  @typedef {{
 *            roleId: String,
 *            permissions: Array,
 *          }}
 */
let UpdateRolePayload;

/**
 * @param {UpdateRolePayload} params
 * @return {Array} Array of all Roles
 */

export const updateRole = params => {
  const { roleId, permissions } = params || {};

  return requestClient()
    .put(`/api/v1/roles/${roleId}`, {
      permissions,
    })
    .catch(error => {
      return { errorMessage: errorHandler(error) };
    });
};
