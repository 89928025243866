import { errors } from '../config/constants';

export const getErrorMessages = array => {
  let errorText = '';

  for (let errorMsg = 0; errorMsg < array.length; errorMsg++) {
    if (typeof array[errorMsg] === 'string') {
      errorText += `${array[errorMsg]}\n`;
    } else {
      errorText += `${array[errorMsg].message}\n`;
    }
  }

  return errorText;
};

export const errorHandler = error => {
  let errorMessage = error.message || errors.REQUEST_ERROR;

  if (error.response) {
    if (error.response.data && error.response.data.errors) {
      return getErrorMessages(error.response.data.errors);
    }
  }

  return errorMessage;
};

export const handleApiErrors = response => {
  let errorMessage = errors.REQUEST_ERROR;

  if (response.errorMessage) {
    return response.errorMessage;
  }

  if (typeof response === 'undefined') {
    return errorMessage;
  }

  if (typeof response.data === 'undefined') {
    return errorMessage;
  }

  if (response.data.errors) {
    return getErrorMessages(response.data.errors);
  }

  if (!response.data || !response.data.data) {
    return errorMessage;
  }
};

export const titleCase = str => {
  if (str) {
    str = str.toLowerCase().split(' ');
    for (let i = 0; i < str.length; i++) {
      str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
    }
    return str.join(' ');
  }

  return str;
};

export const removeUnderscore = str => {
  if (str) {
    return str.replace(/_/g, ' ');
  } else {
    return str;
  }
};

export const removeHyphen = str => {
  if (str) {
    return str.replace(/-/g, ' ');
  } else {
    return str;
  }
};

export const filterMerchantAttribute = (data, attribute) => {
  const [filteredAttribute] = data.filter(
    item => item['attribute']['name'] === attribute,
  );

  if (filteredAttribute) {
    return filteredAttribute.value;
  }
  return;
};

export const splitCamelCase = data => {
  if (typeof data === 'string') {
    return data.replace(/([a-z])([A-Z])/g, '$1 $2');
  }

  return data;
};

export const extractNumber = str => {
  if (str) {
    return parseInt(str.replace(/[^0-9]/g, ''));
  }

  return str;
};

/* eslint-disable no-useless-escape */
export const isValidEmail = input =>
  /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(input);

export const isValidPhone = input => /^\+*(?:[0-9] ?){6,14}[0-9]$/.test(input);

export const isValidUrl = input =>
  /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,10}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/.test(
    input,
  );

export const isValidColor = input => /^#[0-9a-f]{3,6}$/i.test(input);

export const isValidAccountNumber = input => /^\d+$/.test(input);

export const havePermission = (action, permissions = []) => permissions.includes(action) || permissions.includes(action.toLowerCase());

export const numberWithCommas = e => {
  return e === null || typeof e === 'undefined'
    ? 0
    : e.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const removeCommas = e => {
  const hasComma = /,/;

  return hasComma.test(e) ? e.replace(/,/g, '') : e;
};